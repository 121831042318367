import {Helmet} from "react-helmet";

export default function HelmetSeo({ pageTitle, description, keys }) {
	return (
		<Helmet>
			<title>{pageTitle}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keys} />
		</Helmet>
	);
}