import { Link } from "react-router-dom";
import useWindowWidth from '../hooks/useWindowWidth';


export default function SectionRefPage({ title, text, urlBtn, iconBtn, classBtn, labelBtn, img, urlBlank=false, order=false, bg=false }) {
	const isMobile = useWindowWidth() < 810;
	return (
		<section className={bg ? 'bg-light' : null}>
			<div className="container grid descritive g-3 place-center">
				<div>
					<h2>{title}</h2>
					<p className="mb-4">{text}</p>

					{isMobile &&
						<img src={img} className="mb-3" alt={title} />
					}

					<Link to={urlBtn} className={classBtn ? "btn btn-feature "+classBtn : "btn btn-feature"} target={urlBlank ? '_blank' : '_self'}>
						<div>
							{iconBtn}
						</div>
						Scopri di più
					</Link>
				</div>

				{!isMobile &&
					<img src={img} className={order && !isMobile ? 'order' : null} alt={title} />
				}
			</div>
		</section>
	);
}