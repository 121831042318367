import { useState } from 'react';

const useIsOpen = () => {
	const [isOpen, setIsOpen] = useState(false),
			handleIsOpen = e => {
				e.stopPropagation();
				setIsOpen(!isOpen);
			}

	return [isOpen, handleIsOpen];
}

export default useIsOpen;