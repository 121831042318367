import PcprofileNav from './PcprofileNav';
import SitiwebNav from './SitiwebNav';
import ProdottiNav from './ProdottiNav';
import ServiziNav from './ServiziNav';
import AdvertisingNav from './AdvertisingNav';


export default function SubmenuDesktop({ handleIsNavSmOpen }) {
	return (
		<>
			<PcprofileNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<SitiwebNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<ProdottiNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<ServiziNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<AdvertisingNav handleIsNavSmOpen={handleIsNavSmOpen} />
		</>
	)
}