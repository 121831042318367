import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import profile from '../../imgs/profile/pcprofile.jpg';
import profile1 from '../../imgs/profile/pcprofile1.png';
import profile2 from '../../imgs/profile/pcprofile2.png';
import profile3 from '../../imgs/profile/pcprofile3.png';
import profile4 from '../../imgs/profile/pcprofile4.png';
import profile5 from '../../imgs/profile/pcprofile5.png';
import reviews from '../../imgs/profile/reviews.png';


export default function Descrizione() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
		   <HelmetSeo
            pageTitle="Descrizione PcProfile Pagine Comunali - Milanesi Editore"
            description="Inserisci la tua attività su PagineComunali e fatti trovare dai tuoi nuovi clienti. Essere censiti con il nome con quale si è conosciuti è solo l'inizio..."
            keys="descrizione, piattaforma, gestionale, mappe, gallery, informazioni, banca dati, anagrafica"
         />
			
			<section className="bg-light pb-0">
				<div className="container txt-center">
					<h1>PcProfile</h1>
					<h2 className="fw-300">La tua attività online su PagineComunali in pochi click!</h2>
					<img src={profile} style={{ marginBottom: '-.3rem' }} alt="PcProfile Pagine Comunali" />
				</div>
			</section>

			<section className="container">
				<h2 className="txt-center mb-5">Descrizione Pc<b>Profile</b></h2>
				<div className="grid gtc-3 g-3 rg-10 place-center">
					<div>
						<h2>Anagrafica</h2>
						<h3>Correggi la banca dati</h3>
						<p>Inserisci la tua attività su PagineComunali e fatti trovare dai tuoi nuovi clienti. Essere censiti con il nome con quale si è conosciuti è solo l'inizio... con il PcProfileFree puoi inserire anche gli orari e i giorni di chiusura, tutto gratuito e senza limite di tempo.</p>
					</div>

					<img src={profile1} className="mx-w-35 span-2" alt="PcProfile Anagrafica" />

					{!isMobile &&
						<img src={profile2} className="mx-w-35 span-2" alt="PcProfile informazioni" />
					}

					<div>
						<h2>La tua storia fa la differenza</h2>
						<h3>Aumenta le informazioni</h3>
						<p>Inserisci il logo, fai una descrizione dettagliata della tua azienda, aggiungi le fotografie, il link al tuo sito web, il collegamento ai social media, le parole chiave e tanto altro.</p>
					</div>

					{isMobile &&
						<img src={profile2} className="mx-w-35 span-2" alt="PcProfile informazioni" />
					}

					<div>
						<h2>Qual'è il tuo punto di forza</h2>
						<h3>Comunicazioni in real time</h3>
						<p>Ti sei mai chiesto dove sei veramente forte? Comunicalo ai tuoi clienti in tempo reale, pubblicare news, offerte o eventi, se vuoi puoi pubblicarle anche sul tuo sito web con un solo click.</p>
					</div>

					<img src={profile3} className="mx-w-35 span-2" alt="PcProfile news offerte e menu" />

					{!isMobile &&
						<img src={profile4} className="mx-w-35 span-2" alt="PcProfile Photo Gallery" />
					}

					<div>
						<h2>Photo Gallery</h2>
						<h3>Non sottovalutare l'effetto visivo</h3>
						<p>Pubblicare le foto della tua azienda o dei tuoi prodotti non è mai stato così facile.</p>
					</div>

					{isMobile &&
						<img src={profile4} className="mx-w-35 span-2" alt="PcProfile Photo Gallery" />
					}

					<div>
						<h2>Pcprofile Mappe</h2>
						<p>La tua scheda web visibile anche sulle mappe, i tuoi clienti potranno raggiungerti con più facilità.</p>
					</div>

					<img src={profile5} className="mx-w-35 span-2" alt="Pcprofile Mappe" />

					{!isMobile &&
						<img src={reviews} className="mx-w-35 span-2" alt="Pcprofile Recensioni" />
					}

					<div>
						<h2>Recensioni</h2>
						<h3>Piattaforma gestionale</h3>
						<p>Amministra il tuo PcProfile, vuoi mandare delle recensioni in homepage? Puoi farlo. Le aziende troppo spesso non parlano con i loro clienti, non commettere questo errore, utilizza le recensioni per conoscere le esigenze dei tuoi clienti.</p>
					</div>

					{isMobile &&
						<img src={reviews} className="mx-w-35 span-2" alt="Pcprofile Recensioni" />
					}
				</div>
			</section>
		</>
	);
}