import { Link } from "react-router-dom";
import HelmetSeo from "../../components/HelmetSeo";
import image from '../../imgs/template.webp';
 
export default function SitiWeb() {
	return (
		<>
         <HelmetSeo
				pageTitle="Siti Web - Milanesi Editore"
				description="La nostra specializzazione è nello sviluppo di Siti Web utilizzando tecnologie come WordPress, HTML, PHP e React."
				keys="sviluppo Siti Web, WordPress, HTML, PHP, React, siti template"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>Siti Web</h1>
					<p>La nostra specializzazione è nello sviluppo di Siti Web utilizzando tecnologie come WordPress, HTML, PHP e React:</p>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/sitiweb/onepage">Onepage</Link></li>
						<li><Link to="/cosa-offriamo/sitiweb/starter">Starter</Link></li>
						<li><Link to="/cosa-offriamo/sitiweb/pro">Pro</Link></li>
						<li><Link to="/cosa-offriamo/sitiweb/ecommerce">eCommerce</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-25" alt="Siti web" />
			</section>
		</>
	);
}