import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './index.css';

import Header from './components/Header';
import Footer from './components/Footer';
import FixedBottomCta from './components/FixedBottomCta';

import ErrorMessage from './views/ErrorMessage';
import Home from './views/Home';
import CosaOffriamo from './views/CosaOffriamo';
import Profile from './views/cosa-offriamo/Profile';
import SchedaWeb from './views/profile/SchedaWeb';
import Descrizione from './views/profile/Descrizione';
import ComeFunziona from './views/profile/ComeFunziona';
import PrezziProfile from './views/profile/Prezzi';
import SitiWeb from './views/cosa-offriamo/SitiWeb';
import Onepage from './views/sitiweb/Onepage';
import Starter from './views/sitiweb/Starter';
import Pro from './views/sitiweb/Pro';
import Ecommerce from './views/sitiweb/Ecommerce';
import Funzionalita from './views/sitiweb/Funzionalita';
// import FunzionalitaOnepage from './views/sitiweb/FunzionalitaOnepage';
// import FunzionalitaStarter from './views/sitiweb/FunzionalitaStarter';
// import FunzionalitaPro from './views/sitiweb/FunzionalitaPro';
// import FunzionalitaEcommerce from './views/sitiweb/FunzionalitaEcommerce';
import PrezziSitiWeb from './views/sitiweb/Prezzi';
import Https from './views/sitiweb/Https';
import Prodotti from './views/cosa-offriamo/Prodotti';
import FotoVideo from './views/prodotti/FotoVideo';
import GraficaDesign from './views/prodotti/GraficaDesign';
import Hosting from './views/prodotti/Hosting';
import Dbu from './views/prodotti/Dbu';
import Servizi from './views/cosa-offriamo/Servizi';
import ReportReview from './views/servizi/ReportReview';
import Copywriting from './views/servizi/Copywriting';
import DataAnalytics from './views/servizi/DataAnalytics';
import ManutenzioneAssistenza from './views/servizi/ManutenzioneAssistenza';
import Advertising from './views/cosa-offriamo/Advertising';
import StrategiaConsulenza from './views/advertising/StrategiaConsulenza';
import DigitalMarketing from './views/advertising/DigitalMarketing';
import Seo from './views/advertising/digital-marketing/Seo';
import GoogleAds from './views/advertising/digital-marketing/GoogleAds';
import SocialAds from './views/advertising/digital-marketing/SocialAds';
import EmailMarketing from './views/advertising/EmailMarketing';
import ChiediConsulenza from './views/ChiediConsulenza';
// import LavoraConNoi from './views/LavoraConNoi';
import Contatti from './views/Contatti';
import AreaClienti from './views/AreaClienti';


const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location]);
	
	return (
		<>
			<Header />

			<main>
				<Routes location={location} key={location.key}>
					<Route path="/">
						<Route index element={<Home />} />
						<Route path="cosa-offriamo">
							<Route index element={<CosaOffriamo />} />
							<Route path="profile">
								<Route index element={<Profile />} />
								<Route path="scheda-web" element={<SchedaWeb />} />
								<Route path="descrizione" element={<Descrizione />} />
								<Route path="come-funziona" element={<ComeFunziona />} />
								<Route path="prezzi" element={<PrezziProfile />} />
							</Route>
							<Route path="sitiweb">
								<Route index element={<SitiWeb />} />
								<Route path="onepage" element={<Onepage />} />
								<Route path="starter" element={<Starter />} />
								<Route path="pro" element={<Pro />} />
								<Route path="ecommerce" element={<Ecommerce />} />
								<Route path="funzionalita" element={<Funzionalita />} />
								{/*
								<Route path="funzionalita-onepage" element={<FunzionalitaOnepage />} />
								<Route path="funzionalita-starter" element={<FunzionalitaStarter />} />
								<Route path="funzionalita-pro" element={<FunzionalitaPro />} />
								<Route path="funzionalita-ecommerce" element={<FunzionalitaEcommerce />} />
								*/}
								<Route path="prezzi" element={<PrezziSitiWeb />} />
								<Route path="https" element={<Https />} />
							</Route>
							<Route path="prodotti">
								<Route index element={<Prodotti />} />
								<Route path="foto-e-video" element={<FotoVideo />} />
								<Route path="grafica-e-design" element={<GraficaDesign />} />
								<Route path="hosting" element={<Hosting />} />
								<Route path="banche-dati-dbu" element={<Dbu />} />
							</Route>
							<Route path="servizi">
								<Route index element={<Servizi />} />
								<Route path="copywriting" element={<Copywriting />} />
								<Route path="report-e-review" element={<ReportReview />} />
								<Route path="data-analytics" element={<DataAnalytics />} />
								<Route path="manutenzione-e-assistenza" element={<ManutenzioneAssistenza />} />
							</Route>
							<Route path="advertising">
								<Route index element={<Advertising />} />
								<Route path="strategia-consulenza" element={<StrategiaConsulenza />} />
								<Route path="digital-marketing">
									<Route index element={<DigitalMarketing />} />
									<Route path="seo" element={<Seo />} />
									<Route path="google-ads" element={<GoogleAds />} />
									<Route path="social-ads" element={<SocialAds />} />
								</Route>
								<Route path="email-marketing" element={<EmailMarketing />} />
							</Route>
						</Route>
						<Route path="contatti" element={<Contatti />} />
						<Route path="chiedi-una-consulenza" element={<ChiediConsulenza />} />
						{/* <Route path="lavora-con-noi" element={<LavoraConNoi />} /> */}
						<Route path="area-clienti" element={<AreaClienti />} />
						<Route path="/*" element={<ErrorMessage />} />
					</Route>
				</Routes>

				<FixedBottomCta />
			</main>
			
			<Footer />
		</>
	);
}

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</React.StrictMode>
);