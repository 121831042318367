import { THIS_YEAR } from '../data/consts';
import { Link } from 'react-router-dom';
import useIsOpen from '../hooks/useIsOpen';
import LiSubmenu from './Navbar/LiSubmenu';
import SitiwebNav from './Navbar/SitiwebNav';
import ProdottiNav from './Navbar/ProdottiNav';
import ServiziNav from './Navbar/ServiziNav';
import AdvertisingNav from './Navbar/AdvertisingNav';
import logo from '../imgs/logo.svg';

export default function Footer() {
	const [isNavSmOpen, handleIsNavSmOpen] = useIsOpen();
	return (
		<footer>
			<div className="grid gtc-5 g-2">
				<ul>
					<SitiwebNav isNavSmOpen={isNavSmOpen} />
				</ul>

				<ul>
					<ProdottiNav isNavSmOpen={isNavSmOpen} />
				</ul>

				<ul>
					<ServiziNav isNavSmOpen={isNavSmOpen} />
				</ul>

				<ul>
					<AdvertisingNav isNavSmOpen={isNavSmOpen} />
				</ul>

				<ul>
					<LiSubmenu label='Informazioni'>
						<li><Link to="area-clienti">Area Clienti</Link></li>
						<li><Link to="contatti">Contattaci</Link></li>
						<li><Link to="chiedi-una-consulenza">Chiedi una consulenza</Link></li>
						<li><a href="https://www.iubenda.com/privacy-policy/68276502/legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
						<li><a href="https://www.iubenda.com/privacy-policy/68276502/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a></li>
					</LiSubmenu>
				</ul>
			</div>
			

			<div className="flex g-2">
				<img src={logo} alt="logo" />
				<small>&copy; Milanesi Editore {THIS_YEAR} - P.Iva 03463930929.</small>
			</div>
		</footer>
	);
}