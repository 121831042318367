import { Link } from "react-router-dom";
import HelmetSeo from "../components/HelmetSeo";

export default function ErrorMessage() {
	return (
		<>
			<HelmetSeo
				pageTitle="Pagina non trovata - Milanesi Editore"
			/>
			
			<section className="container">
				<h1>Errore 404</h1>
				<h2>Pagina non trovata</h2>
				<p>La pagina che stai cercando non è più disponibile.</p>

				<Link to="/" className="btn">Torna in home</Link>
			</section>
		</>
	);
}