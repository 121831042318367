import { Link } from "react-router-dom";
import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/red-wave.webp';
import digital from '../../imgs/advertising/digital.webp';
import googleAds from '../../imgs/advertising/google-ads.webp';
import socialAds from '../../imgs/advertising/social-ads.webp';
import seo from '../../imgs/advertising/seo.webp';
import contentMarketing from '../../imgs/advertising/content-marketing.webp';


export default function DigitalMarketing() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
		   <HelmetSeo
            pageTitle="Digital Marketing - Milanesi Editore"
            description="Offriamo una vasta gamma di servizi di digital marketing, tra cui pubblicità online, ottimizzazione per i motori di ricerca, marketing dei contenuti, email marketing e social media marketing"
            keys="digital marketing, pubblicità online, marketing dei contenuti, email marketing, social media marketing"
         />
			
			<PageTitle
				title='Digital Marketing'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"/><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"/><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"/><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"/></svg>}
				text='Strategie digitali per valorizzare il tuo brand online'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 pb-0">
				<div className="grid g-3 rg-10 place-center">
					<div>
						<h2>Benvenuto nel mondo del digital marketing!</h2>
						<p>Un team di esperti appassionati pronti a guidarti nel raggiungimento del successo online. Con competenza e creatività, creiamo strategie personalizzate per <b>aumentare la visibilità del tuo brand</b>, coinvolgere il tuo pubblico e ottenere risultati tangibili.</p>
						<p>Offriamo una vasta gamma di servizi di digital marketing, tra cui <b>pubblicità online, ottimizzazione per i motori di ricerca, marketing dei contenuti, email marketing e social media marketing</b>. Siamo al passo con le ultime tendenze del settore e personalizziamo le nostre soluzioni per soddisfare le tue specifiche esigenze.</p>
					</div>

					<img src={digital} className="mx-w-15" alt="Digital marketing" />
				</div>
			</section>

			<section className="container grid gtc-2-1 g-3 place-center">
				<p className="mb-0">Che tu sia una piccola startup o un'azienda consolidata, siamo qui per aiutarti a raggiungere i tuoi obiettivi. Contattaci oggi stesso e scopri come il nostro team di esperti può portare il tuo brand al successo nel mondo digitale.</p>
				<Link to='/contatti' className="btn btn-feature btn-feature-border-red">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>
					</div>
					Contattaci
				</Link>
			</section>
			
			<section className="bg-light">
				<div className="container">
					<div className="txt-center mb-5">
						<h2>Canali e Strumenti di Digital Marketing </h2>
						<p>I canali e gli strumenti che utilizziamo nel nostro approccio di digital marketing sono progettati per massimizzare la visibilità del tuo brand e raggiungere il pubblico giusto al momento giusto. Con una combinazione di Google Ads e social media marketing, offriamo soluzioni efficaci per promuovere il tuo business online.</p>
					</div>

					<div className="grid g-3 place-center mb-5">
						<div>
							<h2>Google Ads</h2>
							<p>Il nostro team di esperti di digital marketing utilizza <b>Google Ads</b> per creare campagne pubblicitarie mirate e di successo. Con una vasta gamma di opzioni pubblicitarie, come <b>annunci di ricerca, annunci display e annunci video</b>, possiamo raggiungere il tuo pubblico target mentre cercano prodotti o servizi simili ai tuoi. Sfruttiamo le parole chiave pertinenti, ottimizziamo le inserzioni e <b>monitoriamo costantemente le prestazioni per massimizzare il ritorno sull'investimento</b>.</p>
						</div>

						<div className="txt-center">
							<img src={googleAds} className="mx-w-25" alt="Google Ads" />
							<Link to='google-ads' className="btn btn-feature btn-feature-border-red m-auto">
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>
								</div>
								Scopri di più
							</Link>
						</div>
						
						{!isMobile &&
							<div className="txt-center">
								<img src={socialAds} className="mx-w-25" alt="Social Ads" />
								<Link to='social-ads' className="btn btn-feature btn-feature-border-red m-auto">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>
									</div>
									Scopri di più
								</Link>
							</div>
						}
						
						<div>
							<h2>Social Ads</h2>
							<p>I social media sono diventati uno strumento potente per promuovere il tuo brand e coinvolgere il pubblico. In Milanesi Editore, utilizziamo strategie di <b>social media marketing personalizzate per posizionare il tuo brand su piattaforme come Facebook, Instagram, Twitter e LinkedIn</b>. Creiamo contenuti coinvolgenti, gestiamo le campagne pubblicitarie e interagiamo con i tuoi follower per aumentare la consapevolezza del tuo brand e generare coinvolgimento significativo.</p>
						</div>

						{isMobile &&
							<div className="txt-center">
								<img src={socialAds} className="mx-w-25" alt="Social Ads" />
								<Link to='social-ads' className="btn btn-feature btn-feature-border-red m-auto">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>
									</div>
									Scopri di più
								</Link>
							</div>
						}
					</div>
					
					<p>La <b>combinazione di Google Ads e social media marketing</b> ci consente di offrire una presenza online completa ed efficace per il tuo brand. Abbiamo una profonda conoscenza delle diverse piattaforme e delle loro caratteristiche distintive, consentendoci di sviluppare strategie su misura per i tuoi obiettivi di marketing.</p>

					<p>Se desideri sfruttare il potenziale di <b>Google Ads e dei social media per far crescere il tuo business</b>, siamo qui per aiutarti. Contattaci oggi stesso per scoprire come possiamo implementare una strategia di digital marketing personalizzata e portare il tuo brand al successo online.</p>
				</div>
			</section>


			<section className="container">
				<div className="grid g-3 rg-10 place-center">
					<div>
						<h2>Search Engine Optimization (SEO)</h2>
						<p>La Search Engine Optimization (SEO) mira a ottenere utenti mirati attraverso i risultati organici dei motori di ricerca, offrendo un vantaggio economico per il tuo business.</p>
						<p>Offriamo un completo supporto per migliorare il posizionamento del tuo brand, attrarre nuovi visitatori e cogliere opportunità di posizionamento inesplorate.</p>
						<p>Aiutiamo a comprendere le esigenze degli utenti, creando un piano di contenuti efficace e strutturando un'architettura dell'informazione apprezzata da utenti e motori di ricerca.</p>
						<p>Costruiamo relazioni online per aumentare la tua autorevolezza e il posizionamento del tuo brand.</p>
					</div>

					<div className="txt-center">
						<img src={seo} className="mx-w-25 mb-4" alt="Search Engine Optimization" />

						<Link to='seo' className="btn btn-feature btn-feature-border-red m-auto">
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>
							</div>
							Scopri di più
						</Link>
					</div>
				</div>
			</section>

			<hr />

			<section className="container">
				<div className="grid g-3 rg-10 place-center">
					{!isMobile &&
						<img src={contentMarketing} className="mx-w-25 mb-1" alt="Content Marketing" />
					}

					<div>
						<h2>Content Marketing</h2>
						<p>Il Content Marketing è la chiave per il successo delle tue campagne di marketing. Noi, in Pagine Comunali, riteniamo che il contenuto sia un elemento fondamentale per connetterti con il tuo pubblico, acquisire conoscenze, differenziarti dalla concorrenza e raggiungere i tuoi obiettivi aziendali. Abbiamo una visione a lungo termine del content marketing, che si integra perfettamente nella tua strategia di marketing complessiva e si concentra sull'utilizzo delle risorse digitali esistenti, piuttosto che su quelle a pagamento.</p>
						<p>Il nostro team di esperti analisti, copywriter e designer digitali collaborerà con te per creare e curare piani editoriali rilevanti e di valore, in grado di attrarre e fidelizzare i tuoi clienti, garantendo risultati concreti per il tuo business. Siamo pronti a guidarti lungo il percorso del content marketing per massimizzare il tuo successo online.</p>
					</div>

					{isMobile &&
						<img src={contentMarketing} className="mx-w-25 mb-1" alt="Content Marketing" />
					}
				</div>
			</section>

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="btn-feature-border-red"
			/>
		</>
	);
}