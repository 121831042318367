export const pricePlansProfile = [
   {
      name: 'Free',
      price: 'Senza alcun limite di tempo',
      features: [
         [
            'Insegna',
            ''
         ],
         [
            'Indirizzo',
            ''
         ],
         [
            '1 Telefono fisso o cellulare',
            ''
         ],
         [
            '1 Categoria',
            ''
         ],
         [
            'Orari e giorni chiusura',
            ''
         ],
         [
            'Versione per tablet e smartphone',
            ''
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'Modifiche e pubblicazioni illimitate',
            ''
         ]
      ]
   },
   {
      name: 'Smart',
      price: 9.9,
      features: [
         [
            'Insegna',
            ''
         ],
         [
            'Indirizzo',
            ''
         ],
         [
            '1 Telefono fisso o cellulare',
            ''
         ],
         [
            '1 Fax',
            ''
         ],
         [
            'Email',
            ''
         ],
         [
            'Sito web',
            ''
         ],
         [
            '1 Categoria',
            ''
         ],
         [
            '5 Parole chiave',
            ''
         ],
         [
            'Logo',
            ''
         ],
         [
            'Descrizione attività',
            'Fino a 1000 caratteri'
         ],
         [
            'Foto gallery',
            'Fino a 6'
         ],
         [
            'Orari e giorni chiusura',
            ''
         ],
         [
            'Informazioni aggiuntive',
            ''
         ],
         [
            'Versione per tablet e smartphone',
            ''
         ],
         [
            'Statistiche di traffico',
            ''
         ],
         [
            'Protocollo HTTPS',
            ''
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'Modifiche e pubblicazioni illimitate',
            ''
         ]
      ]
   },
   {
      name: 'Fast',
      price: 19.9,
      features: [
         [
            'Insegna',
            ''
         ],
         [
            'Indirizzo',
            ''
         ],
         [
            '5 Telefoni fissi o cellulari',
            ''
         ],
         [
            '2 Fax',
            ''
         ],
         [
            'Email',
            ''
         ],
         [
            'Sito web',
            ''
         ],
         [
            '5 Categoria',
            ''
         ],
         [
            '20 Parole chiave',
            ''
         ],
         [
            'Logo',
            ''
         ],
         [
            'Descrizione attività',
            'Fino a 1000 caratteri'
         ],
         [
            'Foto gallery',
            'Fino a 6'
         ],
         [
            'Orari e giorni chiusura',
            ''
         ],
         [
            'Informazioni aggiuntive',
            ''
         ],
         [
            'News',
            ''
         ],
         [
            'Versione per tablet e smartphone',
            ''
         ],
         [
            'Statistiche di traffico',
            ''
         ],
         [
            'Protocollo HTTPS',
            ''
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'Modifiche e pubblicazioni illimitate',
            ''
         ]
      ]
   },
   {
      name: 'Multisede',
      price: 'Scopri l\'offerta a te dedicata',
      features: [
         [
            'Insegna',
            ''
         ],
         [
            'Indirizzo',
            ''
         ],
         [
            '5 Telefoni fissi o cellulari per sede',
            ''
         ],
         [
            '2 Fax per sede',
            ''
         ],
         [
            'Email',
            ''
         ],
         [
            'Sito web',
            ''
         ],
         [
            '5 Categoria per sede',
            ''
         ],
         [
            '5 Parole chiave per sede',
            ''
         ],
         [
            'Logo',
            ''
         ],
         [
            'Descrizione attività',
            'Fino a 1000 caratteri'
         ],
         [
            'Foto gallery',
            'Fino a 6 per sede'
         ],
         [
            'Orari e giorni chiusura',
            ''
         ],
         [
            'Informazioni aggiuntive',
            ''
         ],
         [
            'News',
            ''
         ],
         [
            'Informazioni personalizzate',
            ''
         ],
         [
            'Versione per tablet e smartphone',
            ''
         ],
         [
            'Statistiche di traffico',
            ''
         ],
         [
            'Protocollo HTTPS',
            ''
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'Modifiche e pubblicazioni illimitate',
            ''
         ]
      ]
   }
]