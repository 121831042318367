import HelmetSeo from "../../../components/HelmetSeo";
import PageTitle from '../../../components/PageTitle';
import Faq from '../../../components/Faq';
import Cta from '../../../components/Cta';
import seo from '../../../imgs/advertising/seo-scritta.webp';
import success from '../../../imgs/advertising/success.webp';
import percorso from '../../../imgs/advertising/percorso.webp';
import posizionamento from '../../../imgs/advertising/posizionamento.webp';

const questions = [
   {
      question: 'Cos\'è la Search Engine Optimization (SEO)',
      answer: 'La Search Engine Optimization (SEO) è una strategia che mira a migliorare la visibilità di un sito web sui motori di ricerca, ottimizzando i contenuti e la struttura del sito per ottenere un posizionamento migliore nei risultati di ricerca organici.'
   },
   {
      question: 'Quanto tempo ci vuole per vedere i risultati della SEO',
      answer: 'Il tempo necessario per vedere i risultati della SEO può variare a seconda di diversi fattori, come la concorrenza del settore, l\'autorità del sito web e l\'efficacia delle strategie implementate. In generale, i risultati possono richiedere diversi mesi per essere evidenti, ma l\'investimento nella SEO porta benefici a lungo termine.'
   },
   {
      question: 'Quali sono i vantaggi della SEO rispetto alla pubblicità a pagamento',
      answer: 'La SEO offre risultati organici e duraturi nel tempo, senza dover pagare per ogni clic o impressione. Inoltre, i risultati ottenuti attraverso la SEO sono considerati più affidabili dagli utenti e generano un flusso costante di traffico qualificato.'
   },
   {
      question: 'Cosa include il servizio di SEO di Pagine Comunali',
      answer: 'Il nostro servizio di SEO include un\'analisi approfondita del tuo sito web, l\'ottimizzazione dei contenuti, la ricerca delle parole chiave, la costruzione di collegamenti (link building) e il monitoraggio delle prestazioni. Personalizziamo la strategia in base alle tue esigenze specifiche per ottenere i migliori risultati possibili.'
   },
   {
      question: 'Come posso contattare Pagine Comunali per discutere delle mie esigenze di SEO',
      answer: 'Puoi contattarci tramite il nostro sito web all\'indirizzo [inserire indirizzo del sito web] o chiamando il numero [inserire il numero di telefono]. Saremo lieti di discutere delle tue esigenze di SEO e fornirti una consulenza personalizzata.'
   }
];


export default function Seo() {
	return (
		<>
		   <HelmetSeo
            pageTitle="SEO - Milanesi Editore"
            description="Attraverso una strategia mirata di SEO, lavoriamo per identificare le parole chiave pertinenti per il tuo settore e le esigenze dei tuoi potenziali clienti"
            keys="seo, motori di ricerca, ricerca, ricerca google, parole chiave, search engine optimization"
         />
			
			<PageTitle
				title='Seo'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m15.94,29c-7.17,0-13-5.83-13-13S8.77,3,15.94,3s13,5.83,13,13-5.83,13-13,13Zm0-25c-6.62,0-12,5.38-12,12s5.38,12,12,12,12-5.38,12-12-5.38-12-12-12Z"/><path d="m16.44,32c-.28,0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v3c0,.28-.22.5-.5.5Z"/><path d="m16.44,25.97c-.28,0-.5-.22-.5-.5V6.5c0-.28.22-.5.5-.5s.5.22.5.5v18.97c0,.28-.22.5-.5.5Z"/><path d="m16.44,4c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v3c0,.28-.22.5-.5.5Z"/><path d="m3.44,16H.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.94c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m26.41,16H6.44c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h19.97c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m31.5,16h-3.06c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h3.06c.28,0,.5.22.5.5s-.22.5-.5.5Z"/></svg>}
			/>

			<section className="container pt-sm-4 grid gtc-2-1 g-3 place-center">
				<div>
					<h2>Obiettivo della SEO</h2>
					<p>La SEO mira a garantire che il tuo sito web sia facilmente individuabile e visibile nelle pagine dei risultati dei motori di ricerca quando gli utenti cercano parole chiave rilevanti per il tuo settore o attività. Ci concentriamo sull'acquisizione di utenti in target, catturando l'attenzione di coloro che sono realmente interessati ai tuoi prodotti o servizi. In questo modo, puoi ottenere traffico qualificato che ha maggiori probabilità di convertire.</p>

					<h2>Acquisizione di utenti in target </h2>
					<p>Attraverso una strategia mirata di SEO, lavoriamo per identificare le parole chiave pertinenti per il tuo settore e le esigenze dei tuoi potenziali clienti. Svolgiamo ricerche approfondite per comprendere cosa cercano gli utenti e creiamo un piano di contenuti mirato che soddisfi le loro necessità. Utilizziamo tecniche avanzate per migliorare la visibilità del tuo sito web sui motori di ricerca e garantire che raggiunga il tuo pubblico di destinazione.</p>

					<h2>Costo opportunità</h2>
					<p>La SEO offre un incredibile rapporto costo-opportunità per il tuo business. A differenza della pubblicità a pagamento, i risultati organici ottenuti attraverso la SEO non richiedono un costo per ogni clic o impressione. Investendo nella SEO, puoi ottenere un flusso costante di traffico di qualità senza dover continuamente pagare per annunci pubblicitari. È un modo sostenibile e a lungo termine per aumentare la visibilità del tuo brand online.</p>
				</div>

				<img src={seo} className="mx-w-15" alt="Strategia SEO" />
			</section>

			<section className="bg-light">
				<div className="container grid g-2 place-center">
					<img src={percorso} className="mx-w-25" alt="Supporto di team esperti in SEO" />

					<div>
						<h2>Supporto consulenziale, operativo e analitico</h2>
						<p>Il nostro team di esperti in SEO è qui per offrirti un supporto completo in ogni fase del processo. Dalla consulenza iniziale, in cui analizziamo la tua attuale presenza online e identifichiamo le aree di miglioramento, fino all'implementazione di strategie operative per ottimizzare il tuo sito web, siamo al tuo fianco. Utilizziamo strumenti avanzati per monitorare le prestazioni del tuo sito e analizzare i dati, in modo da apportare continuamente miglioramenti e adattamenti.</p>
					</div>
				</div>
			</section>

			<section className="container grid g-2 place-center">
				<div>
					<h2>Rinforzare il posizionamento del proprio brand</h2>
					<p>Attraverso l'ottimizzazione dei contenuti, delle parole chiave e della struttura del tuo sito web, lavoriamo per rafforzare il posizionamento del tuo brand sui motori di ricerca. Ci assicuriamo che il tuo sito sia facilmente accessibile e che rispetti le linee guida dei motori di ricerca. Inoltre, ottimizziamo i titoli, le meta descrizioni e gli snippet per aumentare la visibilità e l'attrattiva del tuo sito nei risultati di ricerca.</p>
				</div>
				
				<img src={posizionamento} className="mx-w-25" alt="Posizionamento seo" />
			</section>

			<section className="bg-light">
				<div className="container">
					<h2>Acquisire nuovi visitatori</h2>
					<p>Attraverso una strategia mirata di SEO, miriamo a catturare l'attenzione di nuovi visitatori interessati ai tuoi prodotti o servizi. Utilizziamo tecniche di ricerca delle parole chiave per identificare le opportunità di classificazione e creiamo contenuti rilevanti che soddisfino le esigenze degli utenti. In questo modo, attiriamo un flusso costante di visitatori qualificati al tuo sito web, pronti ad esplorare ciò che offri.</p>

					<h2>Cogliere nuove opportunità di posizionamento</h2>
					<p>Il mondo della SEO è in continua evoluzione, e il nostro team è costantemente aggiornato sulle ultime tendenze e best practice. Ci impegniamo a cogliere nuove opportunità di posizionamento che potrebbero non essere ancora state sfruttate dalla tua concorrenza. Monitoriamo attentamente gli algoritmi dei motori di ricerca e apportiamo le necessarie modifiche per mantenere il tuo sito al passo con le ultime direttive e ottenere un posizionamento ottimale.</p>
				</div>
			</section>

			<section className="container grid gtc-3 g-3">
				<div>
					<h2>Pianificazione di contenuti efficaci</h2>
					<p>Comprendere le esigenze degli utenti è fondamentale per la pianificazione di contenuti efficaci. Attraverso una ricerca approfondita, identifichiamo le parole chiave e gli argomenti che sono rilevanti per il tuo pubblico di destinazione. Creiamo quindi contenuti di qualità che rispondono alle loro domande e soddisfano le loro esigenze. La creazione di contenuti informativi, coinvolgenti e ottimizzati per i motori di ricerca è un elemento chiave della nostra strategia di SEO.</p>
				</div>

				<img src={success} alt="Pianificazione di contenuti" />

				<div>
					<h2>Architettura dell'informazione</h2>
					<p>Un aspetto fondamentale della SEO è l'architettura dell'informazione del tuo sito web. Ci assicuriamo che la struttura del tuo sito sia intuitiva, facile da navigare e ben organizzata. Questo non solo migliora l'esperienza degli utenti, ma consente anche ai motori di ricerca di indicizzare correttamente le pagine del tuo sito. Ottimizziamo la struttura delle URL, i collegamenti interni e la navigazione per garantire che ogni pagina del tuo sito sia facilmente accessibile e rilevante per i motori di ricerca.</p>
				</div>
			</section>

			<section className="bg-light">
				<div className="container">
					<h2>Costruzione di relazioni online</h2>
					<p>Un altro aspetto importante della SEO è la costruzione di relazioni online. Lavoriamo per aumentare la tua autorevolezza online attraverso strategie di link building, collaborazioni con influencer del settore e la creazione di contenuti condivisibili. Queste attività contribuiscono a migliorare la reputazione del tuo brand e il posizionamento sui motori di ricerca. Creiamo una presenza online solida e affidabile, che ti distingue dalla concorrenza.</p>

					<h2>Concludendo</h2>
					<p>La Search Engine Optimization (SEO) è fondamentale per il successo del tuo business online. Con il supporto di Pagine Comunali, puoi ottimizzare il tuo sito web per i motori di ricerca e acquisire un flusso costante di visitatori qualificati. Offriamo una gamma completa di servizi SEO, dalla consulenza all'implementazione, per aiutarti a raggiungere i tuoi obiettivi di visibilità online. Contattaci oggi stesso per scoprire come possiamo migliorare il posizionamento del tuo brand e ottenere risultati concreti attraverso la SEO.</p>
				</div>
			</section>

			<section className="container">
            <h2 className="txt-center">Domande frequenti</h2>

				{questions.map((faq, idx) =>
					<Faq
						key={idx}
						question={faq.question}
						answer={faq.answer}
					/>
				)}
         </section>

			<hr />

			<Cta
				title="Se hai bisogno di assistenza, desideri ricevere informazioni aggiuntive o vuoi richiedere un preventivo, non esitare a contattarci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-sm"
				classBtn="btn-feature-border-red"
			/>
		</>
	);
}