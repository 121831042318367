import { useState } from 'react';
import HelmetSeo from "../components/HelmetSeo";

export default function AreaClienti() {
   const [isVisible, setIsVisible] = useState(false);
   const handleSubmit = e => {
      e.preventDefault();
      setIsVisible(!isVisible);
   }

   return (
      <>
         <HelmetSeo
            pageTitle="Area Clienti - Milanesi Editore"
         />

         <section className="container flex txt-center">
            <article>
               <h1>Accedi</h1>
               <p>Sei un cliente Pagine Comunali?</p>
               <form onSubmit={handleSubmit}>
                  <input type="email" name="email" placeholder="Email" required />
                  <input type="password" name="password" placeholder="Password" required />
                  {isVisible &&
                     <p className="txt-danger mb-0 fadein">Password errata, contatta il tuo commerciale di riferimento.</p>
                  }
                  <input type="submit" className="btn" value="Accedi" />
               </form>

            </article>
         </section>
      </>
   );
}