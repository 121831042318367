import { pricePlans } from '../data/pricePlans'
import Popover from './Popover';


export default function PriceTable({ active, activeAll }) {
	return (
		<section className="container">
			<div className="txt-center mb-3">
				<h1 className="mb-1">Prezzi</h1>
				<h2 className="fw-300">Scegli l'offerta ideale per te</h2>
			</div>

			<div className="grid gtc-4 g-_5">
				{pricePlans.map(plan =>
					<article className="price-table" key={plan.name}>
						<h1 className={`price-title ${activeAll || active && active.toLowerCase() === plan.name.toLowerCase() ? 'price-active' : ''}`}>{plan.name}</h1>

						{typeof plan.price === 'string' ?
							<h3 className="price txt-center">{plan.price}</h3>
							:
							<h2 className="price txt-center">
								<small>da</small>
								€ {plan.price.toString().split('.')[0]}
								<sup> .{plan.price.toFixed(2).toString().split('.')[1]}</sup>
								<small>/ mese</small>
							</h2>
						}

						<ul className="price-features list">
							{plan.features.map((feature, idx) =>
								<li key={idx}>
									{feature[0]}
									{feature[1] &&
										<Popover text={feature[1]} />
									}
								</li>
							)}

							{plan.services &&
								<>
									<li className="title">Servizi Aggiuntivi</li>

									{plan.services.map((service, idx) =>
										<li key={idx}>
											{service[0]}
											{service[1] &&
												<Popover text={service[1]} />
											}
										</li>
									)}
								</>
							}
						</ul>
					</article>
				)}
			</div>
		</section>
	);
}