import { useRef } from "react";
import useSendEmail from '../hooks/useSendEmail';
import HelmetSeo from "../components/HelmetSeo";
import Form from "../components/Form/Form";
import InputField from "../components/Form/InputField";
import SelectField from "../components/Form/SelectField";
import logo from '../imgs/logo.svg';

export default function ChiediConsulenza() {
   const formRef = useRef();
	const initValue = {
      agency:'',
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      province: '',
      address: '',
      subject: '',
      message:''
   };
   const { formVals, handleChange, formErrs, handleSubmit, isSubmit, isSent } = useSendEmail(initValue, formRef);
   return (
      <>
         <HelmetSeo
				pageTitle="Chiedi una Consulenza - Milanesi Editore"
				description="Hai bisogno di maggiori informazioni e vorresti una Consulenza? Contattaci"
				keys="consulenza, informazioni"
			/>

         <section className="container">
            <div className="txt-center mb-5">
               <img src={logo} className="mx-w-15 mb-1" alt="logo" />
               <h1>Chiedi una Consulenza</h1>
               <h2 className="fw-300">Hai bisogno di maggiori informazioni? Contattaci</h2>
            </div>

            <Form
               formRef={formRef}
               handleSubmit={handleSubmit}
               errorsForm={formErrs}
               isSent={isSent}
               isSubmit={isSubmit}
            >
               <InputField
                  label='Ragione Sociale'
                  name='agency'
                  type='text'
                  icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                  required={true}
                  value={formVals.agency}
                  onChange={handleChange}
                  errorMsg={formErrs.agency}
               />

               <div className="grid cg-2">
                  <InputField
                     label='Nome'
                     name='name'
                     type='text'
                     icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                     required={true}
                     value={formVals.name}
                     onChange={handleChange}
                     errorMsg={formErrs.name}
                  />

                  <InputField
                     label='Cognome'
                     name='surname'
                     type='text'
                     icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                     required={true}
                     value={formVals.surname}
                     onChange={handleChange}
                     errorMsg={formErrs.surname}
                  />

                  <InputField
                     label='Email'
                     name='email'
                     type='email'
                     icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M896 307.2h-819.2c-42.347 0-76.8 34.453-76.8 76.8v460.8c0 42.349 34.453 76.8 76.8 76.8h819.2c42.349 0 76.8-34.451 76.8-76.8v-460.8c0-42.347-34.451-76.8-76.8-76.8zM896 358.4c1.514 0 2.99 0.158 4.434 0.411l-385.632 257.090c-14.862 9.907-41.938 9.907-56.802 0l-385.634-257.090c1.443-0.253 2.92-0.411 4.434-0.411h819.2zM896 870.4h-819.2c-14.115 0-25.6-11.485-25.6-25.6v-438.566l378.4 252.267c15.925 10.618 36.363 15.925 56.8 15.925s40.877-5.307 56.802-15.925l378.398-252.267v438.566c0 14.115-11.485 25.6-25.6 25.6z"></path></svg>}
                     required={true}
                     value={formVals.email}
                     onChange={handleChange}
                     errorMsg={formErrs.email}
                  />

                  <InputField
                     label='Cellulare'
                     name='phone'
                     type='tel'
                     icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M819.2 1024c-90.691 0-187.154-25.699-286.706-76.386-91.794-46.736-182.48-113.654-262.258-193.522-79.763-79.853-146.595-170.624-193.272-262.498-50.608-99.61-76.269-196.102-76.269-286.795 0-58.774 54.765-115.55 78.31-137.232 33.85-31.17 87.104-67.568 125.794-67.568 19.245 0 41.803 12.589 70.994 39.616 21.782 20.17 46.27 47.51 70.814 79.067 14.794 19.021 88.592 116.267 88.592 162.917 0 38.27-43.25 64.853-89.037 92.998-17.694 10.875-35.992 22.122-49.226 32.73-14.114 11.315-16.645 17.288-17.061 18.629 48.602 121.128 197.141 269.651 318.203 318.184 1.085-0.341 7.067-2.699 18.592-17.075 10.608-13.234 21.854-31.531 32.73-49.227 28.144-45.789 54.726-89.038 92.998-89.038 46.648 0 143.896 73.798 162.917 88.592 31.557 24.546 58.898 49.032 79.067 70.816 27.029 29.189 39.616 51.747 39.616 70.992 0 38.701-36.378 92.115-67.528 126.099-21.693 23.662-78.491 78.701-137.272 78.701zM204.477 51.203c-13.731 0.262-50.634 17.054-90.789 54.029-38.115 35.099-61.792 73.25-61.792 99.568 0 344.523 423.093 768 767.304 768 26.28 0 64.418-23.795 99.528-62.099 37.003-40.366 53.806-77.413 54.069-91.178-1.662-9.728-28.57-47.563-102.232-104.283-63.322-48.762-114.699-74.886-127.901-75.237-0.925 0.274-6.656 2.467-18.277 17.222-10.104 12.832-20.912 30.418-31.366 47.424-28.683 46.666-55.774 90.744-95.122 90.744-6.336 0-12.597-1.219-18.608-3.624-134.376-53.75-293.31-212.685-347.061-347.061-6.456-16.138-7.485-41.414 24.272-70.184 16.882-15.293 40.25-29.656 62.848-43.546 17.006-10.453 34.59-21.261 47.422-31.366 14.755-11.619 16.95-17.352 17.222-18.277-0.352-13.203-26.475-64.579-75.237-127.902-56.72-73.659-94.554-100.568-104.282-102.23z"></path></svg>}
                     required={true}
                     value={formVals.phone}
                     onChange={handleChange}
                     errorMsg={formErrs.phone}
                  />

                  <InputField
                     label='Comune'
                     name='country'
                     type='text'
                     required={true}
                     value={formVals.country}
                     onChange={handleChange}
                     errorMsg={formErrs.country}
                  />
                  <InputField
                     label='Provincia'
                     name='province'
                     type='text'
                     required={true}
                     value={formVals.province}
                     onChange={handleChange}
                     errorMsg={formErrs.province}
                  />
               </div>

               <InputField
                  label='Indirizzo e Numero Civico'
                  name='address'
                  type='text'
                  icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M512 1024c-7.856 0-15.277-3.606-20.13-9.784-2.931-3.73-72.587-92.864-143.229-224.51-41.581-77.491-74.742-153.568-98.565-226.118-30.043-91.499-45.277-177.758-45.277-256.387 0-169.39 137.81-307.2 307.2-307.2s307.2 137.81 307.2 307.2c0 78.629-15.234 164.888-45.278 256.386-23.822 72.55-56.984 148.629-98.565 226.118-70.64 131.646-140.298 220.781-143.229 224.51-4.851 6.179-12.272 9.786-20.128 9.786zM512 51.2c-141.158 0-256 114.842-256 256 0 166.597 74.914 341.176 137.758 458.296 46.186 86.074 92.634 154.306 118.237 189.938 25.709-35.789 72.429-104.432 118.688-190.76 62.643-116.902 137.317-291.163 137.317-457.474 0-141.158-114.84-256-256-256z"></path><path d="M512 460.8c-84.696 0-153.6-68.904-153.6-153.6s68.904-153.6 153.6-153.6 153.6 68.904 153.6 153.6-68.904 153.6-153.6 153.6zM512 204.8c-56.464 0-102.4 45.936-102.4 102.4s45.936 102.4 102.4 102.4c56.464 0 102.4-45.936 102.4-102.4s-45.936-102.4-102.4-102.4z"></path></svg>}
                  required={true}
                  value={formVals.address}
                  onChange={handleChange}
                  errorMsg={formErrs.address}
               />

               <SelectField
                  label='Oggetto'
                  name='subject'
                  options={[
                     'PcProfile Free',
                     'PcProfile Smart',
                     'PcProfile Fast',
                     'PcProfile Multisede',
                     'PcProfile Specialcat',
                     'Siti web',
                     'Siti web Onepage',
                     'Siti web Starter',
                     'Siti web Pro',
                     'Siti web Ecommerce',
                     'Altro'
                  ]}
                  required={true}
                  value={formVals.subject}
                  onChange={handleChange}
               />

               <InputField
                  label='Messaggio'
                  name='message'
                  textarea={true}
                  value={formVals.message}
                  onChange={handleChange}
                  errorMsg={formErrs.message}
               />

               <label htmlFor="agree" className="flex sm-flex-row mb-1">
                  <input
                     type="checkbox"
                     name="agree"
                     required
                  />
                  <span className="mb-0">Ho letto la <a href="#" target="_blank" rel="noopener noreferrer">Privacy Policy</a> e acconsento al trattamento dei miei dati personali *.</span>
               </label>
            </Form>
         </section>
      </>
   );
}