import { Link } from 'react-router-dom';
import LiSubmenu from './LiSubmenu';

export default function AdvertisingNav({ handleIsNavSmOpen }) {
	return (
		<LiSubmenu label='Advertising'>
			<li>
				<Link to="cosa-offriamo/advertising/strategia-consulenza" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m16,21h-.05c-2.76-.03-4.98-2.29-4.95-5.05.03-2.74,2.27-4.95,5-4.95h.05c2.76.03,4.98,2.29,4.95,5.05h0c-.03,2.74-2.27,4.95-5,4.95Zm0-9c-2.19,0-3.98,1.77-4,3.96-.02,2.21,1.75,4.02,3.96,4.04,2.24.03,4.02-1.75,4.04-3.96.02-2.21-1.75-4.02-3.96-4.04h-.04Zm4.5,4.05h0,0Z"/><circle cx="1" cy="16" r="1"/><circle cx="31" cy="16" r="1"/><circle cx="16" cy="1" r="1"/><circle cx="16" cy="31" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="27" cy="27" r="1"/><circle cx="27" cy="5" r="1"/><circle cx="5" cy="27" r="1"/><path d="m16,24h-.08c-2.14-.02-4.14-.87-5.63-2.4s-2.31-3.54-2.29-5.68c.04-4.41,3.64-7.94,8.08-7.92h7.42c.28,0,.5.22.5.5v7.58h0c-.04,4.39-3.63,7.92-8,7.92Zm0-15c-3.83,0-6.96,3.09-7,6.93-.02,1.87.69,3.63,2,4.97s3.06,2.08,4.93,2.1h.07c3.83,0,6.96-3.09,7-6.93v-7.07h-7Zm7.5,7.08h0,0Z"/></svg>
					Strategia e consulenza
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/advertising/digital-marketing" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"/><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"/><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"/><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"/></svg>
					Digital marketing
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/advertising/digital-marketing/social-ads" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.42 73.86"><path d="m25.07,73.86H1.97c-1.12,0-1.97-1.06-1.97-2.46v-37.39c0-1.41.85-2.47,1.97-2.47h23.1c1.12,0,1.97,1.06,1.97,2.47v37.39c0,1.41-.85,2.46-1.97,2.46Zm-22.71-2.35h22.32s0-.07,0-.11v-37.39s0-.08,0-.11H2.36s0,.07,0,.11v37.39s0,.08,0,.11Z"/><path d="m59.96,71.51h-24.69c-.06,0-.11,0-.17-.01-6.29-.12-7.49-1.3-9.39-3.72-.23-.29-.47-.6-.75-.93-.18-.21-.27-.48-.27-.75v-30.7c0-.22.06-.44.18-.63,4.16-6.53,10.25-16.63,11.51-18.72-.47-2.38-2.28-11.68-2.28-13.89,0-.34.13-1.19,1.31-1.7,2.27-.98,7.55-.49,11.19,2.93,3.19,3.01,6.04,9.09,2.08,21.1h19.51c4.54,0,8.23,3.69,8.23,8.23,0,2.07-.77,4.02-2.16,5.52,1.37,1.31,2.16,3.12,2.16,5.06,0,3.02-1.91,5.6-4.58,6.61,1.4,1.31,2.23,3.16,2.23,5.15,0,4.03-3.42,7.24-7.47,7.04.28.75.42,1.55.42,2.36,0,3.89-3.16,7.05-7.05,7.05Zm-24.54-2.35h24.54c2.59,0,4.7-2.11,4.7-4.7,0-1.08-.38-2.1-1.09-2.97-.29-.35-.35-.84-.16-1.25l.15-.32c.27-.58.96-.83,1.54-.57.61.28,1.25.41,1.91.41,2.59,0,4.7-2.11,4.7-4.7,0-1.88-1.12-3.58-2.85-4.32-.41-.18-.69-.58-.71-1.03l-.02-.48c-.01-.32.1-.63.32-.87.22-.23.53-.37.85-.37h0c2.65,0,4.75-2.11,4.75-4.7,0-1.63-.84-3.12-2.24-3.99-.33-.2-.54-.56-.56-.94-.02-.39.15-.76.46-.99,1.48-1.13,2.34-2.83,2.34-4.66,0-3.24-2.64-5.88-5.88-5.88h-21.16c-.38,0-.74-.19-.96-.5-.22-.31-.27-.72-.14-1.08,4.61-12.68,1.6-18.33-1.74-20.84-2.98-2.24-6.62-2.28-7.72-1.87.18,2.46,1.7,10.43,2.31,13.48.06.28,0,.58-.14.83-.07.12-6.93,11.55-11.59,18.88v29.93c.18.23.36.45.52.66,1.51,1.93,2.14,2.73,7.73,2.82.04,0,.09,0,.13,0Z"/></svg>
					Social media marketing
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/advertising/email-marketing" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.98 32"><path d="m36.4,32H1.57c-.87,0-1.57-.7-1.57-1.57V11.51c0-.18.09-.34.24-.43L17.93.41c.6-.55,1.52-.55,2.12,0l17.69,10.67c.15.09.24.25.24.43v18.92c0,.86-.71,1.57-1.57,1.57ZM1,11.79v18.64c0,.31.26.57.57.57h34.83c.32,0,.57-.25.57-.57V11.79L19.48,1.24s-.07-.05-.1-.07c-.22-.22-.58-.22-.8,0-.03.03-.06.05-.1.07L1,11.79Z"/><path d="m13.49,20c-.09,0-.18-.02-.26-.07L.62,12.41c-.24-.14-.31-.45-.17-.69.14-.24.45-.32.69-.17l12.61,7.52c.24.14.31.45.17.69-.09.16-.26.24-.43.24Z"/><path d="m24.49,20c-.17,0-.34-.09-.43-.24-.14-.24-.06-.54.17-.69l12.61-7.52c.24-.14.54-.06.69.17s.06.54-.17.69l-12.61,7.52c-.08.05-.17.07-.26.07Z"/><path d="m36.99,31.5c-.1,0-.19-.03-.28-.08l-17.25-11.48c-.21-.16-.75-.16-.92-.02L1.27,31.41c-.23.15-.54.09-.69-.14-.15-.23-.09-.54.14-.69l17.25-11.48c.5-.4,1.56-.4,2.1.02l17.21,11.46c.23.15.29.46.14.69-.1.14-.25.22-.42.22Z"/></svg>
					Email marketing
				</Link>
			</li>
		</LiSubmenu>
	);
}