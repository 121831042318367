import useWindowWidth from '../../hooks/useWindowWidth';
import useIsOpen from '../../hooks/useIsOpen';

export default function LiSubmenu({ label, customLiClass=false, customUlClass=false, children }) {
	const isMobile = useWindowWidth() < 1200;
	const [isOpen, handleIsOpen] = useIsOpen();

	if(!isMobile) {
		return (
			<li className={customLiClass ? customLiClass : null}>
				<i>{label}</i> {customLiClass ? <span /> : null}
				<ul className={customUlClass ? customUlClass : null}>
					{children}
				</ul>
			</li>
		);
	} else {
		return (
			<li className={customLiClass ? customLiClass : null} aria-expanded={isOpen} onFocus={handleIsOpen}><span onClick={handleIsOpen}>{label}</span>
				<ul className={customUlClass ? customUlClass : null} data-visible={isOpen}>
					{children}
				</ul>
			</li>
		);
	}
}