import useWindowWidth from '../../../hooks/useWindowWidth';
import HelmetSeo from "../../../components/HelmetSeo";
import PageTitle from '../../../components/PageTitle';
import Faq from '../../../components/Faq';
import Cta from '../../../components/Cta';
import googleAds from '../../../imgs/advertising/google-ads.webp';
import gads from '../../../imgs/advertising/gads.webp';
import campagneGoogle from '../../../imgs/advertising/campagne-google.webp';
import buyer from '../../../imgs/advertising/buyer.webp';

const questions = [
   {
      question: 'Cos\'è Google Ads',
      answer: 'Google Ads è una piattaforma pubblicitaria online che aiuta le aziende a raggiungere un vasto pubblico tramite annunci su Google e partner selezionati, offrendo opzioni di creazione e gestione delle campagne, targeting accurato e strumenti di monitoraggio per valutare l\'efficacia.'
   },
   {
      question: 'Quali sono i vantaggi di utilizzare Google Ads',
      answer: 'I vantaggi di utilizzare Google Ads includono la possibilità di raggiungere un vasto pubblico di utenti, la capacità di targettizzare gli annunci in base a criteri specifici, la flessibilità nel determinare il budget pubblicitario e la misurabilità dei risultati attraverso strumenti di analisi. '
   },
   {
      question: 'Quali sono i costi associati alla pubblicità su Google Ads',
      answer: 'I costi associati alla pubblicità su Google Ads dipendono da diversi fattori, tra cui il tipo di annunci, le parole chiave selezionate, la concorrenza nel settore e il budget impostato dall\'inserzionista.'
   },
   {
      question: 'Come posso impostare una campagna su Google Ads',
      answer: 'Per impostare una campagna pubblicitaria su Google Ads, devi creare un account, selezionare gli obiettivi della campagna, scegliere le parole chiave rilevanti, scrivere gli annunci e impostare il budget e le impostazioni di targeting.'
   },
   {
      question: 'Come posso ottimizzare le mie campagne su Google Ads',
      answer: 'Puoi ottimizzare le campagne su Google Ads utilizzando diverse strategie, come la scelta accurata delle parole chiave, l\'uso di estensioni degli annunci, l\'analisi delle metriche di conversione e il monitoraggio continuo delle prestazioni.'
   },
   {
      question: 'Come posso misurare l\'efficacia delle mie campagne su Google Ads',
      answer: 'Puoi utilizzare gli strumenti di analisi forniti da Google, come Google Analytics, per misurare l\'efficacia delle tue campagne. Puoi monitorare metriche come il numero di clic, le impressioni e le conversioni.'
   },
   {
      question: 'Quali sono le opzioni di targeting su Google Ads',
      answer: 'Le opzioni di targeting su Google Ads includono la scelta di parole chiave rilevanti, la selezione di posizioni geografiche specifiche, l\'utilizzo di dati demografici e interessi e l\'opzione di remarketing.'
   },
   {
      question: 'Come posso migliorare il punteggio di qualità delle mie inserzioni su Google Ads',
      answer: 'Puoi migliorare il punteggio di qualità delle tue inserzioni ottimizzando le parole chiave, creando annunci pertinenti e coinvolgenti, migliorando l\'esperienza utente sulla pagina di destinazione e monitorando le metriche di qualità.'
   },
   {
      question: 'Cosa sono le estensioni degli annunci su Google Ads',
      answer: 'Le estensioni degli annunci consentono di aggiungere informazioni aggiuntive agli annunci, come indirizzi, numeri di telefono e link a pagine specifiche del sito web.'
   },
   {
      question: 'Come posso aumentare le conversioni e il ROI utilizzando Google Ads',
      answer: 'Puoi migliorare le conversioni e il ROI utilizzando strategie di targeting efficaci, creando annunci pertinenti e coinvolgenti e monitorando le metriche chiave per ottimizzare le prestazioni delle tue campagne.'
   },
];


export default function GoogleAds() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
         <HelmetSeo
            pageTitle="Google Ads - Milanesi Editore"
            description="Possiamo massimizzare l'efficacia delle tue campagne pubblicitarie per ottenere un maggiore ritorno sull'investimento"
            keys="google ads, sponsorizzate google, campagne pubblicitarie, strategia di targeting, remarketing, metriche di marketing"
         />
			
			<PageTitle
				title='Google Ads'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.01 32"><path d="m10.19,32c-5.62,0-10.19-4.57-10.19-10.19s4.57-10.19,10.19-10.19c.68,0,1.66.17,2.31.3L25.69.36c.27-.24.61-.36.97-.36h3.88c.81,0,1.47.66,1.47,1.47v4.53c0,.12,0,.22,0,.29,0,.2.02.43-.19.61-.2.18-.42.14-.54.12-.06,0-.15-.02-.28-.02h-3v4c-.02.74-.22.95-.89.99-.01,0-.1,0-.11,0h-3v4c0,.19-.11.36-.28.45l-4.52,2.26c.48.96.8,2.45.8,3.79,0,4.67-3.67,9.5-9.81,9.5Zm0-19.38c-5.07,0-9.19,4.12-9.19,9.19s4.12,9.19,9.19,9.19c5.52,0,8.81-4.32,8.81-8.5,0-1.59-.47-3.1-.91-3.71-.09-.12-.12-.27-.08-.41.03-.14.13-.26.26-.33l4.72-2.36v-4.19c0-.28.22-.5.5-.5h3.5v-4.5c0-.28.22-.5.5-.5h3.5V1.47c0-.26-.21-.47-.47-.47h-3.88c-.11,0-.22.04-.31.12l-13.37,11.72c-.12.1-.28.15-.43.11-1-.21-1.85-.33-2.35-.33Zm21.3-6.1h0,0ZM8.5,27c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5,3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5Zm0-6c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5,2.5-1.12,2.5-2.5-1.12-2.5-2.5-2.5Zm6.18-6.3c-.14,0-.28-.06-.38-.17-.18-.21-.16-.52.05-.71L25.67,3.9l.66.75-11.32,9.92c-.09.08-.21.12-.33.12Z"/></svg>}
			/>

         <section className="container pt-sm-4 grid g-3 place-center pb-0">
				<div>
               <h2>Come possiamo aiutarvi</h2>
               <p><b>Possiamo massimizzare l'efficacia delle tue campagne pubblicitarie per ottenere un maggiore ritorno sull'investimento</b>. Utilizzando una combinazione di strategie e competenze specializzate, possiamo ottimizzare ogni fase del percorso del cliente. <b>Il nostro obiettivo è aiutarti a risparmiare tempo e risorse</b>, scoprire nuove opportunità, risolvere eventuali problemi e ottenere risultati migliori complessivamente.</p>
				</div>

				<img src={googleAds} className="mx-w-25" alt="Google Ads" />
			</section>
         
         <section className="container grid gtc-3 g-3 place-center">
            <ul className="list">
               <li>Gestire i tuoi account AdWords</li>
               <li>Ideare o ottimizzare le tue campagne</li>
               <li>Ideare annunci testuali e visuali performanti</li>
               <li>Creare e ottimizzare landing page</li>
               <li>Migliorare la tua strategia di targeting</li>
               <li>Tracciare le tue visite in negozio da campagne digitali</li>
               <li>Gestire e ottimizzare le offerte (bid)</li>
               <li>Aumentare il punteggio di qualità</li>
               <li>Identificare parole chiave negative</li>
               <li>Ricerca di nuove parole chiave</li>
               <li>Elaborare report analitici sulle campagne</li>
            </ul>

            <img src={gads} alt="Ideare o ottimizzare le tue campagne" />

            <ul className="list">
               <li>Ottimizzare sistematicamente i tassi di conversione (CRO)</li>
               <li>Conoscenza avanzata delle strategie di offerta e budgeting</li>
               <li>Monitoraggio e ottimizzazione costante delle prestazioni delle campagne</li>
               <li>Analisi dei dati e interpretazione delle metriche di marketing</li>
               <li>Creazione di annunci persuasivi e rilevanti</li>
               <li>Utilizzo degli strumenti di remarketing</li>
               <li>Strategie di segmentazione di pubblico</li>
               <li>Gestione e coordinamento di team di lavoro</li>
               <li>Aggiornamento sulle ultime tendenze e novità nel settore della pubblicità digitale e del marketing online</li>
            </ul>
			</section>

         <hr />

			<section className="container grid g-3 place-center">
            {!isMobile &&
               <img src={campagneGoogle} className="mx-w-25" alt="Campagne google" />
            }
				<div>
					<h2>Amplifica la tua presenza online con diverse tipologie di campagne</h2>
					<p>Sfrutta al massimo il potenziale delle diverse tipologie di campagne pubblicitarie digitali per raggiungere il tuo pubblico target in modo efficace e coinvolgente. Dalle <b>Campagne Search</b> che mostrano annunci pertinenti nei risultati di ricerca, alle <b>Campagne Display</b> che diffondono la tua pubblicità su siti web e app, passando per le <b>Campagne Shopping</b> che promuovono i tuoi prodotti direttamente sui motori di ricerca, le <b>Campagne Video</b> che catturano l'attenzione con contenuti audiovisivi coinvolgenti e le <b>Campagne App</b> che promuovono il download e l'interazione con le tue applicazioni mobili. Scopri come ogni tipo di campagna può contribuire alla crescita del tuo business online.</p>
				</div>

            {isMobile &&
				   <img src={campagneGoogle} className="mx-w-25" alt="Campagne google" />
            }
			</section>

         <hr />

			<section className="container grid g-3 place-center">
				<div>
					<h2>Massimizza l'efficacia dei tuoi annunci con il targeting preciso</h2>
					<p>Mettiamo a tua disposizione la nostra expertise per concentrare il tuo budget pubblicitario su un pubblico altamente mirato e rilevante. Attraverso un'analisi approfondita delle esigenze e dei comportamenti dei tuoi clienti, identificheremo criteri di segmentazione accurati e scopriremo nuove opportunità di pubblico per ottimizzare il ritorno sull'investimento. Lavoreremo insieme per individuare i migliori prospect e far crescere le tue vendite in modo mirato ed efficace.</p>

               <div className="grid">
                  <ul className="list bold">
                     <li>Dati demografici</li>
                     <li>Località geografiche</li>
                     <li>Dispositivi</li>
                     <li>Orari</li>
                     <li>Lingue</li>
                     <li>Livelli di rendimento</li>
                     <li>Età e genere</li>
                     <li>Affinità</li>
                  </ul>
                        
                  <ul className="list bold">
                     <li>In-market</li>
                     <li>Pubblico personalizzato</li>
                     <li>Pubblico simile (Lookalike)</li>
                     <li>Remarketing</li>
                     <li>Pubblico combinato</li>
                     <li>Posizionamenti simili</li>
                     <li>Argomenti correlati</li>
                  </ul>
               </div>
				</div>

				<img src={buyer} className="mx-w-25" alt="Annunci targetizzati" />
			</section>

			<section className="container">
            <h2 className="txt-center">Domande frequenti</h2>

				{questions.map((faq, idx) =>
					<Faq
						key={idx}
						question={faq.question}
						answer={faq.answer}
					/>
				)}
         </section>

         <hr />

         <Cta
            title="Se hai bisogno di assistenza, desideri ricevere informazioni aggiuntive o vuoi richiedere un preventivo, non esitare a contattarci."
            urlBtn="/contatti"
            labelBtn="Contattaci"
            classSection="pbk-sm"
            classBtn="btn-feature-border-red"
         />
		</>
	);
}