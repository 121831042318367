import { LIB_URL } from '../data/consts'

export default function BtnScegliTemplate() {
	return (
		<a href={LIB_URL} target="_blank" rel="noreferrer" className="btn btn-feature">
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32"><path d="m22.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h14c.28,0,.5.22.5.5s-.22.5-.5.5H1.5c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h21c.28,0,.5-.22.5-.5v-7.5c0-.28.22-.5.5-.5s.5.22.5.5v7.5c0,.83-.67,1.5-1.5,1.5Z"/><path d="m23.5,12.5c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m23.5,10h-7c-.84,0-1.5-.94-1.5-1.75V.5c0-.21.13-.39.32-.47.19-.07.41-.02.55.14l8,9c.13.15.16.36.08.54-.08.18-.26.3-.46.3ZM16,1.81v6.44c0,.33.28.75.5.75h5.89l-6.39-7.19Z"/><path d="m15.88,14H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.38c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.5,18H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h9c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m11.5,10h-6c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h6c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m15.75,22H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.25c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m18.5,26H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h13c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.5,24c-3.58,0-6.5-2.92-6.5-6.5s2.92-6.5,6.5-6.5,6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5Zm0-12c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5,5.5-2.47,5.5-5.5-2.47-5.5-5.5-5.5Z"/><path d="m32.5,31c-.13,0-.26-.05-.35-.15l-8-8c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/></svg>
			</div>
			Scegli Template
		</a>
	);
}