import useWindowWidth from '../../hooks/useWindowWidth';

import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import SectionRefPage from '../../components/SectionRefPage';
import PriceTable from '../../components/PriceTable';
import BtnScegliTemplate from '../../components/BtnScegliTemplate';
import Cta from '../../components/Cta';

import titleBg from '../../imgs/bg/green-wave.webp';
import template from '../../imgs/sitiweb/template.webp';
import formazione from '../../imgs/sitiweb/formazione.webp';
import soddisfatto from '../../imgs/sitiweb/soddisfatto.webp';
import httpsSecure from '../../imgs/sitiweb/https-secure.webp';
import funzionalita from '../../imgs/sitiweb/funzionalita.webp';


export default function Onepage() {
	const isMobile = useWindowWidth() < 1080;

	return (
		<>
			<HelmetSeo
				pageTitle="Siti web Onepage - Milanesi Editore"
				description="Siti web onepage, tutte le informazioni che cerchi in una singola pagina. La soluzione perfetta per una presenza online rapida ed efficace"
				keys="onepage, sitoweb onepage, sito vetrina, sito veloce, sito web semplice, librerie template, template perfetto, preventivo gratuito, pagine sito web, presenza online"
			/>

			<PageTitle
				title='Onepage'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m30.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h29c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h29c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"></path><g><path d="m4,28.48c-.13,0-.26-.05-.36-.15-.13-.13-.17-.33-.12-.5l3.16-9.71c.02-.07.07-.14.12-.2l10.2-10.2c.19-.19.52-.19.71,0l6.73,6.73c.19.19.2.51,0,.71l-10.09,10.21c-.06.06-.13.1-.21.13l-10,2.98s-.09.02-.14.02Zm3.6-9.95l-2.83,8.7,8.96-2.67,9.65-9.76-6.03-6.03-9.76,9.76Z"></path><path d="m24.06,15.29c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l3.74-3.74c.22-.22.22-.57,0-.79l-5.24-5.23c-.22-.22-.57-.22-.79,0l-3.74,3.74c-.2.2-.51.2-.71,0s-.2-.51,0-.71l3.74-3.74c.61-.61,1.59-.61,2.2,0l5.24,5.23c.61.61.61,1.6,0,2.2l-3.74,3.74c-.1.1-.23.15-.35.15Z"></path><path d="m13.38,25.13l-.86-2.99c-.05-.18,0-.37.13-.5l9.24-9.03.7.71-9.02,8.82.78,2.71-.96.28Z"></path><path d="m10.04,19.42s-.07,0-.11-.01l-2.9-.66.22-.98,2.63.6,8.86-8.9.71.71-9.06,9.1c-.09.1-.22.15-.35.15Z"></path><path d="m13,22.5h-3c-.28,0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v2.5h2.5c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></g></svg>}
				text='Tutto ciò che desideri in una sola pagina'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Siti web onepage</h2>
					<p>Siti web <b>onepage</b>, tutte le informazioni che cerchi in una singola pagina. La soluzione perfetta per <b>una presenza online rapida ed efficace</b>. Ideali per chi desidera un sito web <b>semplice ed economico</b>, queste pagine condensano tutte le tue necessità in un design elegante. La struttura a singola pagina non solo <b>risparmia tempo e risorse</b>, ma si traduce anche in <b>costi più accessibili</b>. Fai il primo passo verso una presenza online di qualità <b>senza rinunciare alla qualità</b>. </p>
				</div>

				<img src={template} className="mx-w-25" alt="Presenza online rapida ed efficace" />
				
				{!isMobile &&
					<img src={formazione} className="mx-w-25" alt="Scegli la grafica del tuo sito web" />
				}

				<div>
					<h2>Scegli la grafica del tuo sito web dalle nostre librerie</h2>
					<p>La creazione del tuo sito web <b>inizia con la scelta di un template</b>. In Milanesi Editore, abbiamo a disposizione una vasta gamma di template <b>progettati per qualsiasi attività</b>.</p>
					<p className="mb-4">Che tu gestisca un ristorante, sia un professionista o faccia parte di un'azienda, <b>troverai sicuramente il template perfetto per le tue esigenze</b>. Scegli il design che ti ispira di più, e noi <b>ci occuperemo di trasformarlo e personalizzarlo</b> in una onepage unica, pensata esclusivamente per te. La tua presenza online inizia qui.</p>
					{isMobile &&
						<img src={formazione} className="mx-w-25 mb-2" alt="Scegli la grafica del tuo sito web" />
					}
					<BtnScegliTemplate />
				</div>

				<div>
					<h2>Ogni nostro cliente deve essere soddisfatto al 100%</h2>
					<p>Se, in un secondo momento, desiderate <b>ampliare il vostro sito onepage</b>, siamo lieti di assistervi, <b>senza dover affrontare costi eccessivi</b>. Con noi, i vostri investimenti iniziali non saranno mai sprecati, ma potranno essere utilizzati per migliorare la vostra presenza online in modo personalizzato. I nostri consulenti sono a vostra disposizione per raccogliere le vostre idee, fornirvi consigli concreti e <b>dare forma alla crescita del vostro sito</b>. Contattateci ora per ulteriori dettagli e richiedere <b>un preventivo gratuito per l'aggiunta di nuove pagine</b>.</p>
				</div>
				
				<img src={soddisfatto} className="mx-w-25" alt="Cliente soddisfatto al 100%" />
			</section>
			
			<hr />
			
			<SectionRefPage
				title="Protocollo Https"
				text="Il protocollo standard di trasferimento ipertestuale sicuro, la dicitura “https://” viene visualizzata nel URL del sito web protetto con un certificato SSL (Secure Sockets Layer). Se si clicca sul simbolo del lucchetto nella barra del browser, è possibile visualizzare i dettagli del certificato, il nome aziendale del proprietario del sito web oltre che i dati relativi all'autorità di emissione."
				urlBtn="/cosa-offriamo/sitiweb/https"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="m25.5,32H2.5c-1.38,0-2.5-1.12-2.5-2.5v-14c0-1.38,1.12-2.5,2.5-2.5h23c1.38,0,2.5,1.12,2.5,2.5v14c0,1.38-1.12,2.5-2.5,2.5ZM2.5,14c-.83,0-1.5.67-1.5,1.5v14c0,.83.67,1.5,1.5,1.5h23c.83,0,1.5-.67,1.5-1.5v-14c0-.83-.67-1.5-1.5-1.5H2.5Z"></path><path d="m4.5,14c-.28,0-.5-.22-.5-.5v-3.5C4,4.49,8.49,0,14,0s10,4.49,10,10v3.42c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.42c0-4.96-4.04-9-9-9S5,5.04,5,10v3.5c0,.28-.22.5-.5.5Z"></path><path d="m8.5,13.99c-.28,0-.5-.22-.5-.5v-3.49c0-3.31,2.69-6,6-6s6,2.69,6,6v3.45c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.45c0-2.76-2.24-5-5-5s-5,2.24-5,5v3.49c0,.28-.22.5-.5.5Z"></path><path d="m27.12,27H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m27.12,21H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m1.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m7.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m13.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m19.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path></svg>}
				img={httpsSecure}
				order={true}
			/>

			<SectionRefPage
				title="Funzionalità"
				text="Scoprite le molteplici funzionalità che offriamo per il vostro sito web, tra cui design responsivo, servizio foto/video, temi grafici animati, personalizzazione HTML/CSS, widget, Google MyBusiness, report/recensioni dettagliati, integrazione con Google Analytics 4 e soluzioni specifiche per il vostro eCommerce."
				urlBtn="/cosa-offriamo/sitiweb/funzionalita"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>}
				img={funzionalita}
				bg={true}
			/>

			{!isMobile &&
				<PriceTable active="Onepage" />
			}

			<hr />
			
			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="btn-feature-border-green"
			/>
		</>
	);
}