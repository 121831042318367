import { Link } from "react-router-dom";
import HelmetSeo from "../components/HelmetSeo";
import image from '../imgs/sitiweb/ecommerce-strategia.webp';

export default function CosaOffriamo() {
	return (
		<>
			<HelmetSeo
				pageTitle="Cosa Offriamo - Milanesi Editore"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>Cosa Offriamo</h1>
					<p>Un'unica agenzia per tutte le tue necessità di comunicazione digitale:</p>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/profile">PcProfile</Link></li>
						<li><Link to="/cosa-offriamo/sitiweb">Siti Web</Link></li>
						<li><Link to="/cosa-offriamo/servizi">Servizi</Link></li>
						<li><Link to="/cosa-offriamo/prodotti">Prodotti</Link></li>
						<li><Link to="/cosa-offriamo/advertising">Advertising</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-25" alt="Presenza online rapida ed efficace" />
			</section>
		</>
	); 
}