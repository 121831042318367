import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

export default function FixedBottomCta() {
	const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY),
			[visible, setVisible] = useState(true);
	const footerHeight = 500;

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100 || currentScrollPos + window.innerHeight > document.documentElement.scrollHeight - footerHeight);
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos]);

	return (
		<div className={`fixed-cta ${visible ? 'visible' : ''}`}>
			<div className="container flex g-1 space-between">
				<p className="txt-white mb-0">Cerchi un partner per progetti digitali?</p>
				<Link to="/contatti" className="btn btn-border txt-white" style={{ borderColor: "#fff" }}>Contattaci</Link>
			</div>
		</div>
	);
}