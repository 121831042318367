import { Link } from "react-router-dom";
import HelmetSeo from "../../components/HelmetSeo";
import image from '../../imgs/sitiweb/ecommerce-strategia.webp';

export default function Advertising() {
	return (
		<>
			<HelmetSeo
				pageTitle="Advertising - Milanesi Editore"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>Advertising</h1>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/advertising/strategia-consulenza">Strategia e consulenza</Link></li>
						<li><Link to="/cosa-offriamo/advertising/digital-marketing">Digital marketing</Link></li>
						<li><Link to="/cosa-offriamo/advertising/digital-marketing/social-ads">Social media marketing</Link></li>
						<li><Link to="/cosa-offriamo/advertising/email-marketing">Email marketing</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-25" alt="Advertising" />
			</section>
		</>
	);
}