export default function PageTitle({ title, text, icon, imgBg=false, imgBottom=false }) {
	return (
      <section
         className={!imgBg ? "head " : "head section-bg"}
         style={imgBg ? {"--bg-url": `url(${imgBg})`} : {}}
      >

         <div className="container">
            <h1 className={imgBg && !imgBottom ? 'txt-white' : ''}>{icon && icon}{title}</h1>
            {text &&
               <h2 className={imgBg && !imgBottom ? 'fw-300 txt-white' : 'fw-300'}>{text}</h2>
            }
            
            {!imgBg && !imgBottom &&
               <div className="line-logo mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.81 27.42"><rect fill="#ffec00" x="7.47" y="7.36" width="16.34" height="20.06"/><polygon fill="#fdc800" points="5.05 23.61 3.69 23.61 3.69 3.64 20.08 3.64 20.08 5.01 5.05 5.01 5.05 23.61"/><polygon fill="#e58c00" points="1.36 19.96 0 19.96 0 0 16.39 0 16.39 1.36 1.36 1.36 1.36 19.96"/></svg>
               </div>
            }
         </div>

         {imgBottom &&
            <img src={imgBottom} alt="" />
         }
      </section>
	);
}