import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/grey-wave.webp';
 
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function DataAnalytics() {
	return (
		<>
		   <HelmetSeo
            pageTitle="Data Analytics - Milanesi Editore"
            description="Scegliamo la piattaforma professionale di analisi dei dati di navigazione più adatta alle tue esigenze, potrai controllare le statistiche del tuo sito web e del tuo PcProfile e ottenere report dettagliati"
            keys="data analytics, analisi dati, raccolta dati, strategia digitale, statistiche, statistiche sito web"
         />
			
			<PageTitle
				title='Data Analytics'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m28.94,19.73l8.97-13.95c.34.13.7.21,1.09.21,1.65,0,3-1.35,3-3s-1.35-3-3-3-3,1.35-3,3c0,.91.42,1.72,1.06,2.27l-8.97,13.95c-.34-.13-.7-.21-1.09-.21-.91,0-1.71.41-2.27,1.06l-8.95-5.96c.13-.34.21-.71.21-1.09,0-1.65-1.35-3-3-3s-3,1.35-3,3c0,.91.42,1.72,1.06,2.27l-6.91,10.96c-.35-.15-.74-.23-1.15-.23-1.65,0-3,1.35-3,3s1.35,3,3,3,3-1.35,3-3c0-.89-.4-1.68-1.01-2.23l6.92-10.98c.34.13.7.21,1.09.21.91,0,1.71-.41,2.27-1.06l8.95,5.96c-.13.34-.21.71-.21,1.09,0,1.65,1.35,3,3,3s3-1.35,3-3c0-.91-.42-1.72-1.06-2.27ZM39,1c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2ZM3,31c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM13,15c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm14,9c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/></svg>}
				text='Trasforma i dati in conoscenza'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h2>Data Analytics</h2>
					<p>Scegliamo la piattaforma professionale di analisi dei dati di navigazione più adatta alle tue esigenze, potrai controllare le <b>statistiche del tuo sito web e del tuo PcProfile</b> e ottenere report dettagliati e molto altro. Proponiamo sia offerte Free con analisi periodica dei tuoi dati, che offerte più professionali e personalizzate con analisi dei dati anche in tempo reale per soddisfare qualsiasi esigenza.</p>
				</div>

				<Line
					options={
						{
							responsive: true,
							plugins: {
								legend: { position: 'top' }
							}
						}
					}
					data={
						{
							labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
							datasets: [
								{
									label: '2021',
									data: [22, 32, 50, 40, 99, 73, 85],
									borderColor: 'rgb(255, 99, 132)',
									backgroundColor: 'rgba(255, 99, 132, .5)',
								},
								{
									label: '2022',
									data: [15, 25, 67, 39, 55, 85, 75],
									borderColor: 'rgb(53, 162, 235)',
									backgroundColor: 'rgba(53, 162, 235, .5)',
								},
							]
						}
					}
				/>
			</section>
			
			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}