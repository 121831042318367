export default function validate(data, initValue) {
	const errors = {};
	const emailRgx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	const phoneRgx = /^(?:(?:\+|00)39)?[ ]?(?:\d{2,4}[ ]?){0,3}\d{2,4}$/;

	const validationRules = {
		agency: {
			required: true,
			minLength: 3,
			errorMessage: 'Il campo Ragione Sociale è obbligatorio e deve contenere almeno 3 caratteri.',
		},
		name: {
			required: true,
			minLength: 3,
			errorMessage: 'Il campo Nome è obbligatorio e deve contenere almeno 3 caratteri.',
		},
		surname: {
			required: true,
			minLength: 3,
			errorMessage: 'Il campo Cognome è obbligatorio e deve contenere almeno 3 caratteri.',
		},
		email: {
			required: true,
			regex: emailRgx,
			errorMessage: 'Il campo Email è obbligatorio e deve essere un indirizzo email valido.',
		},
		phone: {
			regex: phoneRgx,
			errorMessage: 'Numero di telefono non valido.',
		},
		country: {
			required: true,
			minLength: 3,
			errorMessage: 'Il campo Comune è obbligatorio e deve contenere almeno 3 caratteri.',
		},
		province: {
			required: true,
			minLength: 2,
			errorMessage: 'Il campo Provincia è obbligatorio e deve contenere almeno 2 caratteri.',
		},
		address: {
			required: true,
			minLength: 4,
			errorMessage: 'Il campo Indirizzo è obbligatorio e deve contenere almeno 4 caratteri.',
		}
	}

	Object.keys(initValue).forEach((key) => {
		const rule = validationRules[key];

		if (rule) {
			if (rule.required && (!data[key] || !data[key].trim())) {
				errors[key] = rule.errorMessage;
			} else if (rule.minLength && data[key] && data[key].trim().length < rule.minLength) {
				errors[key] = rule.errorMessage;
			} else if (rule.regex && data[key] && !rule.regex.test(data[key].trim())) {
				errors[key] = rule.errorMessage;
			}
		}
	});

	return errors;
}