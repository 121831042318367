import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';

import topBg from '../../imgs/bg/foto-video-bg.webp';
import bg from '../../imgs/prodotti/fumetti.png';
import gallery from '../../imgs/prodotti/gallery.webp';
import fotografie from '../../imgs/prodotti/fotografie.webp';
import obiettivo from '../../imgs/prodotti/obiettivo.webp';
import video from '../../imgs/prodotti/video.webp';
import virtualtour from '../../imgs/prodotti/virtualtour.webp';


export default function FotoVideo() {
	const isMobile = useWindowWidth() < 810;
	return (
		<>
		   <HelmetSeo
            pageTitle="Foto e Video - Milanesi Editore"
            description="Hai bisogno di immagini di alta qualità per il tuo sito web o i tuoi materiali di marketing? Offriamo un vasto catalogo di fotografie professionali"
            keys="foto, video, foto stock, licenza foto, fotografie professionali, immagini, alta qualità, sito web, marketing"
         />
			
			<PageTitle
				title='Foto e Video'
				text='Vuoi un aspetto professionale per il tuo sito? Scegli immagini professionali.'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#fff"><path d="m30.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h29c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h29c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"/><path d="m20.5,12.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm0-3c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z"/><path d="m19.03,20.87c-.13,0-.26-.05-.35-.15l-7.52-7.52c-.26-.26-.71-.26-.97,0l-5.12,5.12c-.2.2-.51.2-.71,0s-.2-.51,0-.71l5.12-5.12c.64-.64,1.75-.64,2.39,0l7.18,7.18,3.5-3.33c.31-.31.73-.49,1.18-.49h0c.45,0,.87.18,1.19.5l2.82,3.07c.19.2.17.52-.03.71-.2.19-.52.17-.71-.03l-2.8-3.05c-.12-.11-.29-.19-.47-.19h0c-.18,0-.36.07-.49.2l-3.87,3.67c-.1.09-.22.14-.34.14Z"/><path d="m27.5,25H4.5c-.28,0-.5-.22-.5-.5V4.5c0-.28.22-.5.5-.5h23c.28,0,.5.22.5.5v20c0,.28-.22.5-.5.5Zm-22.5-1h22V5H5v19Z"/></svg>}
				imgBg={topBg}
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				{!isMobile &&
					<img src={fotografie} className="mx-w-25" alt="Immagini di alta qualità" />
				}

				<div>
					<h2>Foto Professionali per il Tuo Business</h2>
					<p>Hai bisogno di immagini di alta qualità per il tuo sito web o i tuoi materiali di marketing? Offriamo un <b>vasto catalogo di fotografie professionali</b>, acquisite <b>con diritti d'autore</b> dai più importanti fornitori mondiali. Puoi esplorare la nostra libreria e selezionare le immagini che meglio rappresentano la tua azienda.</p>
					<p>L'utilità di queste foto è duplice. Una volta stabilita la grafica del tuo sito, potrai visualizzare il tuo sito in anteprima, in modo da <b>vedere come si adatta alla tua attività</b>. Inoltre, queste immagini professionali possono servire come fonte d'ispirazione per la creazione delle tue foto personalizzate o persino essere utilizzate direttamente in determinati contesti e necessità.</p>
					<p>La presentazione visiva del tuo brand è fondamentale per catturare l'attenzione dei tuoi clienti.</p>
				</div>

				{isMobile &&
					<img src={fotografie} className="mx-w-25" alt="Immagini di alta qualità" />
				}
			</section>

			<hr />

			<section className="container grid descritive g-3 place-center">
				<div>
					<h2 className="mb-1">Personalizzazione delle Immagini</h2>
					<p>Una volta scelta l'immagine perfetta, puoi crearne una <b>LikeBox personalizzata</b>. Il nostro <b>team di editor d'immagini</b> si occuperà di adattare e personalizzare le foto scelte per garantire che si integrino perfettamente con il tuo sito web o i tuoi materiali di marketing. La presentazione visiva del tuo brand è fondamentale per catturare l'attenzione dei tuoi clienti.</p>
				</div>

				<img src={gallery} alt="Personalizzazione delle Immagini" />
			</section>

			<hr />
				
			<section className="container">
				<div className="box-line-grid-2">
               <div>
				      <img src={obiettivo} className={`${!isMobile ? 'mx-h-20' : 'mx-w-15'} mb-2`} alt="Servizio Fotografico Personalizzato" />
                  <h2>Servizio Fotografico Personalizzato</h2>
						<p>Se desideri immagini uniche e personalizzate per la tua azienda, siamo qui per aiutarti. <b>Il nostro team di fotografi professionisti può catturare l'essenza della tua attività</b>, creando immagini su misura per le tue esigenze. Dalle immagini dei prodotti alle foto del tuo team, possiamo creare un set di foto che ti distinguerà dalla concorrenza.</p>
               </div>

               <div>
				      <img src={video} className={`${!isMobile ? 'mx-h-20' : 'mx-w-15'} mb-2`} alt="Realizzazione di Video Aziendali" />
                  <h2>Realizzazione di Video Aziendali</h2>
						<p><b>Il video è un potente strumento di comunicazione</b>. Offriamo servizi di <b>produzione video</b> per promuovere la tua azienda o i tuoi prodotti. Dalla creazione di video aziendali che raccontano la tua storia alle pubblicità accattivanti, il nostro team di esperti può realizzare video che catturano l'attenzione e coinvolgono il tuo pubblico.</p>
               </div>
            </div>
			</section>

			<section className="section-bg pbk-lg txt-white" style={{"--bg-url": `url(${bg})`}}>
				<div className="container">
					<h2 className="txt-white">Scegli le migliori immagini per il tuo sito internet !</h2>
					<p>Un'ampia scelta tra fotografie, vettoriali e clip video per un sito professionale</p>
				</div>
			</section>

			<section className="container grid g-3 place-center">
				{!isMobile &&
					<img src={virtualtour} className="mx-w-25" alt="Virtual Tour Interattivo" />
				}

				<div>
					<h2>Virtual Tour Interattivo</h2>
					<p className="mb-4">Porta <b>i tuoi clienti in un viaggio virtuale attraverso il tuo spazio</b>. Con il nostro servizio di <b>virtual tour</b>, puoi offrire un'esperienza interattiva ai visitatori del tuo sito web. <b>Mostra loro l'interno della tua azienda, il tuo showroom</b> o qualsiasi altro luogo che desideri far conoscere.</p>

					{isMobile &&
						<img src={virtualtour} className="mx-w-25 mb-3" alt="Virtual Tour Interattivo" />
					}

					<a href="https://my.matterport.com/show/?m=Nua58ERHkGX" target="_blank" rel="noreferrer" className="btn btn-feature bg-red">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m16,21h-.05c-2.76-.03-4.98-2.29-4.95-5.05.03-2.74,2.27-4.95,5-4.95h.05c2.76.03,4.98,2.29,4.95,5.05h0c-.03,2.74-2.27,4.95-5,4.95Zm0-9c-2.19,0-3.98,1.77-4,3.96-.02,2.21,1.75,4.02,3.96,4.04,2.24.03,4.02-1.75,4.04-3.96.02-2.21-1.75-4.02-3.96-4.04h-.04Zm4.5,4.05h0,0Z"/><circle cx="1" cy="16" r="1"/><circle cx="31" cy="16" r="1"/><circle cx="16" cy="1" r="1"/><circle cx="16" cy="31" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="27" cy="27" r="1"/><circle cx="27" cy="5" r="1"/><circle cx="5" cy="27" r="1"/><path d="m16,24h-.08c-2.14-.02-4.14-.87-5.63-2.4s-2.31-3.54-2.29-5.68c.04-4.41,3.64-7.94,8.08-7.92h7.42c.28,0,.5.22.5.5v7.58h0c-.04,4.39-3.63,7.92-8,7.92Zm0-15c-3.83,0-6.96,3.09-7,6.93-.02,1.87.69,3.63,2,4.97s3.06,2.08,4.93,2.1h.07c3.83,0,6.96-3.09,7-6.93v-7.07h-7Zm7.5,7.08h0,0Z"/></svg>
						</div>
						Virtual Tour
					</a>
				</div>
			</section>

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-red"
			/>
		</>
	);
}