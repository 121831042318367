import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import httpsSsl from '../../imgs/sitiweb/https-ssl.webp';
import httpsSecure from '../../imgs/sitiweb/https-secure.webp';
import httpsEcommerce from '../../imgs/sitiweb/https-ecommerce.webp';

export default function Https() {
	return (
		<>
			<HelmetSeo
				pageTitle="Protocollo HTTPS  - Milanesi Editore"
				description="SSL è lo standard della tecnologia che va a garantire la sicurezza di una connessione internet e protegge i dati scambiati fra due sistemi"
				keys="protocollo ssl, certificati ssl, https, certificati https, sicurezza informatica, sicurezza  sito, browser, sito web, sito web sicuro, posizionamento motore di ricerca, Hyper Text Transfer Protocol Secure, Secure Sockets Layer"
			/>
			
			<PageTitle
				title='" https:// "'
				text='Aumenta la fiducia dei tuoi visitatori'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="m25.5,32H2.5c-1.38,0-2.5-1.12-2.5-2.5v-14c0-1.38,1.12-2.5,2.5-2.5h23c1.38,0,2.5,1.12,2.5,2.5v14c0,1.38-1.12,2.5-2.5,2.5ZM2.5,14c-.83,0-1.5.67-1.5,1.5v14c0,.83.67,1.5,1.5,1.5h23c.83,0,1.5-.67,1.5-1.5v-14c0-.83-.67-1.5-1.5-1.5H2.5Z"/><path d="m4.5,14c-.28,0-.5-.22-.5-.5v-3.5C4,4.49,8.49,0,14,0s10,4.49,10,10v3.42c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.42c0-4.96-4.04-9-9-9S5,5.04,5,10v3.5c0,.28-.22.5-.5.5Z"/><path d="m8.5,13.99c-.28,0-.5-.22-.5-.5v-3.49c0-3.31,2.69-6,6-6s6,2.69,6,6v3.45c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.45c0-2.76-2.24-5-5-5s-5,2.24-5,5v3.49c0,.28-.22.5-.5.5Z"/><path d="m27.12,27H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m27.12,21H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m1.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"/><path d="m7.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"/><path d="m13.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"/><path d="m19.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"/></svg>}
			/>

			<section className="container pt-sm-4 grid gtc-2-1 g-3 place-center">
				<div>
					<h2>Protocollo “ HTTPS ”</h2>
					<h3>Hyper Text Transfer Protocol Secure</h3>
					<p>È il protocollo standard di trasferimento ipertestuale sicuro, la dicitura “https://” viene visualizzata nel URL del sito web protetto con un certificato SSL (Secure Sockets Layer). Se si clicca sul simbolo del lucchetto nella barra del browser, è possibile visualizzare i dettagli del certificato, il nome aziendale del proprietario del sito web oltre che i dati relativi all'autorità di emissione.</p>

					<h2>Certificato “ SSL ”</h2>
					<h3>Secure Sockets Layer (livello di sockets sicuri)</h3>
					<p>È lo standard della tecnologia che va a garantire la sicurezza di una connessione internet e protegge i dati scambiati fra due sistemi impedendo agli utenti esterni di leggere e o modificare le informazioni che si stanno trasferendo, le quali potrebbero comprendere anche informazioni riservate, coordinate bancarie etc. La comunicazione fra sistemi può riguardare un server o client, ad es. un sito web di eCommerce e un browser, o due server ad es. un'applicazione basata su informazioni personalmente identificabili o dati appartenenti ad una banca dati riservata.</p>
					<p>Il Secure Sockets Layer serve ad impedire la lettura e l'intercettazione di qualsiasi dato trasferito fra utenti e i siti web o fra due sistemi. È possibile utilizzare algoritmi crittografici per “nascondere” i dati in transito, impedendone la lettura agli hacker durante il transito da un sito ad un utente, o tra due sistemi. Queste informazioni possono essere di natura sensibile o personale e protette da privacy, come ad esempio la banca dati telefonica presente su paginecomunali.it.</p>
				</div>

				<img src={httpsSsl} alt="Sito web protetto con certificato SSL" />
			</section>

			<section className="bg-light">
				<div className="container grid g-2 place-center">
					<svg title="Transport Layer Security" viewBox="0 0 115 134.92" className="mx-w-15">
						<path fill="#adadad" d="M111.66,21.67C98.92,9.83,82.88,2.64,64,.3c-.2-.03-.39,.12-.42,.33-.03,.21,.12,.39,.33,.42,18.67,2.31,34.52,9.4,47.12,21.07v50.4c-3.46,24.09-21.23,44.11-54.32,61.19V0h-.37c-.07,0-.14,0-.22,0C35.77,0,16.93,7.29,.13,21.66l-.13,.11v50.83c2.24,22.34,17.04,40.33,47.98,58.32,.18,.11,.41,.04,.51-.14,.1-.18,.04-.41-.14-.51C17.64,112.41,2.96,94.6,.75,72.56V22.12C17.33,7.98,35.9,.79,55.96,.75V134.92l.55-.28c33.67-17.25,51.75-37.54,55.27-62.08V21.78l-.12-.11Z"/>
					</svg>

					<div>
						<h2>Certificato “ TLS ”</h2>
						<h3>Transport Layer Security (sicurezza del livello di trasporto)</h3>
						<p>È il diretto successore del protocollo SSL. Il TLS è una versione aggiornata, migliorata e più sicura del precedente SSL. Il protocollo in se funziona in maniera analoga al suo predecessore, utilizzando la crittografia per proteggere il trasferimento di dati e informazioni. I due termini vengono spesso utilizzati indifferentemente, anche perché la sigla SSL è piuttosto diffusa e facilmente riconoscibile. Tuttavia al momento di attivare un protocollo di sicurezza, i clienti otterranno certificati TLS aggiornati con crittografia ECC, RSA o DSA.</p>
					</div>
				</div>
			</section>

			<section className="container grid g-2">
				<div>
					<h2>Come funziona la comunicazione tra il browser e il tuo sito web protetto da un Certificato SSL</h2>
					<ul className="list">
						<li>Il browser tenta di connettersi al tuo sito web protetto da un certificato SSL.</li>
    					<li>Il browser chiede al server web di identificarsi.</li>
    					<li>Il server invia al browser una copia del proprio certificato SSL.</li>
    					<li>Il browser verifica se il certificato SSL è attendibile, in caso affermativo, invia un messaggio al server.</li>
    					<li>Il server restituisce un segnale di conferma con firma digitale per dare inizio a una sessione SSL crittografata.</li>
					</ul>
				</div>
				
				<img src={httpsSecure} className="mx-w-35" alt="Comunicazione tra browser e sito web protetto" />
			</section>

			<section className="bg-light">
				<div className="container">
					<h2>I vantaggi per tutti</h2>
					<p>Nonostante il certificato SSL venga installato dal lato server, tutti i browser offrono all’utente che visita l vostro sito web indicazioni visive sulla suddetta attivazione. Innanzitutto verrà visualizzata la dicitura https:// all’inizio dell’indirizzo del sito, contrariamente al convenzionale “http://” , la “s” aggiuntiva sottintende “sicuro”. A seconda del livello di certificato fornito, la conferma di una connessione sicura può essere indicata da un’icona a forma di lucchetto o dalla barra dell’indirizzo di colore verde.</p>

					<h2>Google</h2>
					<p>Da alcuni anni, Google richiede l’utilizzo di HTTPS o SSL in qualsiasi sito web, e dal 2014 ha deciso di offrire dei vantaggi in termini di <a href="https://developers.google.com/search/blog/2014/08/https-as-ranking-signal?hl=it" target="_blank" rel="noreferrer">posizionamento nel motore di ricerca</a> ai siti web sicuri, questo è un altro buon motivo per installare un certificato SSL in qualsiasi sito anche se non necessariamente in un eCommerce.</p>
				</div>
			</section>

			<section className="container grid gtc-3 g-3">
				<div>
					<h2>L’eCommerce ha bisogno del Certificato SSL!</h2>
					<p>Tutti i siti web delle aziende che accettano pagamenti con carta di credito o debito, devono utilizzare un certificato SSL o TLS per garantire un adeguata sicurezza al loro sito web.</p>
					<p>È una procedura fondamentale ed obbligatoria atta per garantire la sicurezza del proprio sito web, la protezione dei clienti e l’aumento drastico della percezione di sicurezza del tuo sito web.</p>
				</div>

				<img src={httpsEcommerce} alt="Garantire la sicurezza del proprio sito web eCommerce" />

				<div>
					<h2>Perchè i carrelli vengono abbandonati?</h2>
					<p>Business Insider riporta che, circa il 74% dei carrelli acquisti viene abbandonato, ma sostiene inoltre che il 64% possa essere recuperato grazie all'ottimizzazione del flusso e della sicurezza del pagamento.</p>
					<p>Inoltre sostiene che la maggior parte di questo 64% completerà un acquisto dopo aver ottenuto informazioni sulla sicurezza dell'area relativa al pagamento. Sono cifre che nessun azienda può permettersi di ignorare.</p>
				</div>
			</section>

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}