import { Link } from "react-router-dom";
import HelmetSeo from "../../components/HelmetSeo";
import image from '../../imgs/profile/portale.png';

export default function Profile() {
	return (
		<>
         <HelmetSeo
				pageTitle="PcProfile - Milanesi Editore"
				description="Inserisci la tua attività su PagineComunali"
				keys="pagine comunali, elenchi telefonici"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>PcProfile</h1>
					<p>Inserisci la tua attività su PagineComunali:</p>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/profile/scheda-web">Scheda web</Link></li>
						<li><Link to="/cosa-offriamo/profile/descrizione">Descrizione</Link></li>
						<li><Link to="/cosa-offriamo/profile/come-funziona">Come funziona</Link></li>
						<li><Link to="/cosa-offriamo/profile/prezzi">Prezzi</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-35" alt="PcProfile" />
			</section>
		</>
	);
} 