import { profileFaqs } from '../../data/profileFaqs';
import useWindowWidth from '../../hooks/useWindowWidth';
import Faq from '../../components/Faq';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import titleBg from '../../imgs/bg/grey-wave.webp';
import update from '../../imgs/profile/update.jpg';
import seo from '../../imgs/profile/seo.png';
import bersaglio from '../../imgs/profile/bersaglio.png';
import news from '../../imgs/profile/news.jpg';


export default function ComeFunziona() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
		   <HelmetSeo
            pageTitle="Come funziona PcProfile Pagine Comunali - Milanesi Editore"
            description="Registrare la tua azienda su Pagine Comunali è semplice e veloce. Scegli il profilo più adatto alle tue esigenze, ti proponiamo un profilo"
            keys="funziona, servizio, profilo"
         />
			
			<PageTitle
				title='Come Funziona'
				text="Scopri come funziona il servizio giusto per te punto per punto"
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.58 32"><path d="m10.48,20.73c-.2,0-.38-.12-.46-.31l-2.66-6.42c-.1-.24,0-.51.22-.63l7.23-3.96c.13-.07.28-.08.41-.03.14.05.24.15.29.29l3.16,8.16c.05.13.04.28-.02.4-.06.13-.17.22-.31.26l-7.72,2.23s-.09.02-.14.02Zm-2.03-6.7l2.32,5.59,6.77-1.95-2.75-7.11-6.34,3.47Z"/><path d="m2,22.6c-.2,0-.38-.12-.46-.31L.04,18.68c-.1-.25,0-.53.25-.64l7.73-3.69c.12-.06.27-.06.39-.02.13.05.23.15.28.28l1.84,4.44c.05.13.05.27,0,.39-.06.13-.16.22-.29.27l-8.07,2.86c-.06.02-.11.03-.17.03Zm-.85-3.86l1.13,2.73,7.13-2.53-1.44-3.47-6.82,3.26Z"/><path d="m18.58,19.5c-.2,0-.39-.12-.47-.32l-3.91-10.22c-.09-.24,0-.5.23-.62l11.91-6.28c.12-.07.27-.08.41-.03.13.05.24.15.29.28l5,12.5c.05.13.05.27-.01.4-.06.13-.17.22-.3.26l-13,4s-.1.02-.15.02Zm-3.28-10.48l3.58,9.37,12.03-3.7-4.59-11.48-11.02,5.82Z"/><path d="m34.08,16c-.2,0-.39-.12-.46-.31L27.61.69c-.1-.26.02-.55.28-.65.25-.1.55.02.65.28l6,15c.1.26-.02.55-.28.65-.06.02-.12.04-.19.04Z"/><path d="m16.08,32c-.28,0-.5-.22-.5-.5v-7c0-.28.22-.5.5-.5s.5.22.5.5v7c0,.28-.22.5-.5.5Z"/><path d="m25.08,32c-.14,0-.28-.06-.38-.17l-6-7c-.18-.21-.16-.52.05-.7.21-.18.53-.16.7.05l6,7c.18.21.16.52-.05.7-.09.08-.21.12-.33.12Z"/><path d="m7.08,32c-.12,0-.23-.04-.33-.12-.21-.18-.23-.5-.05-.7l6-7c.18-.21.5-.23.7-.05.21.18.23.5.05.7l-6,7c-.1.12-.24.17-.38.17Z"/><path d="m19.08,25h-6c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h3c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-5.5-1h5v-.92c0-.61-.43-1.08-1-1.08h-3c-.59,0-1,.41-1,1v1Z"/></svg>}
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4">
				<h2>REGISTRAZIONE</h2>
				<h3>SEMPLICE E VELOCE</h3>
				<p>Registrare la tua azienda su Pagine Comunali è semplice e veloce. Scegli il profilo più adatto alle tue esigenze, ti proponiamo un profilo.</p>
				<div className="grid gtc-4 g-2 mb-3">
					<div className="card-box">
						<h2>Free</h2>
						<p>Gratuito e senza limite di tempo, puoi inserire i dati che riguardano la tua azienda, inoltre puoi inserire gli orari di apertura e chiusura, nonché i giorni di riposo o ferie.</p>
					</div>

					<div className="card-box">
						<h2>Smart</h2>
						<p>Ti permette di aumentare notevolmente le informazioni contenute nel tuo PcProfile, potrai inserire il logo, la descrizione, le fotografie, il link al tuo sito web, le parole chiave e tanto altro.</p>
					</div>

					<div className="card-box">
						<h2>Fast</h2>
						<p>Porta al massimo le prestazioni del tuo profilo, inoltre ti permette di pubblicare news, offerte o eventi in tempo reale, potrai anche duplicate sul tuo PcwebSite Pro.</p>
					</div>

					<div className="card-box">
						<h2>Multisede</h2>
						<p>Avrai una consulenza personalizzata da un nostro incaricato per costruire la soluzione più adatta alla tua azienda, potrà comprendere tutte o in parte le funzioni degli altri PcProfile.</p>
					</div>
				</div>
				<h3>GESTIONE DEL PROPRIO ACCOUNT</h3>
				<p className="mb-5">Entro cinque giorni dalla registrazione riceverai via mail il codice di accesso all’area personale PcProfile di PagineComunali che ti permetterà di gestire in totale autonomia il tuo profilo. Una completata la procedura di login potrai consultare e o modificare i dati in base al profilo scelto in qualsiasi momento. Un consulente sarà sempre a tua disposizione per qualsiasi chiarimento.</p>

				<div className="grid g-3 rg-10 place-center mb-5">
					<div>
						<h2>VISIBILIÀ</h2>
						<h3>PERCHE' REGISTRARSI ?</h3>
						<p>Nella banca dati PagineComunali sono presenti milioni di imprese operanti nel mercato nazionale. Il sito PagineComunali.it ha ricevuto milioni di visite di utenti unici al mese, quindi essere censito correttamente è di estrema importanza per la tua azienda. Aumentare le informazioni contenute nel tuo profilo potrà aumentare le possibilità di crescita del tuo business. Inoltre con il servizio gratuito PcBook gli utenti potranno salvare la vostra azienda in un area riservata, scrive le annotazioni personali ed averla sempre a portata di mano.</p>
					</div>
					
					<img src={bersaglio} className="mx-w-25" alt="Banca dati PagineComunali" />

					{!isMobile &&
						<img src={seo} className="mx-w-25" alt="Search Engine Optimization" />
					}

					<div>
						<h2>SEO</h2>
						<p>SEO, Search Engine Optimization, tutti i contenuti del tuo profilo sono ottimizzati per il posizionamento sui principali motori di ricerca come Google, Bing e Yahoo. I profili Smart, Fast, Multisede o SpecialCat sono forniti di dominio di terzo livello con Protocollo “https//” a garanzia della vostra professionalità e credibilità nel web.</p>
					</div>

					{isMobile &&
						<img src={seo} className="mx-w-25" alt="Search Engine Optimization" />
					}

					<div>
						<h2>UPGRADE PROFILO</h2>
						<p>I PcProfile proposti sono costruiti per soddisfare tutte le esigenze della tua azienda, esistono anche dei profili speciali, PcProfile Specialcat, progettati e costruiti per permettervi di mettere in evidenza le particolarità della vostra azienda. Dall’area persona di gestione del tuo profilo, potrai decidere di sottoscrivere un profilo di livello superiore in qualsiasi momento, in questo modo potrai ampliare sempre più le possibilità di essere trovato da nuovi clienti.</p>
					</div>

					<img src={update} className="mx-w-25" alt="Upgrade PcProfile" />
				</div>

				<h2>NEWS OFFERTE EVENTI</h2>
				<p>I PcProfile Fast, SpecialCat o Multisede prevedono la possibilità di pubblicare informazioni commerciali giornaliere o periodiche. La pianificazione è gestita in totale autonomia da parte del cliente, il quale deciderà anticipatamente il giorno di pubblicazione, la durata della stessa e di conseguenza il giorno di oscuramento, il quale “oscuramento” non presuppone la cancellazione delle informazioni, ma solo la rimozione dall’area pubblica “online”, la cancellazione dei dati è prerogativa esclusiva del cliente, il quale potrà richiamarle e ripubblicarle successivamente.</p>
				<p className="mb-5">Esistono anche dei profili speciali, PcProfile Specialcat, progettati e costruiti per permettervi di mettere in evidenza le particolarità della vostra azienda.</p>

				<img src={news} className="mb-5" alt="" />

				<h2 className="txt-center">FAQ</h2>
				{profileFaqs.map((faq, idx) =>
					<Faq
						key={idx}
						question={faq.question}
						answer={faq.answer}
					/>
				)}
			</section>
		</>
	);
}