import HelmetSeo from "../../components/HelmetSeo";
import PriceTable from '../../components/PriceTable';
import Cta from '../../components/Cta';

export default function Prezzi() {
	return (
		<>
			<HelmetSeo
				pageTitle="Prezzi Siti web - Milanesi Editore"
				description="Scegli l'offerta ideale per te tra le nostre soluzioni di sito web Onepage, Starte, Pro e eCommerce"
				keys="prezzi siti web, prezzi, costo sito web"
			/>

			<PriceTable activeAll />

			<hr />
			
			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}