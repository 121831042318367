export default function Form({ noborder, formRef, errorsForm, handleSubmit, isSent, isSubmit, children }) {
	return (
		<form ref={formRef} className={noborder ? "" : "form-border"} onSubmit={handleSubmit}>
      
         {children}

			{Object.keys(errorsForm).length > 0 &&
				<p className="txt-danger">Errore! Controlla e correggi i campi del form.</p>
			}

			{isSent &&
            <h3 className="txt-success">Invio riuscito!</h3>
         }

			<button type="submit" className="btn mb-1" disabled={isSubmit ? true : false}>Invia</button>
         
         <small className="visible">* Campi obbligatori.</small>
      </form>
	);
}