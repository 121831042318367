export default function FeatureBlock({ icon, title, text }) {
   return (
      <div className="feature-box">
         <div className="circle">
            {icon}
         </div>
         <h4>{title}</h4>
         <small>{text}</small>
      </div>
   );
}