function SelectField({ label, name, options, handleChange, required=false }) {
	return (
      <label htmlFor={name}>
         <span>{label} {required ? '*' : ''}</span>
         <select
            name={name}
            id={name}
            required={required}
            onChange={handleChange}
         >
            <option value="">- Seleziona -</option>
            {options.map((val, idx) =>
               <option key={idx} value={val}>{val}</option>
            )}
         </select>
      </label>
	);
}

export default SelectField;