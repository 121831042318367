import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/red-wave.webp';
import email from '../../imgs/advertising/email.webp';
import piechart from '../../imgs/advertising/piechart.webp';
import database from '../../imgs/advertising/database.webp';


export default function EmailMarketing() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
		   <HelmetSeo
            pageTitle="Email Marketing - Milanesi Editore"
            description="Le nostre campagne offrono la possibilità di raggiungere i tuoi potenziali clienti in modo altamente segmentato, garantendo una maggiore rilevanza ed efficacia dei messaggi inviati"
            keys="email marketing, campagne email, email, lead, trovare clienti"
         />
			
			<PageTitle
				title='Email Marketing'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.98 32"><path d="m36.4,32H1.57c-.87,0-1.57-.7-1.57-1.57V11.51c0-.18.09-.34.24-.43L17.93.41c.6-.55,1.52-.55,2.12,0l17.69,10.67c.15.09.24.25.24.43v18.92c0,.86-.71,1.57-1.57,1.57ZM1,11.79v18.64c0,.31.26.57.57.57h34.83c.32,0,.57-.25.57-.57V11.79L19.48,1.24s-.07-.05-.1-.07c-.22-.22-.58-.22-.8,0-.03.03-.06.05-.1.07L1,11.79Z"/><path d="m13.49,20c-.09,0-.18-.02-.26-.07L.62,12.41c-.24-.14-.31-.45-.17-.69.14-.24.45-.32.69-.17l12.61,7.52c.24.14.31.45.17.69-.09.16-.26.24-.43.24Z"/><path d="m24.49,20c-.17,0-.34-.09-.43-.24-.14-.24-.06-.54.17-.69l12.61-7.52c.24-.14.54-.06.69.17s.06.54-.17.69l-12.61,7.52c-.08.05-.17.07-.26.07Z"/><path d="m36.99,31.5c-.1,0-.19-.03-.28-.08l-17.25-11.48c-.21-.16-.75-.16-.92-.02L1.27,31.41c-.23.15-.54.09-.69-.14-.15-.23-.09-.54.14-.69l17.25-11.48c.5-.4,1.56-.4,2.1.02l17.21,11.46c.23.15.29.46.14.69-.1.14-.25.22-.42.22Z"/></svg>}
				text='La tua voce diretta al cuore dei tuoi clienti'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Email Marketing</h2>
					<p>Siamo convinti che l'email marketing rimanga un metodo estremamente efficace per raggiungere il tuo pubblico di riferimento, superando spesso altri mezzi di comunicazione in termini di portata e tasso di conversione.</p>
					<p>Grazie alla nostra esperienza nel settore dell'editoria di elenchi telefonici, siamo in possesso della <b>Banca Dati Unica (DBU) che include non solo numeri di telefono, ma anche gli indirizzi email</b> corrispondenti. La nostra banca dati è già <b>suddivisa per categoria merceologica, regione e comuni</b>, il che ci permette di indirizzare le email in modo altamente mirato verso una specifica categoria all'interno di un territorio determinato.</p>

					<p>Le nostre campagne offrono la possibilità di <b>raggiungere i tuoi potenziali clienti in modo altamente segmentato</b>, garantendo una maggiore rilevanza ed efficacia dei messaggi inviati. I nostri specialisti di marketing sviluppano strategie personalizzate per allargare e targettizzare le tue liste di distribuzione email.</p>
				</div>

				<img src={email} className="mx-w-25" alt="Email Marketing" />
				

				{!isMobile &&
					<img src={piechart} className="mx-w-25" alt="Benefici dell'Email Marketing" />
				}

				<div>
					<p><b>I nostri copywriter e designer lavorano a stretto contatto con te per creare messaggi di posta elettronica</b> che catturino l'attenzione del tuo pubblico su ogni dispositivo. Dalle campagne automatizzate di generazione di lead alla gestione delle email transazionali, <b>il nostro team multidisciplinare di copywriter, designer, sviluppatori e specialisti di marketing</b> è pronto ad aiutarti a raggiungere i tuoi obiettivi aziendali. Siamo in grado di creare, implementare, gestire e misurare tutte le tue campagne di email marketing in corso.</p>
					<p>Abbiamo a disposizione un team dedicato pronto a soddisfare ogni tua esigenza. Indipendentemente dagli obiettivi che ti prefiggi, siamo al tuo fianco per aiutarti a raggiungerli nel modo migliore e più efficiente. Analizziamo attentamente le tue specifiche necessità, definiamo la strategia, individuiamo le attività e le azioni da intraprendere e ti offriamo supporto in ogni fase di implementazione.</p>
				</div>

				{isMobile &&
					<img src={piechart} className="mx-w-25" alt="Benefici dell'Email Marketing" />
				}
				

				<div>
					<h2>Scopri le nostre soluzioni personalizzate</h2>
					<p>Configurazione della piattaforma di invio email, Grazie alla nostra vasta esperienza e alla conoscenza delle peculiarità del nostro DBU, siamo in grado di selezionare la piattaforma di email marketing più adatta alle tue esigenze, considerando il tuo budget, le dimensioni del database email, le necessità di automazione, le integrazioni richieste e altro ancora.</p>
					<p>Fornitura, gestione e segmentazione del database, <b>il nostro DBU altamente categorizzato</b>, pertanto possiamo aiutarti a raggiungere nuovi potenziali clienti e a mantenere le tue liste di contatti sempre aggiornate.</p>
					<p>La nostra expertise nella gestione dei dati ci consente di garantire la pulizia delle liste, la riattivazione degli utenti inattivi e l'utilizzo di filtri e opzioni di segmentazione insieme agli strumenti di automazione del marketing per offrire contenuti altamente rilevanti e personalizzati. Grafica, design e <b>sviluppo di DEM (Direct Email Marketing) e landing page</b>.</p>
				</div>

				<img src={database} className="mx-w-30" alt="Banche dati telefoniche e ricerca lead" />
			</section>

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="btn-feature-border-red"
			/>
		</>
	);
}