import useWindowWidth from '../../hooks/useWindowWidth';

import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import SectionRefPage from '../../components/SectionRefPage';
import PriceTable from '../../components/PriceTable';
import BtnScegliTemplate from '../../components/BtnScegliTemplate';
import Cta from '../../components/Cta';

import titleBg from '../../imgs/bg/green-wave.webp';
import fattiIspirare from '../../imgs/sitiweb/fatti-ispirare.webp';
import libreria from '../../imgs/sitiweb/libreria.webp';
import robotFunzionalita from '../../imgs/sitiweb/robot-funzionalita.webp';
import sitoOnline from '../../imgs/sitiweb/sito-online.webp';
import httpsSecure from '../../imgs/sitiweb/https-secure.webp';
import funzionalita from '../../imgs/sitiweb/funzionalita.webp';


export default function Starter() {
	const isMobile = useWindowWidth() < 1080;

	return (
		<>
			<HelmetSeo
				pageTitle="Siti web Starter - Milanesi Editore"
				description="I siti web Starter sono realizzati attraverso tecnologie all'avanguardia e affidabili. La scelta della piattaforma su cui sviluppare i siti web dei nostri clienti è il risultato di un'analisi accurata degli obiettivi"
				keys="sito web, sviluppo, analisi, obiettivi, template, prduzione, wordpress, html, react, manager dedicato, sito online, ssl, widget"
			/>

			<PageTitle
				title='Starter'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.62 32"><path d="m16.31,24c-.08,0-.15-.02-.22-.05-.34-.16-8.28-4.12-8.28-11.45V2.5c0-.28.22-.5.5-.5h17c.28,0,.5.22.5.5v10c0,7.34-8.92,11.29-9.3,11.46-.06.03-.13.04-.2.04ZM8.81,3v9.5c0,6.08,6.29,9.78,7.51,10.44,1.33-.64,8.49-4.35,8.49-10.44V3H8.81Z"></path><path d="m25.31,1H8.31c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h17c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.31,27c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"></path><path d="m6.31,16c-.11,0-.21-.03-.31-.1-1.82-1.41-4.79-4.59-5.67-7.24-.57-1.71-.41-3.31.46-4.51.71-.98,2.24-2.14,5.52-2.14h2c.28,0,.5.22.5.5s-.22.5-.5.5h-2c-2.26,0-3.89.6-4.71,1.73-.67.93-.79,2.22-.32,3.61.81,2.42,3.66,5.47,5.33,6.76.22.17.26.48.09.7-.1.13-.25.19-.4.19Z"></path><path d="m27.31,16c-.15,0-.3-.07-.4-.19-.17-.22-.13-.53.09-.7,1.56-1.21,4.7-4.87,5.33-6.76.47-1.4.35-2.68-.32-3.61-.82-1.13-2.44-1.73-4.71-1.73h-2c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2c3.28,0,4.81,1.17,5.52,2.14.86,1.2,1.03,2.8.46,4.51-.71,2.12-3.94,5.9-5.67,7.24-.09.07-.2.1-.31.1Z"></path><path d="m23.31,32h-14c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h11c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-13.5-1h13v-.92c0-.61-.43-1.08-1-1.08h-11c-.59,0-1,.41-1,1v1Z"></path></svg>}
				text='Avere il tuo sito internet non è mai stato così semplice.'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Perché scegliere un sito web Starter</h2>
					<p>I <b>siti web Starter</b> sono realizzati attraverso tecnologie all'avanguardia e affidabili. La scelta della piattaforma su cui sviluppare i siti web dei nostri clienti è il risultato di <b>un'analisi accurata degli obiettivi</b>, tenendo conto delle esigenze di manutenzione e gestione dei contenuti. Operiamo su tre ambienti distinti (<b>sviluppo, staging e produzione</b>) per garantire l’implementazione coordinata e scalabile dell'intero progetto, seguendo una solida pipeline di rilascio.</p>
					<p className="mb-4">La nostra specializzazione è nello sviluppo di Siti Web utilizzando tecnologie come <b>WordPress, HTML, PHP e React</b>.</p>
					{isMobile &&
						<img src={fattiIspirare} className="mx-w-25 mb-3" alt="Perché scegliere un sito web Starter" />
					}
				</div>

				{!isMobile &&
					<img src={fattiIspirare} className="mx-w-25" alt="Perché scegliere un sito web Starter" />
				}

				{!isMobile &&
					<img src={libreria} className="mx-w-25" alt="Scegli il template adatto a te dalle nostre librerie" />
				}

				<div>
					<h2>Scegli il template adatto a te</h2>
					<p className="mb-4">Scegli il template che meglio si adatta a te. Troverai opzioni <b>classiche, professionali, minimal, moderne</b>. Puoi personalizzare il template scelto <b>senza costi aggiuntivi</b>. Se un template non include una sezione o una pagina di cui hai bisogno, basta chiederla al tuo <b>client manager dedicato</b>, che sarà sempre al tuo fianco durante l'intero processo di creazione del tuo sito.</p>
					{isMobile &&
						<img src={libreria} className="mb-4" alt="Scegli il template adatto a te dalle nostre librerie" />
					}
					<BtnScegliTemplate />
				</div>

			
				<div>
					<h2>Personalizzalo come tu vuoi</h2>
					<p>Puoi personalizzare il template esattamente come vuoi. Dopo che hai scelto il template, <b>aggiungi le pagine di cui hai bisogno</b>, come le gallerie per le immagini, Il tuo team e tanto altro. Inserisci le informazioni della tua attività come il nome, il logo, i dettagli di contatto e il <b>collegamento a Whatsapp</b>, il <b>form di richiesta preventivo o contatto</b>, così che i clienti possano contattarti in modo facile e veloce.</p>
				</div>
				
				<img src={robotFunzionalita} className="mx-w-25" alt="Personalizza il tuo sito web" />
				
				{!isMobile &&
					<img src={sitoOnline} className="mx-w-25" alt="Sito web online collegato al tuo dominio" />
				}
				<div>
					<h2>Portiamo il tuo sito online</h2>
					<p>Puoi seguire il processo di <b>creazione del tuo sito web attraverso uno stage di sviluppo online</b>, intervenire in qualsiasi momento per chiarimenti, modifiche o implementazioni. Solo dopo la tua approvazione, il sito sarà ufficialmente online e collegato al tuo dominio. È importante ricordare che il sito web è uno <b>strumento in continua evoluzione</b>, avrai la possibilità di apportare miglioramenti o implementazioni anche in un secondo momento.</p>
				</div>
				{isMobile &&
					<img src={sitoOnline} className="mx-w-25" alt="Sito web online collegato al tuo dominio" />
				}
			</section>
			
			<hr />
			
			<SectionRefPage
				title="Protocollo Https"
				text="Il protocollo standard di trasferimento ipertestuale sicuro, la dicitura “https://” viene visualizzata nel URL del sito web protetto con un certificato SSL (Secure Sockets Layer). Se si clicca sul simbolo del lucchetto nella barra del browser, è possibile visualizzare i dettagli del certificato, il nome aziendale del proprietario del sito web oltre che i dati relativi all'autorità di emissione."
				urlBtn="/cosa-offriamo/sitiweb/https"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="m25.5,32H2.5c-1.38,0-2.5-1.12-2.5-2.5v-14c0-1.38,1.12-2.5,2.5-2.5h23c1.38,0,2.5,1.12,2.5,2.5v14c0,1.38-1.12,2.5-2.5,2.5ZM2.5,14c-.83,0-1.5.67-1.5,1.5v14c0,.83.67,1.5,1.5,1.5h23c.83,0,1.5-.67,1.5-1.5v-14c0-.83-.67-1.5-1.5-1.5H2.5Z"></path><path d="m4.5,14c-.28,0-.5-.22-.5-.5v-3.5C4,4.49,8.49,0,14,0s10,4.49,10,10v3.42c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.42c0-4.96-4.04-9-9-9S5,5.04,5,10v3.5c0,.28-.22.5-.5.5Z"></path><path d="m8.5,13.99c-.28,0-.5-.22-.5-.5v-3.49c0-3.31,2.69-6,6-6s6,2.69,6,6v3.45c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.45c0-2.76-2.24-5-5-5s-5,2.24-5,5v3.49c0,.28-.22.5-.5.5Z"></path><path d="m27.12,27H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m27.12,21H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m1.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m7.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m13.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m19.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path></svg>}
				img={httpsSecure}
			/>

			<SectionRefPage
				title="Funzionalità"
				text="Scoprite le molteplici funzionalità che offriamo per il vostro sito web, tra cui design responsivo, servizio foto/video, temi grafici animati, personalizzazione HTML/CSS, widget, Google MyBusiness, report/recensioni dettagliati, integrazione con Google Analytics 4 e soluzioni specifiche per il vostro eCommerce."
				urlBtn="/cosa-offriamo/sitiweb/funzionalita"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>}
				img={funzionalita}
				order={true}
				bg={true}
			/>

			{!isMobile &&
				<PriceTable active="Starter"/>
			}

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-sm"
				classBtn="btn-feature-border-green"
			/>
		</>
	);
}