import { featuresSitiweb } from '../../data/featuresSitiweb';
import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import FeatureBlock from '../../components/FeatureBlock';
import PriceTable from '../../components/PriceTable';
import BtnScegliTemplate from '../../components/BtnScegliTemplate';
import Cta from '../../components/Cta';

import titleBg from '../../imgs/bg/green-wave.webp';
import funzionalitaPc from '../../imgs/sitiweb/funzionalita-pc.webp';


export default function Funzionalita() {
	const isMobile = useWindowWidth() < 1080;

	return (
		<>
			<HelmetSeo
				pageTitle="Funzionalità - Milanesi Editore"
				description="Esplora le nostre soluzioni web personalizzate e scopri tutte le nostre funzionalità per il tuo sito web"
				keys="soluzioni web personalizzate, soluzioni web, funzionalità, sito web, presenza online, ecommerce, negozio online, siti vetrina, blog, landing page, html"
			/>

			<PageTitle
				title='Funzionalità'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.58 32"><path d="m10.48,20.73c-.2,0-.38-.12-.46-.31l-2.66-6.42c-.1-.24,0-.51.22-.63l7.23-3.96c.13-.07.28-.08.41-.03.14.05.24.15.29.29l3.16,8.16c.05.13.04.28-.02.4-.06.13-.17.22-.31.26l-7.72,2.23s-.09.02-.14.02Zm-2.03-6.7l2.32,5.59,6.77-1.95-2.75-7.11-6.34,3.47Z"></path><path d="m2,22.6c-.2,0-.38-.12-.46-.31L.04,18.68c-.1-.25,0-.53.25-.64l7.73-3.69c.12-.06.27-.06.39-.02.13.05.23.15.28.28l1.84,4.44c.05.13.05.27,0,.39-.06.13-.16.22-.29.27l-8.07,2.86c-.06.02-.11.03-.17.03Zm-.85-3.86l1.13,2.73,7.13-2.53-1.44-3.47-6.82,3.26Z"></path><path d="m18.58,19.5c-.2,0-.39-.12-.47-.32l-3.91-10.22c-.09-.24,0-.5.23-.62l11.91-6.28c.12-.07.27-.08.41-.03.13.05.24.15.29.28l5,12.5c.05.13.05.27-.01.4-.06.13-.17.22-.3.26l-13,4s-.1.02-.15.02Zm-3.28-10.48l3.58,9.37,12.03-3.7-4.59-11.48-11.02,5.82Z"></path><path d="m34.08,16c-.2,0-.39-.12-.46-.31L27.61.69c-.1-.26.02-.55.28-.65.25-.1.55.02.65.28l6,15c.1.26-.02.55-.28.65-.06.02-.12.04-.19.04Z"></path><path d="m16.08,32c-.28,0-.5-.22-.5-.5v-7c0-.28.22-.5.5-.5s.5.22.5.5v7c0,.28-.22.5-.5.5Z"></path><path d="m25.08,32c-.14,0-.28-.06-.38-.17l-6-7c-.18-.21-.16-.52.05-.7.21-.18.53-.16.7.05l6,7c.18.21.16.52-.05.7-.09.08-.21.12-.33.12Z"></path><path d="m7.08,32c-.12,0-.23-.04-.33-.12-.21-.18-.23-.5-.05-.7l6-7c.18-.21.5-.23.7-.05.21.18.23.5.05.7l-6,7c-.1.12-.24.17-.38.17Z"></path><path d="m19.08,25h-6c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h3c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-5.5-1h5v-.92c0-.61-.43-1.08-1-1.08h-3c-.59,0-1,.41-1,1v1Z"></path></svg>}
				text='Scopri tutte le nostre funzionalità per il tuo sito web'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4">
				<div className="grid g-3 place-center">
					<div>
						<img src={funzionalitaPc} className="mx-w-25 mb-3" alt="Funzionalità siti web" />
						
						<div className="grid">
							<ul className="list">
								<li>Siti Web Istituzionali</li>
								<li>Siti Vetrina</li>
								<li>Landing Page</li>
								<li>Blog</li>
								<li>E-commerce / Negozio Online</li>
							</ul>

							<ul className="list">
								<li>Forum e Community</li>
								<li>Portali Web</li>
								<li>Siti per Eventi e Conferenze</li>
								<li>Siti di Notizie e Media</li>
								<li>Siti per E-Learning e l'Educational</li>
							</ul>
						</div>
					</div>

					<div>
						<h2>Realizza la tua presenza online: Esplora le nostre soluzioni web personalizzate</h2>
						<p>Il nostro team di professionisti trasforma la tua visione in un sito Internet ben progettato e ottimizzato per i motori di ricerca. Offriamo soluzioni web su misura, come landing page, siti vetrina, progressive web app, portali, marketplace e applicazioni di back-end aziendali integrate con i sistemi gestionali. Utilizziamo i principali linguaggi come HTML, CSS e JavaScript per garantire una struttura solida, un design accattivante e funzionalità interattive. Abbiamo esperienza con noti CMS come WordPress e Joomla, ma creiamo anche CMS personalizzati per adattarsi alle tue esigenze specifiche. Guidiamo ogni fase del processo con attenzione ai dettagli per garantire risultati di successo. Affidati alla nostra esperienza per un sito web di successo. Contattaci oggi stesso per iniziare il tuo progetto!</p>
					</div>
				</div>
			</section>
			
			<section className="bt pbk-sm">
				<div className="container grid gtc-3-1 g-5 place-center">
					<div>
						<h2 className="mb-1">Esplora le nostre librerie</h2>
						<p>Personalizziamo la tua scelta! + di 200 template a disposizione.</p>
					</div>
					
					<BtnScegliTemplate />
				</div>
			</section>


			<section className="bg-light">
				<div className="container txt-center">

					<h2>{featuresSitiweb[0].type}</h2>
					<div className="grid gtc-4 mb-5">
						{featuresSitiweb[0].content.map((feature, idx) =>
							<FeatureBlock
								key={idx}
								icon={feature.icon}
								title={feature.title}
								text={feature.text}
							/>
						)}
					</div>


					<h2>{featuresSitiweb[1].type}</h2>
					<div className="grid gtc-4 mb-5">
						{featuresSitiweb[1].content.map((feature, idx) =>
							<FeatureBlock
								key={idx}
								icon={feature.icon}
								title={feature.title}
								text={feature.text}
							/>
						)}
					</div>
					

					<h2>{featuresSitiweb[2].type}</h2>
					<div className="grid gtc-4 mb-5">
						{featuresSitiweb[2].content.map((feature, idx) =>
							<FeatureBlock
								key={idx}
								icon={feature.icon}
								title={feature.title}
								text={feature.text}
							/>
						)}
					</div>

				</div>
			</section>

			{!isMobile &&
				<PriceTable />
			}

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}