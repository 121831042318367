import { useRef } from 'react';
import useIsOpen from '../hooks/useIsOpen';

export default function Popover({ text }) {
	const [isOpen, handleIsOpen] = useIsOpen(),
			popoverBody = useRef();

	return (
		<span className="popover-btn i-info"
			aria-controls={popoverBody}
			onClick={handleIsOpen}
		>
			<div className="popover"
				ref={popoverBody}
				data-visible={isOpen}
				onFocus={handleIsOpen}
			>
				<span>{text}</span>
			</div>
		</span>
	);
}