import { Link } from "react-router-dom";
import HelmetSeo from "../../components/HelmetSeo";
import image from '../../imgs/servizi/page-pc-crogs.webp';

export default function Servizi() {
	return (
		<>
			<HelmetSeo
				pageTitle="Servizi - Milanesi Editore"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>Servizi</h1>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/servizi/copywriting">Copywriting</Link></li>
						<li><Link to="/cosa-offriamo/servizi/report-e-review">Report e review</Link></li>
						<li><Link to="/cosa-offriamo/servizi/data-analytics">Data analytics</Link></li>
						<li><Link to="/cosa-offriamo/servizi/manutenzione-e-assistenza">Manutenzione e assistenza</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-25" alt="Servizi" />
			</section>
		</>
	);
} 