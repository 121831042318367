import { Link } from "react-router-dom";
import HelmetSeo from "../../components/HelmetSeo";
import image from '../../imgs/prodotti/gallery.webp';

export default function Prodotti() {
	return (
		<>
			<HelmetSeo
				pageTitle="Prodotti - Milanesi Editore"
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h1>Prodotti</h1>
					<ul className="list-check bold">
						<li><Link to="/cosa-offriamo/prodotti/foto-e-video">Foto e video</Link></li>
						<li><Link to="/cosa-offriamo/prodotti/grafica-e-design">Grafica e design</Link></li>
						<li><Link to="/cosa-offriamo/prodotti/hosting">Hosting</Link></li>
						<li><Link to="/cosa-offriamo/prodotti/banche-dati-dbu">Banche dati - dbu</Link></li>
					</ul>
				</div>

				<img src={image} className="mx-w-25" alt="Prodotti" />
			</section>
		</>
	);
} 