import LiSubmenu from './LiSubmenu';
import PcprofileNav from './PcprofileNav';
import SitiwebNav from './SitiwebNav';
import ProdottiNav from './ProdottiNav';
import ServiziNav from './ServiziNav';
import AdvertisingNav from './AdvertisingNav';


export default function SubmenuDesktop({ isMobile, handleIsNavSmOpen }) {
	return (
		<LiSubmenu label='Cosa offriamo' customLiClass='megamenu' customUlClass={isMobile ? 'gtc-4' : 'gtc-5'}>
		
			<PcprofileNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<SitiwebNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<ProdottiNav handleIsNavSmOpen={handleIsNavSmOpen} />

			<ServiziNav handleIsNavSmOpen={handleIsNavSmOpen} />
			
			<AdvertisingNav handleIsNavSmOpen={handleIsNavSmOpen} />

		</LiSubmenu>
	);
}