export const profileFaqs = [
   {
      question: 'Posso modificare i miei dati',
      answer: 'Sì, poi modificare i tuoi dati in qualunque momento. Entro pochi giorni dalla registrazione riceverai via mail il codice di accesso all’area persona PcProfile, dalla quale puoi gestire tutte le informazioni pubblicate sul tuo profilo.'
   },
   {
      question: 'Posso cancella tutti i dati inseriti e pubblicati',
      answer: 'Per il PcProfile Free è possibile cancellare il tuo profilo in qualunque momento direttamente dall’area di gestione. Nell’eventualità il profilo scelto fosse lo Smart, Fast o superiore, la disetta dovrà avvenire entro i termini previsti dal contratto che è stato sottoscritto.'
   },
   {
      question: 'Le modifiche che ho fatto verranno pubblicati anche sui volumi cartacei',
      answer: 'I dati inseriti sul tuo PcProfile riguarderanno esclusivamente il portale PagineComunali.'
   },
   {
      question: 'Posso non inserire la Partita IVA',
      answer: 'La Partita IVA è un dato obbligatorio, atto a garantire la veridicità delle informazioni inserite sul nostro portale.'
   },
   {
      question: 'Quali dati sono visibili nel Mio PcProfile',
      answer: 'In base al profilo scelto hai innumerevoli dati pubblicati, ti suggeriamo di prenderne visione dalla sezione prezzi.'
   },
   {
      question: 'Posso inserire i giorni di chiusura',
      answer: 'Si, puoi inserire i giorni della settimana, del mese o dell’anno in cui la tua attività rimarrà chiusa, o modificarli in qualunque momento.'
   },
   {
      question: 'Posso registrare più di un profilo',
      answer: 'Potrai registrare la tua attività una solo volta, il controllo viene effettuato incrociando l’univocità della tua Partita IVA con i dati inseriti.'
   },
   {
      question: 'Le News del giorno deve essere inserita il giorno stesso',
      answer: 'La pianificazione delle News, come per le Offerte e degli Eventi è pianificabile, al momento della pubblicazione ti verrà richiesta la data di pubblicazione e i giorni di permanenza online, dovrai solo occuparti dei contenuti, alla pubblicazione e alla rimozione ci penserà il tuo PcProfile Fast.'
   },
   {
      question: 'Per i profili a pagamento, ci sono spese aggiuntive',
      answer: 'Il prezzo riportato in corrispondenza del profilo scelto comprende tutti i servizi.'
   },
   {
      question: 'Per quanto rimarrà online il mio profilo',
      answer: 'Il profilo che hai attivato non ha limiti di tempo. La scelta di cancellare i tuoi dati o disdire un contratto sarà una tua esclusiva prerogativa. Milanesi Editore si riserva il diritto di oscurare un profilo nell’eventualità che: le informazioni inserite risultino errate, appartengano ad un operatore diverso da quello dichiarato, presentino contenuti volgari o non idonei alla visualizzazione pubblica.'
   },
   {
      question: 'Che tipo di immagini posso inserire nel mio profilo',
      answer: 'Le immagini che potrai inserire non dovranno essere di proprietà altrui. Le immagini per essere validate dalla nostra redazione non dovranno mostrare volti, marchi non di vostra proprietà, contenuti volgari o inappropriati. Potrai caricare immagini jpeg, png o svg con una dimensione minima di 800 X 800 pixel.'
   }
]