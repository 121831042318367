import HelmetSeo from "../../../components/HelmetSeo";
import PageTitle from '../../../components/PageTitle';
import Cta from '../../../components/Cta';
import titleBg from '../../../imgs/bg/red-wave.webp';
import socials from '../../../imgs/advertising/socials.webp';
import awarness from '../../../imgs/advertising/awarness.png';
import reach from '../../../imgs/profile/bersaglio.png';
import download from '../../../imgs/advertising/download.png';
import engagment from '../../../imgs/advertising/engagment.png';
import traffico from '../../../imgs/advertising/traffico.png';
import lead from '../../../imgs/advertising/lead.png';

export default function SocialAds() {
	return (
		<>
         <HelmetSeo
            pageTitle="Social Ads - Milanesi Editore"
            description="I nostri esperti Social possono aiutarti a sfruttare al massimo il potenziale dei social ads, offrendo una serie di servizi e competenze"
            keys="ads, socail, sponsorizzate, pubblicità online"
         />
			
			<PageTitle
				title='Social Ads'
            text="Il tuo Successo, la nostra missione"
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.01 32"><path d="m10.19,32c-5.62,0-10.19-4.57-10.19-10.19s4.57-10.19,10.19-10.19c.68,0,1.66.17,2.31.3L25.69.36c.27-.24.61-.36.97-.36h3.88c.81,0,1.47.66,1.47,1.47v4.53c0,.12,0,.22,0,.29,0,.2.02.43-.19.61-.2.18-.42.14-.54.12-.06,0-.15-.02-.28-.02h-3v4c-.02.74-.22.95-.89.99-.01,0-.1,0-.11,0h-3v4c0,.19-.11.36-.28.45l-4.52,2.26c.48.96.8,2.45.8,3.79,0,4.67-3.67,9.5-9.81,9.5Zm0-19.38c-5.07,0-9.19,4.12-9.19,9.19s4.12,9.19,9.19,9.19c5.52,0,8.81-4.32,8.81-8.5,0-1.59-.47-3.1-.91-3.71-.09-.12-.12-.27-.08-.41.03-.14.13-.26.26-.33l4.72-2.36v-4.19c0-.28.22-.5.5-.5h3.5v-4.5c0-.28.22-.5.5-.5h3.5V1.47c0-.26-.21-.47-.47-.47h-3.88c-.11,0-.22.04-.31.12l-13.37,11.72c-.12.1-.28.15-.43.11-1-.21-1.85-.33-2.35-.33Zm21.3-6.1h0,0ZM8.5,27c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5,3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5Zm0-6c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5,2.5-1.12,2.5-2.5-1.12-2.5-2.5-2.5Zm6.18-6.3c-.14,0-.28-.06-.38-.17-.18-.21-.16-.52.05-.71L25.67,3.9l.66.75-11.32,9.92c-.09.08-.21.12-.33.12Z"/></svg>}
				imgBottom={titleBg}
			/>

         <section className="container pt-sm-4 grid g-3 place-center">
				<div>
               <h2>Come possiamo aiutarvi</h2>
               <p>I nostri esperti possono aiutarti a sfruttare al massimo il potenziale dei social ads, offrendo una serie di servizi e competenze, tra cui:</p>
               <ul className="list">
                  <li><b>Identificazione dei canali social</b> più rilevanti per il tuo settore e il tuo target di riferimento, come Facebook, Instagram, LinkedIn, YouTube, Pinterest, Spotify e altri</li>
                  <li><b>Definizione degli obiettivi</b> chiari per la tua campagna sui social media, come aumentare la brand awareness, raggiungere un pubblico specifico, generare lead qualificati o incrementare l'engagement del tuo pubblico</li>
                  <li><b>Ideazione e creazione di annunci</b> coinvolgenti e persuasivi, in grado di catturare l'attenzione del tuo pubblico e trasmettere il messaggio giusto</li>
                  <li><b>Ottimizzazione delle campagne</b> per massimizzare il rendimento del tuo investimento pubblicitario e ottenere risultati tangibili</li>
                  <li><b>Monitoraggio costante delle performance</b> delle campagne, analisi dei dati e reportistica dettagliata per misurare l'efficacia delle tue strategie di social advertising</li>
               </ul>
				</div>

				<img src={socials} className="mx-w-25" alt="Piattaforme social" />
			</section>

         <hr />

			<section className="container txt-center">
            <h2>Obbiettivi di campagna</h2>
            <p>I nostri servizi di Social Ads ti consentono di perseguire una serie di obiettivi di marketing, tra cui:</p>

				<div className="box-line-grid">
               <div>
				      <img src={awarness} className="icon-h-10" alt="Brand Awareness" />
                  <h3>Brand Awareness</h3>
                  <p>Aumenta la notorietà del tuo brand o prodotto mostrando annunci mirati a persone interessate nel tuo settore. Puoi raggiungere un vasto pubblico e far conoscere la tua marca in modo efficace</p>
               </div>

               <div>
				      <img src={reach} className="icon-h-10" alt="Massimizza la visibilità" />
                  <h3>Reach</h3>
                  <p>Massimizza la visibilità della tua campagna mostrando gli annunci al più alto numero di persone possibile all'interno della tua audience target. Puoi espandere la tua portata e raggiungere un vasto pubblico con un budget ottimizzato</p>
               </div>

               <div>
				      <img src={download} className="icon-h-10" alt="Download di App" />
                  <h3>Download di App</h3>
                  <p>Incentiva il download e l'installazione della tua app grazie agli annunci appositamente progettati per condurre gli utenti direttamente a App Store o Google Play Store. Puoi aumentare il numero di installazioni e favorire l'utilizzo della tua app</p>
               </div>
                  
               <div>
                  <img src={engagment} className="icon-h-10" alt="Crescere Engagement" />
                  <h3>Crescere Engagement</h3>
                  <p>Coinvolgi il tuo pubblico e stimola interazioni con il tuo brand attraverso contenuti attraenti e coinvolgenti. Puoi generare reazioni positive, commenti, condivisioni e aumentare l'interesse e la fiducia nei confronti del tuo brand</p>
               </div>
                  
               <div>
				      <img src={traffico} className="icon-h-10"alt="Traffico al Sito Web" />
                  <h3>Traffico al Sito Web</h3>
                  <p>Incrementa il numero di visite al tuo sito web, app o chat Messenger. Attraverso annunci mirati e strategie di targeting accurato, puoi indirizzare il traffico di qualità verso le tue pagine web, aumentando le opportunità di conversione e di interazione con il tuo brand</p> 
               </div>

               <div>
                  <img src={lead} className="icon-h-10" alt="Lead Generation" />
                  <h3>Lead Generation</h3>
                  <p>Genera lead qualificati per il tuo business attraverso campagne di social advertising. Puoi creare annunci che invitano gli utenti interessati a scoprire di più sul tuo prodotto o servizio, offrendo loro la possibilità di iscriversi o richiedere informazioni aggiuntive</p>
               </div>
				</div>
			</section>

         <hr />

         <Cta
            title="Se hai bisogno di assistenza, desideri ricevere informazioni aggiuntive o vuoi richiedere un preventivo, non esitare a contattarci."
            urlBtn="/contatti"
            labelBtn="Contattaci"
            classSection="pbk-sm"
            classBtn="btn-feature-border-red"
         />
		</>
	);
}