export const manutenzioneFaqs = [
   {
      question: 'Aggiornamenti del sistema gestionale del sito',
      answer: 'Manteniamo sempre aggiornato il sistema gestionale del vostro sito web. Verifichiamo regolarmente se sono disponibili nuove versioni del CMS (Content Management System) o della piattaforma che utilizziamo e le aggiorniamo per migliorare la sicurezza e la stabilità del vostro sito.'
   },
   {
      question: 'Aggiornamenti dei plugin o delle estensioni',
		answer: 'Aggiorniamo i plugin o le estensioni del vostro sito web. Ci assicuriamo di utilizzare le ultime versioni dei plugin o delle estensioni di terze parti che abbiamo installato. Gli aggiornamenti spesso correggono bug e migliorano le prestazioni, quindi li applichiamo prontamente.'
   },
   {
      question: 'Aggiornamenti di sicurezza',
		answer: 'Prestiamo molta attenzione alla sicurezza del vostro sito web. Manteniamo aggiornati i componenti di sicurezza come firewall, antivirus e software anti-malware. Cambiamo regolarmente le password di accesso e utilizziamo misure di protezione aggiuntive come l\'autenticazione a due fattori, se disponibile.'
   },
   {
      question: 'Backup regolari',
		answer: 'Eseguiamo regolari backup dei dati del vostro sito web. Effettuiamo copie di backup dei file, del database e dei contenuti del sito e le conserviamo in luoghi sicuri. Periodicamente verifichiamo che i backup siano funzionanti e testiamo la procedura di ripristino.'
   },
   {
      question: 'Monitoraggio delle prestazioni',
		answer: 'Monitoriamo costantemente le prestazioni. Utilizziamo strumenti di monitoraggio delle prestazioni per controllare la velocità di caricamento del sito e l\'efficienza delle risorse. Se riscontriamo problemi di prestazioni, interveniamo per ottimizzarle.'
   },
   {
      question: 'Correzione dei bug e risoluzione dei problemi',
		answer: 'Risolviamo tempestivamente i bug e i problemi che possono verificarsi. Monitoriamo costantemente il vostro sito per individuare eventuali errori come link rotti, pagine non funzionanti o errori di formattazione e li correggiamo prontamente per offrire una buona esperienza agli utenti.'
   },
   {
      question: 'Aggiornamenti dei contenuti',
		answer: 'Se richiesto manteniamo il vostro sito web aggiornato con nuovi contenuti. Aggiungiamo regolarmente articoli, blog, informazioni sui vostri prodotti o servizi e altre informazioni rilevanti per il nostro pubblico. Questo aiuta a mantenere l\'interesse degli utenti e a migliorare la visibilità del sito web.'
   },
   {
      question: 'Ottimizzazione per i dispositivi mobili',
		answer: 'Ci assicuriamo che il sito web sia ottimizzato per i dispositivi mobili. Verifichiamo che il layout e il design si adattino correttamente ai diversi schermi e che il contenuto sia facilmente accessibile anche su smartphone e tablet.'
   },
   {
      question: 'Test di funzionalità e compatibilità',
		answer: 'Effettuiamo test di funzionalità e compatibilità regolari. Verifichiamo che tutte le funzionalità siano operative e che il sito sia compatibile con i diversi browser e sistemi operativi. Risolviamo eventuali problemi o errori che potrebbero influire sull\'usabilità.'
   },
   {
      question: 'Monitoraggio della sicurezza',
		answer: 'Monitoriamo costantemente la sicurezza del sito web. Utilizziamo strumenti di monitoraggio per individuare attività sospette o tentativi di accesso non autorizzati. In caso di problemi, prendiamo prontamente le misure necessarie per proteggere il sito e i dati degli utenti, ad esempio applicando patch di sicurezza, utilizzando certificati SSL - TLS e adottando misure di crittografia quando necessario.'
   }
]