import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';

import topBg from '../../imgs/prodotti/design.webp';
import loghi from '../../imgs/prodotti/loghi.webp';
import infografica from '../../imgs/servizi/infografica.webp';


export default function GraficaDesign() {
	const isMobile = useWindowWidth() < 810;
   return (
      <>
         <HelmetSeo
            pageTitle="Grafica e Design - Milanesi Editore"
            description="Siamo esperti nella creazione di grafiche che catturano l'attenzione e trasmettono il messaggio desiderato"
            keys="grafica, design, grafica creativa, identità aziendali, loghi, social media, info-grafici informativi"
         />
			
         <PageTitle
            title='Grafica e Design'
            icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 32" fill="#fff"><path d="m23.34,32h-3.94c-.95-.04-1.55-.59-1.55-1.4,0-.49.28-.8.52-1.08.33-.37.67-.75.67-1.73,0-1.77-1.6-2.91-4.08-2.91s-4.08,1.14-4.08,2.91c0,.98.34,1.37.67,1.73.25.28.52.59.52,1.08,0,.81-.6,1.36-1.53,1.4h-6.13c-2.3,0-4.4-2.03-4.4-4.27v-4.1c.04-.96.57-1.55,1.39-1.55.48,0,.83.29,1.17.58.46.38.98.81,1.97.81,1.64,0,3.47-1.83,3.47-4.46s-1.8-4-3.5-4c-1,0-1.51.39-1.96.74-.34.26-.68.53-1.15.53-.82,0-1.35-.59-1.39-1.53v-3.73c0-2.13,2.06-4,4.4-4h6.1c.56-.02.56-.12.56-.28,0-.1-.08-.2-.27-.41-.37-.41-.92-1.03-.92-2.4,0-2.34,2.06-3.91,5.13-3.91,3.48,0,5.04,1.96,5.04,3.91,0,1.36-.55,1.99-.92,2.4-.19.22-.27.31-.27.41,0,.16,0,.26.53.28.01,0,.03,0,.04,0h3.92c2.12,0,3.66,1.76,3.66,4.19v3.58s0,.02,0,.02c.02.1.33.57.8.57.12,0,.34-.17.56-.35.51-.41,1.29-1.02,2.63-1.02,1.99,0,4,1.55,4,5,0,3.2-2.21,4.88-4.4,4.88-1.36,0-1.98-.55-2.39-.92-.22-.19-.31-.27-.41-.27-.47,0-.78.47-.8.59v4.65c0,2.4-1.5,4.08-3.66,4.08Zm-8.39-8.11c3.51,0,5.08,1.96,5.08,3.91,0,1.36-.55,1.99-.92,2.4-.19.22-.27.32-.27.41,0,.11,0,.37.57.39h3.92c1.83,0,2.66-1.54,2.66-3.08v-4.69s0-.07.01-.1c.09-.61.83-1.44,1.79-1.44.49,0,.8.28,1.08.53.37.33.75.67,1.73.67,1.64,0,3.4-1.21,3.4-3.88,0-2.43-1.18-4-3-4-1,0-1.54.43-2.01.8-.37.29-.71.56-1.18.56-.96,0-1.7-.83-1.79-1.44,0-.03-.01-.07-.01-.1v-3.63c0-1.59-.91-3.19-2.66-3.19h-3.95s-.05,0-.07,0c-1.22-.06-1.47-.73-1.47-1.28,0-.49.28-.8.52-1.08.33-.37.67-.75.67-1.73,0-1.77-1.59-2.91-4.04-2.91s-4.13,1.17-4.13,2.91c0,.98.34,1.37.67,1.73.25.28.52.59.52,1.08,0,.56-.26,1.23-1.49,1.28h0s-.03,0-.05,0h-6.12c-1.61,0-3.4,1.23-3.4,3v3.71c.02.55.28.55.39.55.13,0,.32-.15.54-.32.49-.38,1.23-.94,2.57-.94,2.18,0,4.5,1.75,4.5,5s-2.31,5.46-4.47,5.46c-1.35,0-2.1-.62-2.6-1.04-.22-.18-.42-.35-.54-.35s-.37,0-.39.57v4.08c0,1.65,1.69,3.27,3.4,3.27h6.11c.55-.02.55-.28.55-.4,0-.1-.08-.2-.27-.41-.37-.41-.92-1.03-.92-2.4,0-2.34,2.04-3.91,5.08-3.91Z"/></svg>}
				imgBg={topBg}
         />

         <section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Servizi di grafica creativa</h2>
					<p>Siamo esperti nella creazione di grafiche che catturano l'attenzione e trasmettono il messaggio desiderato. I nostri designer sono capaci di sviluppare <b>loghi</b> distintivi, <b>immagini</b> accattivanti per il web e <b>materiali cartacei</b> di alta qualità. Che tu stia cercando un nuovo logo aziendale o un sito web con un design coinvolgente, siamo qui per trasformare la tua visione in realtà.</p>
               
					<p className="mb-2">Offriamo una vasta gamma di servizi di grafica, tra cui:</p>
               <ul className="list bold">
                  <li>Creazione di loghi e identità aziendali</li>
                  <li>Grafiche per il web, inclusi elementi visivi per siti web e social media</li>
                  <li>Materiali cartacei come brochure, volantini e manifesti</li>
                  <li>Impaginazione di libri, riviste e cataloghi</li>
                  <li>Creazione di info-grafici per rappresentare dati complessi</li>
                  <li>Grafica per presentazioni aziendali</li>
               </ul>
				</div>
				
				<img src={loghi} className="mx-w-25" alt="Servizi di grafica creativa" />
            
				{!isMobile &&
				   <img src={infografica} className="mx-w-25" alt="Statistiche aziendali e dati di mercato" />
            }

				<div>
					<h2>Info-grafici informativi</h2>
					<p><b>Gli info-grafici sono uno strumento potente per comunicare dati complessi in modo chiaro e accessibile</b>. I nostri designer sono esperti nella creazione di info-grafici efficaci che semplificano concetti intricati, rendendo le informazioni facilmente comprensibili per il tuo pubblico. Che si tratti di <b>statistiche aziendali o dati di mercat</b>o, trasformiamo i numeri in visualizzazioni chiare e coinvolgenti.</p>
				</div>

            {isMobile &&
				   <img src={infografica} className="mx-w-25" alt="Statistiche aziendali e dati di mercato" />
            }
			</section>

         <hr />

			<section className="container">
            <h2>Poligrafia professionale</h2>
				<p>Grazie alla nostra <b>esperienza nella poligrafia</b>, gestiamo progetti complessi di impaginazione, dalla <b>realizzazione di riviste e cataloghi</b> all'impaginazione di <b>libri e brochure</b>. I nostri professionisti della poligrafia prestano grande attenzione ai dettagli, garantendo che ogni progetto presenti un layout impeccabile, formattazione professionale e design di alta qualità.</p>
            <p>Siamo pronti a soddisfare le tue esigenze di grafica in modo professionale e creativo. Che tu abbia bisogno di un nuovo logo aziendale o della creazione di materiali promozionali di alta qualità, <b>siamo qui per aiutarti a raggiungere i tuoi obiettivi visivi</b>.</p>
			</section>

         <hr />

         <Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
            classBtn="bg-red"
         />
      </>
   );
}