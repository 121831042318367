import { Link } from 'react-router-dom';
import LiSubmenu from './LiSubmenu';

export default function ProdottiNav({ handleIsNavSmOpen }) {
	return (
		<LiSubmenu label='Prodotti'>
			<li>
				<Link to="cosa-offriamo/prodotti/foto-e-video" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m30.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h29c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h29c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"/><path d="m20.5,12.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm0-3c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z"/><path d="m19.03,20.87c-.13,0-.26-.05-.35-.15l-7.52-7.52c-.26-.26-.71-.26-.97,0l-5.12,5.12c-.2.2-.51.2-.71,0s-.2-.51,0-.71l5.12-5.12c.64-.64,1.75-.64,2.39,0l7.18,7.18,3.5-3.33c.31-.31.73-.49,1.18-.49h0c.45,0,.87.18,1.19.5l2.82,3.07c.19.2.17.52-.03.71-.2.19-.52.17-.71-.03l-2.8-3.05c-.12-.11-.29-.19-.47-.19h0c-.18,0-.36.07-.49.2l-3.87,3.67c-.1.09-.22.14-.34.14Z"/><path d="m27.5,25H4.5c-.28,0-.5-.22-.5-.5V4.5c0-.28.22-.5.5-.5h23c.28,0,.5.22.5.5v20c0,.28-.22.5-.5.5Zm-22.5-1h22V5H5v19Z"/></svg>
					Foto e video
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/prodotti/grafica-e-design" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 32"><path d="m23.34,32h-3.94c-.95-.04-1.55-.59-1.55-1.4,0-.49.28-.8.52-1.08.33-.37.67-.75.67-1.73,0-1.77-1.6-2.91-4.08-2.91s-4.08,1.14-4.08,2.91c0,.98.34,1.37.67,1.73.25.28.52.59.52,1.08,0,.81-.6,1.36-1.53,1.4h-6.13c-2.3,0-4.4-2.03-4.4-4.27v-4.1c.04-.96.57-1.55,1.39-1.55.48,0,.83.29,1.17.58.46.38.98.81,1.97.81,1.64,0,3.47-1.83,3.47-4.46s-1.8-4-3.5-4c-1,0-1.51.39-1.96.74-.34.26-.68.53-1.15.53-.82,0-1.35-.59-1.39-1.53v-3.73c0-2.13,2.06-4,4.4-4h6.1c.56-.02.56-.12.56-.28,0-.1-.08-.2-.27-.41-.37-.41-.92-1.03-.92-2.4,0-2.34,2.06-3.91,5.13-3.91,3.48,0,5.04,1.96,5.04,3.91,0,1.36-.55,1.99-.92,2.4-.19.22-.27.31-.27.41,0,.16,0,.26.53.28.01,0,.03,0,.04,0h3.92c2.12,0,3.66,1.76,3.66,4.19v3.58s0,.02,0,.02c.02.1.33.57.8.57.12,0,.34-.17.56-.35.51-.41,1.29-1.02,2.63-1.02,1.99,0,4,1.55,4,5,0,3.2-2.21,4.88-4.4,4.88-1.36,0-1.98-.55-2.39-.92-.22-.19-.31-.27-.41-.27-.47,0-.78.47-.8.59v4.65c0,2.4-1.5,4.08-3.66,4.08Zm-8.39-8.11c3.51,0,5.08,1.96,5.08,3.91,0,1.36-.55,1.99-.92,2.4-.19.22-.27.32-.27.41,0,.11,0,.37.57.39h3.92c1.83,0,2.66-1.54,2.66-3.08v-4.69s0-.07.01-.1c.09-.61.83-1.44,1.79-1.44.49,0,.8.28,1.08.53.37.33.75.67,1.73.67,1.64,0,3.4-1.21,3.4-3.88,0-2.43-1.18-4-3-4-1,0-1.54.43-2.01.8-.37.29-.71.56-1.18.56-.96,0-1.7-.83-1.79-1.44,0-.03-.01-.07-.01-.1v-3.63c0-1.59-.91-3.19-2.66-3.19h-3.95s-.05,0-.07,0c-1.22-.06-1.47-.73-1.47-1.28,0-.49.28-.8.52-1.08.33-.37.67-.75.67-1.73,0-1.77-1.59-2.91-4.04-2.91s-4.13,1.17-4.13,2.91c0,.98.34,1.37.67,1.73.25.28.52.59.52,1.08,0,.56-.26,1.23-1.49,1.28h0s-.03,0-.05,0h-6.12c-1.61,0-3.4,1.23-3.4,3v3.71c.02.55.28.55.39.55.13,0,.32-.15.54-.32.49-.38,1.23-.94,2.57-.94,2.18,0,4.5,1.75,4.5,5s-2.31,5.46-4.47,5.46c-1.35,0-2.1-.62-2.6-1.04-.22-.18-.42-.35-.54-.35s-.37,0-.39.57v4.08c0,1.65,1.69,3.27,3.4,3.27h6.11c.55-.02.55-.28.55-.4,0-.1-.08-.2-.27-.41-.37-.41-.92-1.03-.92-2.4,0-2.34,2.04-3.91,5.08-3.91Z"/></svg>
					Grafica e design
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/prodotti/hosting" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m33,32H9c-4.79,0-9-4.44-9-9.5,0-3.88,3.21-9.5,9-9.5h1.5c.28,0,.5.22.5.5s-.22.5-.5.5h-1.5c-5.33,0-8,5.45-8,8.5,0,4.53,3.74,8.5,8,8.5h24c4.31,0,8-4.67,8-8.5,0-5.21-4.44-8.54-7.5-8.54-.28,0-.5-.22-.5-.5s.22-.5.5-.5c3.93,0,8.5,4.17,8.5,9.54,0,4.28-4.15,9.5-9,9.5Z"/><path d="m33.5,16c-.28,0-.5-.22-.5-.5v-1.5c0-6.26-3.91-13-12.5-13S7.63,7.55,7.5,13.72c0,.28-.23.51-.51.49-.28,0-.5-.23-.49-.51C6.66,5.89,12.68,0,20.5,0c8.86,0,13.5,7.04,13.5,14v1.5c0,.28-.22.5-.5.5Z"/></svg>
					Hosting
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/prodotti/banche-dati-dbu" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.67 31.89"><path d="m15.83,18.35c-1.05,0-2.1-.23-2.92-.69L1.37,11.14c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97L12.91.69c1.64-.92,4.21-.93,5.84,0l11.54,6.52c.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52h0c-.82.46-1.87.69-2.92.69Zm0-17.28c-.87,0-1.75.18-2.4.55L1.89,8.14c-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04L18.23,1.62c-.65-.37-1.52-.55-2.4-.55Zm2.66,16.12h0,0Z"/><path d="m15.83,25.19c-1.05,0-2.1-.23-2.92-.69L1.37,17.98c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97c.26-.15.58-.05.73.2s.05.58-.2.73c-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04c-.26-.15-.35-.47-.2-.73.15-.26.47-.35.73-.2.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52c-.82.46-1.87.69-2.92.69Z"/><path d="m15.83,31.89c-1.05,0-2.1-.23-2.92-.69L1.37,24.68c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97c.26-.15.58-.05.73.2.14.26.05.58-.2.73-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04c-.26-.15-.35-.47-.2-.73.15-.26.47-.35.73-.2.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52c-.82.46-1.87.69-2.92.69Z"/></svg>
					Banche dati - dbu
				</Link>
			</li>
		</LiSubmenu>
	);
}