import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/grey-wave.webp';
import copywriting from '../../imgs/servizi/copywriting.webp';


export default function Copywriting() {
	return (
		<>
		   <HelmetSeo
            pageTitle="Copywriting - Milanesi Editore"
            description="Usiamo le parole giuste per convincere il tuo futuro cliente che sta facendo la scelta giusta creando una scrittura persuasiva"
            keys="copywriting, copy, scrivere, persuasione, copywriter, marketing moderno, scrittura persuasiva"
         />
			
			<PageTitle
				title='Copywriting'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.89 31.95"><path d="m36.95,29c-.99,0-2-.69-2-2V.5c0-.28.22-.5.5-.5s.5.22.5.5v26.5c0,.97.9,1,1,1,.37,0,1-.13,1-1V.5c0-.28.22-.5.5-.5s.5.22.5.5v26.5c0,1.2-.8,2-2,2Z"/><path d="m14.45,21H4.45c-.28,0-.5-.22-.5-.5v-9c0-.28.22-.5.5-.5h10c.28,0,.5.22.5.5v9c0,.28-.22.5-.5.5Zm-9.5-1h9v-8H4.95v8Z"/><path d="m28.45,8H4.45c-.28,0-.5-.22-.5-.5V3.5c0-.28.22-.5.5-.5h24c.28,0,.5.22.5.5v4c0,.28-.22.5-.5.5Zm-23.5-1h23v-3H4.95v3Z"/><path d="m28.45,12h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,15h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,18h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,21h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,24h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.45,24H4.45c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,27h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.45,27H4.45c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m36.95,31.95c-2.73,0-4.95-2.22-4.95-4.95V.5c0-.25.2-.45.45-.45s.45.2.45.45v26.5c0,2.23,1.82,4.05,4.05,4.05s4.05-1.82,4.05-4.05V.5c0-.25.2-.45.45-.45s.45.2.45.45v26.5c0,2.73-2.22,4.95-4.95,4.95Z"/><path d="m36.95,31.95H4.95c-2.73,0-4.95-2.22-4.95-4.95V.5C0,.25.2.05.45.05h32c.25,0,.45.2.45.45v26.5c0,2.23,1.82,4.05,4.05,4.05.25,0,.45.2.45.45s-.2.45-.45.45ZM.89.95v26.05c0,2.23,1.82,4.05,4.05,4.05h29.17c-1.28-.9-2.11-2.38-2.11-4.05V.95H.89Z"/></svg>}
				text='Parole che vendono: il potere del copywriting persuasivo'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 place-center">
				<div>
					<h2>Servizio di Copywriting</h2>
					<p>Quella del copywriter è una figura fondamentale del marketing moderno, ci sono delle precise regole che prendono il nome di persuasive copywriting, in italiano scrittura persuasiva. Partiamo da un'analisi delle caratteristiche della tua azienda o del prodotto, anche rispetto ai tuoi diretti concorrenti, ed usiamo le parole giuste per convincere il tuo futuro cliente che sta facendo la scelta giusta.</p>
				</div>

				<img src={copywriting} className="mx-w-25" alt="Persuasive copywriting" />
			</section>
			
			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}