function InputField({ label, name, type, value, onChange, errorMsg, icon=false, required=false, textarea=false }) {
	return (
      <label htmlFor={name}>
         <span>{label} {required ? '*' : ''}</span>

         {textarea ?
            <textarea
               name={name}
               id={name}
               rows="6"
               value={value}
               onChange={onChange}
            />
         :
            <input
               type={type}
               name={name}
               id={name}
               required={required}
               value={value}
               onChange={onChange}
            />
         }
         {icon ?? icon}

         {errorMsg &&
            <p className="txt-danger mt--1 mb-2">{errorMsg}</p>
         }
      </label>
	);
}

export default InputField;