import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/yellow-wave.webp';
import database from '../../imgs/advertising/database.webp';
import piantina from '../../imgs/profile/piantina.webp';


export default function Dbu() {
	const isMobile = useWindowWidth() < 810;
   return (
      <>
         <HelmetSeo
            pageTitle="Banca dati e DBU - Milanesi Editore"
            description="La nostra web agency ha un solido background nell'editoria di elenchi telefonici, realizzando e distribuendo gli elenchi telefonici Pagine Comunali per oltre 30 anni."
            keys="banca dati, elenco telefonico, banca dati telefonica"
         />
			
         <PageTitle
            title='Banca dati e DBU'
            icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.67 31.89"><path d="m15.83,18.35c-1.05,0-2.1-.23-2.92-.69L1.37,11.14c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97L12.91.69c1.64-.92,4.21-.93,5.84,0l11.54,6.52c.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52h0c-.82.46-1.87.69-2.92.69Zm0-17.28c-.87,0-1.75.18-2.4.55L1.89,8.14c-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04L18.23,1.62c-.65-.37-1.52-.55-2.4-.55Zm2.66,16.12h0,0Z"/><path d="m15.83,25.19c-1.05,0-2.1-.23-2.92-.69L1.37,17.98c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97c.26-.15.58-.05.73.2s.05.58-.2.73c-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04c-.26-.15-.35-.47-.2-.73.15-.26.47-.35.73-.2.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52c-.82.46-1.87.69-2.92.69Z"/><path d="m15.83,31.89c-1.05,0-2.1-.23-2.92-.69L1.37,24.68c-.88-.5-1.37-1.2-1.37-1.97s.49-1.47,1.37-1.97c.26-.15.58-.05.73.2.14.26.05.58-.2.73-.52.3-.83.67-.83,1.04s.3.74.83,1.04l11.54,6.52c1.3.73,3.5.73,4.79,0l11.54-6.52c.52-.3.83-.67.83-1.04s-.3-.74-.83-1.04c-.26-.15-.35-.47-.2-.73.15-.26.47-.35.73-.2.88.5,1.37,1.2,1.37,1.97s-.49,1.47-1.37,1.97l-11.54,6.52c-.82.46-1.87.69-2.92.69Z"/></svg>}
            text='Informazioni aziendali su misura per te'
				imgBottom={titleBg}
         />

         <section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Esperienza Editoriale al Tuo Servizio</h2>
					<p>La nostra web agency ha un solido background nell'editoria di elenchi telefonici, <b>realizzando e distribuendo gli elenchi telefonici Pagine Comunali per oltre 30 anni</b>. Abbiamo reso questa esperienza disponibile attraverso il nostro servizio di vendita di partizioni personalizzate della Banca Dati Telefonica (DBU).</p>
               <p>La DBU contiene una vasta gamma di informazioni aziendali, tra cui <b>nomi di aziende, indirizzi, numeri di telefono ed e-mail</b>. La caratteristica distintiva del nostro servizio è la possibilità di personalizzare completamente la tua selezione. Puoi scegliere le categorie merceologiche che interessano di più alla tua attività, dalla ristorazione agli alberghi, ai negozi di ferramenta e altro ancora.</p>
				</div>
				
				<img src={database} className="mx-w-25" alt="Banca Dati Telefonica" />

				{!isMobile &&
				   <img src={piantina} className="mx-w-25" alt="Marketing mirato" />
            }

				<div>
					<p><b>La selezione accurata delle categorie ti permette di concentrare le tue risorse di marketing in modo mirato</b>, evitando sprechi di tempo e denaro. Ad esempio, se stai pianificando una <b>campagna di email marketing</b>, puoi inviare messaggi personalizzati alle aziende della categoria specifica, massimizzando l'efficacia delle tue comunicazioni.</p>
               <p>Inoltre, ti offriamo la flessibilità di selezionare regioni specifiche o comuni che si allineano alle tue strategie commerciali. Che tu voglia focalizzarti su <b>un'area geografica particolare o su un settore di mercato specifico</b>, la nostra DBU personalizzata è uno strumento prezioso per il tuo successo.</p>
               <p><b>Siamo pronti ad ascoltare le tue esigenze</b> e a condividere la nostra esperienza editoriale e la nostra banca dati telefonica per aiutarti a raggiungere i tuoi obiettivi commerciali. Contattaci oggi stesso per iniziare la tua strategia di marketing su misura.</p>
				</div>

            {isMobile &&
				   <img src={piantina} className="mx-w-25" alt="Marketing mirato" />
            }
			</section>

         <hr />

         <Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
            classBtn="bg-red"
         />
      </>
   );
}