import { Link } from 'react-router-dom';
import LiSubmenu from './LiSubmenu';

export default function ServiziNav({ handleIsNavSmOpen }) {
	return (
		<LiSubmenu label='Servizi'>
			<li>
				<Link to="cosa-offriamo/servizi/copywriting" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.89 31.95"><path d="m36.95,29c-.99,0-2-.69-2-2V.5c0-.28.22-.5.5-.5s.5.22.5.5v26.5c0,.97.9,1,1,1,.37,0,1-.13,1-1V.5c0-.28.22-.5.5-.5s.5.22.5.5v26.5c0,1.2-.8,2-2,2Z"/><path d="m14.45,21H4.45c-.28,0-.5-.22-.5-.5v-9c0-.28.22-.5.5-.5h10c.28,0,.5.22.5.5v9c0,.28-.22.5-.5.5Zm-9.5-1h9v-8H4.95v8Z"/><path d="m28.45,8H4.45c-.28,0-.5-.22-.5-.5V3.5c0-.28.22-.5.5-.5h24c.28,0,.5.22.5.5v4c0,.28-.22.5-.5.5Zm-23.5-1h23v-3H4.95v3Z"/><path d="m28.45,12h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,15h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,18h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,21h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,24h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.45,24H4.45c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m28.45,27h-10c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.45,27H4.45c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m36.95,31.95c-2.73,0-4.95-2.22-4.95-4.95V.5c0-.25.2-.45.45-.45s.45.2.45.45v26.5c0,2.23,1.82,4.05,4.05,4.05s4.05-1.82,4.05-4.05V.5c0-.25.2-.45.45-.45s.45.2.45.45v26.5c0,2.73-2.22,4.95-4.95,4.95Z"/><path d="m36.95,31.95H4.95c-2.73,0-4.95-2.22-4.95-4.95V.5C0,.25.2.05.45.05h32c.25,0,.45.2.45.45v26.5c0,2.23,1.82,4.05,4.05,4.05.25,0,.45.2.45.45s-.2.45-.45.45ZM.89.95v26.05c0,2.23,1.82,4.05,4.05,4.05h29.17c-1.28-.9-2.11-2.38-2.11-4.05V.95H.89Z"/></svg>
					Copywriting
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/servizi/report-e-review" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m28.94,19.73l8.97-13.95c.34.13.7.21,1.09.21,1.65,0,3-1.35,3-3s-1.35-3-3-3-3,1.35-3,3c0,.91.42,1.72,1.06,2.27l-8.97,13.95c-.34-.13-.7-.21-1.09-.21-.91,0-1.71.41-2.27,1.06l-8.95-5.96c.13-.34.21-.71.21-1.09,0-1.65-1.35-3-3-3s-3,1.35-3,3c0,.91.42,1.72,1.06,2.27l-6.91,10.96c-.35-.15-.74-.23-1.15-.23-1.65,0-3,1.35-3,3s1.35,3,3,3,3-1.35,3-3c0-.89-.4-1.68-1.01-2.23l6.92-10.98c.34.13.7.21,1.09.21.91,0,1.71-.41,2.27-1.06l8.95,5.96c-.13.34-.21.71-.21,1.09,0,1.65,1.35,3,3,3s3-1.35,3-3c0-.91-.42-1.72-1.06-2.27ZM39,1c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2ZM3,31c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2ZM13,15c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm14,9c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/></svg>
					Report e review
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/servizi/data-analytics" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 32"><path d="m8.5,32H1.5c-.83,0-1.5-.67-1.5-1.5v-11c0-.83.67-1.5,1.5-1.5h7c.83,0,1.5.67,1.5,1.5v11c0,.83-.67,1.5-1.5,1.5ZM1.5,19c-.28,0-.5.22-.5.5v11c0,.28.22.5.5.5h7c.28,0,.5-.22.5-.5v-11c0-.28-.22-.5-.5-.5H1.5Z"/><path d="m21.5,32h-7c-.83,0-1.5-.67-1.5-1.5V1.5c0-.83.67-1.5,1.5-1.5h7c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM14.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h7c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5h-7Z"/><path d="m34.5,32h-7c-.83,0-1.5-.67-1.5-1.5V10.5c0-.83.67-1.5,1.5-1.5h7c.83,0,1.5.67,1.5,1.5v20c0,.83-.67,1.5-1.5,1.5Zm-7-22c-.28,0-.5.22-.5.5v20c0,.28.22.5.5.5h7c.28,0,.5-.22.5-.5V10.5c0-.28-.22-.5-.5-.5h-7Z"/><path d="m34.5,32H5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h29.5c.28,0,.5.22.5.5s-.22.5-.5.5Z"/></svg>
					Data analytics
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/servizi/manutenzione-e-assistenza" onClick={handleIsNavSmOpen}>
					<svg mlns="http://www.w3.org/2000/svg" viewBox="0 0 31.97 32.07"><path d="m6.37,32.07c-.87,0-1.74-.19-2.58-.58-.15-.07-.25-.21-.28-.37-.03-.16.02-.32.14-.44l3.48-3.5c.23-.23.24-.57.02-.79l-1.51-1.58c-.09-.09-.2-.13-.33-.13h0c-.16,0-.33.07-.46.2l-3.49,3.51c-.12.12-.28.17-.45.14-.16-.03-.3-.14-.37-.29-1-2.31-.5-4.96,1.28-6.75,1.7-1.71,4.35-2.32,6.55-1.54l11.54-11.54c-.79-2.24-.2-4.93,1.5-6.64,1.81-1.82,4.48-2.29,6.8-1.19.15.07.25.21.28.37.03.16-.02.32-.14.44l-3.5,3.5c-.22.23-.22.57,0,.79l1.5,1.5c.22.22.58.23.8,0l3.49-3.51c.12-.12.29-.17.45-.14.16.03.3.14.37.29,1,2.31.5,4.96-1.28,6.75-1.7,1.71-4.35,2.32-6.55,1.54l-11.54,11.54c.79,2.24.2,4.93-1.5,6.64-1.16,1.17-2.67,1.78-4.22,1.78Zm-1.47-1.22c1.77.56,3.63.1,4.99-1.26,1.48-1.49,1.97-3.95,1.16-5.86-.08-.19-.04-.4.11-.55l12-12c.14-.14.36-.19.55-.11,1.9.81,4.27.31,5.77-1.2,1.31-1.32,1.79-3.18,1.32-4.93l-2.93,2.94c-.29.3-.69.46-1.1.46h0c-.42,0-.82-.17-1.12-.47l-1.5-1.5c-.62-.62-.62-1.59,0-2.21l2.96-2.96c-1.77-.56-3.63-.1-4.98,1.26-1.48,1.49-1.97,3.95-1.16,5.86.08.19.04.4-.11.55l-12,12c-.14.14-.36.19-.55.11-1.89-.81-4.27-.31-5.77,1.2-1.31,1.32-1.79,3.18-1.32,4.93l2.93-2.94c.31-.32.74-.5,1.17-.5h0c.4,0,.77.15,1.05.44l1.51,1.58c.6.6.59,1.57-.03,2.2l-2.94,2.96Z"/><path d="m28.8,31.86c-.42,0-.82-.17-1.12-.47l-10.03-10c-.2-.2-.2-.51,0-.71.2-.2.51-.2.71,0l10.03,10c.22.22.61.22.83,0l1.43-1.49c.24-.24.24-.57,0-.8l-10-10c-.2-.2-.2-.51,0-.71s.51-.2.71,0l10,10c.62.62.62,1.59,0,2.21l-1.43,1.49c-.31.31-.7.47-1.13.47h0Z"/><path d="m12.5,13.04c-.13,0-.26-.05-.35-.15L4.15,4.89c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/><path d="m3.5,6.04c-.13,0-.26-.05-.35-.15L.15,2.89c-.2-.2-.2-.51,0-.71L2.15.18C2.34-.01,2.66-.01,2.85.18l3,3c.2.2.2.51,0,.71l-2,2c-.1.1-.23.15-.35.15ZM1.21,2.54l2.29,2.29,1.29-1.29L2.5,1.24l-1.29,1.29Z"/></svg>
					Manutenzione e assistenza
				</Link>
			</li>
		</LiSubmenu>
	);
}