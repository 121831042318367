import { manutenzioneFaqs } from '../../data/manutenzioneFaqs'
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Faq from '../../components/Faq';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/grey-wave.webp';
import robot from '../../imgs/servizi/robot-medico.webp';
import gdpr from '../../imgs/servizi/gdpr-ita-shield.webp';
import crogs from '../../imgs/servizi/page-pc-crogs.webp';


export default function ManutenzioneAssistenza() {
	return (
		<>			
		   <HelmetSeo
            pageTitle="Manutenzione e Assistenza - Milanesi Editore"
            description="Offriamo servizio di manutenzione senza costi aggiuntivi aggiornando browser, motori di ricerca, sistemi operativi per avere sempre l'ultimo modello di sito web in commercio."
            keys="aggiornamento sito, manutenzione sito, aggiornamento hosting, aggiornamento plugin, cms, plugin, backup, normativa, cookie, normativa cookie, policy"
         />

			<PageTitle
				title='Manutenzione e Assistenza'
				text='Assicuriamo la salute e la sicurezza del tuo sito web'
				icon={<svg mlns="http://www.w3.org/2000/svg" viewBox="0 0 31.97 32.07"><path d="m6.37,32.07c-.87,0-1.74-.19-2.58-.58-.15-.07-.25-.21-.28-.37-.03-.16.02-.32.14-.44l3.48-3.5c.23-.23.24-.57.02-.79l-1.51-1.58c-.09-.09-.2-.13-.33-.13h0c-.16,0-.33.07-.46.2l-3.49,3.51c-.12.12-.28.17-.45.14-.16-.03-.3-.14-.37-.29-1-2.31-.5-4.96,1.28-6.75,1.7-1.71,4.35-2.32,6.55-1.54l11.54-11.54c-.79-2.24-.2-4.93,1.5-6.64,1.81-1.82,4.48-2.29,6.8-1.19.15.07.25.21.28.37.03.16-.02.32-.14.44l-3.5,3.5c-.22.23-.22.57,0,.79l1.5,1.5c.22.22.58.23.8,0l3.49-3.51c.12-.12.29-.17.45-.14.16.03.3.14.37.29,1,2.31.5,4.96-1.28,6.75-1.7,1.71-4.35,2.32-6.55,1.54l-11.54,11.54c.79,2.24.2,4.93-1.5,6.64-1.16,1.17-2.67,1.78-4.22,1.78Zm-1.47-1.22c1.77.56,3.63.1,4.99-1.26,1.48-1.49,1.97-3.95,1.16-5.86-.08-.19-.04-.4.11-.55l12-12c.14-.14.36-.19.55-.11,1.9.81,4.27.31,5.77-1.2,1.31-1.32,1.79-3.18,1.32-4.93l-2.93,2.94c-.29.3-.69.46-1.1.46h0c-.42,0-.82-.17-1.12-.47l-1.5-1.5c-.62-.62-.62-1.59,0-2.21l2.96-2.96c-1.77-.56-3.63-.1-4.98,1.26-1.48,1.49-1.97,3.95-1.16,5.86.08.19.04.4-.11.55l-12,12c-.14.14-.36.19-.55.11-1.89-.81-4.27-.31-5.77,1.2-1.31,1.32-1.79,3.18-1.32,4.93l2.93-2.94c.31-.32.74-.5,1.17-.5h0c.4,0,.77.15,1.05.44l1.51,1.58c.6.6.59,1.57-.03,2.2l-2.94,2.96Z"/><path d="m28.8,31.86c-.42,0-.82-.17-1.12-.47l-10.03-10c-.2-.2-.2-.51,0-.71.2-.2.51-.2.71,0l10.03,10c.22.22.61.22.83,0l1.43-1.49c.24-.24.24-.57,0-.8l-10-10c-.2-.2-.2-.51,0-.71s.51-.2.71,0l10,10c.62.62.62,1.59,0,2.21l-1.43,1.49c-.31.31-.7.47-1.13.47h0Z"/><path d="m12.5,13.04c-.13,0-.26-.05-.35-.15L4.15,4.89c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/><path d="m3.5,6.04c-.13,0-.26-.05-.35-.15L.15,2.89c-.2-.2-.2-.51,0-.71L2.15.18C2.34-.01,2.66-.01,2.85.18l3,3c.2.2.2.51,0,.71l-2,2c-.1.1-.23.15-.35.15ZM1.21,2.54l2.29,2.29,1.29-1.29L2.5,1.24l-1.29,1.29Z"/></svg>}
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 place-center pb-0">
				<div>
					<h2>Manutenzione ordinaria illimitata</h2>
					<p>Offriamo servizio di manutenzione senza costi aggiuntivi aggiornando browser, motori di ricerca, sistemi operativi per avere sempre l'ultimo modello di sito web in commercio.</p>
					<ul className="list-check bold">
						<li>CMS</li>
						<li>PLUGIN ED ESTENSIONI</li>
						<li>SICUREZZA</li>
						<li>BACKUP</li>
						<li>PRESTAZIONI</li>
					</ul>
				</div>

				<img src={robot} className="mx-w-25" alt="Manutenzione ordinaria siti web" />
			</section>

			<section className="container grid gtc-3 g-3">
				<div>
					<h2>Aggiornamento dei siti web</h2>
					<p><b>È fondamentale aggiornare costantemente il proprio sito web per garantire sicurezza e funzionalità</b>. Aggiornare sempre sia il software che l'aspetto grafico, inclusi i plugin e le estensioni utilizzate. Il nostro servizio di manutenzione garantisce il tuo sito sempre al passo con i tempi, aggiornando i componenti software e ottimizzando l'aspetto grafico, per una navigazione veloce e fluida.</p>
				</div>
			
				<img src={crogs} alt="" />

				<div>
					<h2>Aggiornamento agli hosting</h2>
					<p>Per garantire efficienza e sicurezza <b>è importante effettuare gli aggiornamenti necessari all'hosting su cui risiede il sito</b>. Noi monitoriamo costantemente il tuo hosting effettuando gli aggiornamenti necessari per garantire il massimo delle prestazioni e sicurezza. La nostra assistenza ti permetterà di avere un hosting all'avanguardia e perfettamente funzionante.</p>
				</div>
			</section>
				
			<section className="bg-light">
				<div className="container grid g-2 place-center">
					<img src={gdpr} className="mx-w-25" alt="Conformità normativa privacy e cookie policy" />

					<div>
						<h2>Conformità normativa e cookie policy</h2>
						<p>Conformità alla normativa per garantire la sicurezza e la privacy dei dati dei visitatori del sito web. Abbiamo attenzione costante alle normative in vigore, verificando e aggiornando costantemente la conformità del sito web alle normative nazionali ed europee sulla privacy e sulla protezione dei dati. Ci occupiamo di <b>implementare i cookie banner a norma di legge</b> e di predisporre le policy di privacy e di utilizzo dei dati, per garantire la massima trasparenza e consapevolezza ai visitatori del sito. Dove possibile, interveniamo autonomamente senza costi aggiuntivi per il cliente, altre volte è necessario sottoscrivere abbonamenti con fornitori specializzati come <b>Iubenda, CookieBot</b> e altri.</p>
					</div>
				</div>
			</section>

			<section className="container">
				{manutenzioneFaqs.map((faq, idx) =>
					<Faq
						key={idx}
						question={faq.question}
						answer={faq.answer}
					/>
				)}
			</section>
			
			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-grey"
			/>
		</>
	);
}