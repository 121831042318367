export default function Cta({ title, text, urlBtn, urlBlank=false, classSection, classBtn, labelBtn }) {
	return (
		<section className={classSection ? "container grid gtc-3-1 g-2 "+classSection : "container grid gtc-3-1 g-2"}>
			<div>
				<h3 className={text ? 'fw-300 mb-1' : 'fw-300 mb-0'}>{title}</h3>
				{text ??
					<p>{text}</p>
				}
			</div>

			<a
				href={urlBtn}
				className={classBtn ? "btn btn-feature txt-center self-center "+classBtn : "btn btn-feature txt-center self-center m-auto"}
				target={urlBlank ? '_blank' : '_self'}
			>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m37.5,32s-.07,0-.1,0c-.09-.02-2.21-.47-4.08-2.44-.17.04-.35.09-.53.14-.57.15-1.16.31-1.8.31-2.8,0-4.77-.61-6.8-2.1-.22-.16-.27-.48-.11-.7.16-.22.48-.27.7-.11,1.84,1.35,3.64,1.9,6.2,1.9.5,0,1.03-.14,1.54-.28.29-.08.59-.16.87-.21.17-.03.35.03.47.16,1.12,1.27,2.4,1.88,3.13,2.16v-3.82c0-.17.08-.32.22-.41,1.41-.97,3.78-2.99,3.78-5.59,0-2.92-1.74-4.78-5.65-6.02-.26-.08-.41-.37-.32-.63.08-.26.36-.41.63-.33,4.33,1.38,6.35,3.6,6.35,6.98,0,2.2-1.42,4.42-4,6.26v4.24c0,.15-.07.29-.18.39-.09.07-.2.11-.32.11Z"/><path d="m6.5,30.4c-.1,0-.19-.03-.27-.08-.14-.09-.23-.25-.23-.42v-6.64c-2.79-1.97-6-5.22-6-9.76C0,5.93,7.33,0,16.68,0s16.32,5.8,16.32,13.5-6.36,12.5-15.82,12.5c-1.3,0-2.73-.23-3.99-.46-2.92,3.19-6.33,4.74-6.48,4.81-.07.03-.14.04-.2.04ZM16.68,1C7.89,1,1,6.49,1,13.5c0,4.22,3.15,7.27,5.78,9.09.14.09.22.25.22.41v6.08c1.17-.63,3.58-2.1,5.63-4.41.12-.13.29-.19.47-.16,1.28.25,2.77.5,4.08.5,8.87,0,14.82-4.62,14.82-11.5S25.41,1,16.68,1Z"/><path d="m16.5,15.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm0-3c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z"/><path d="m23.5,15.5c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm0-3c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z"/><path d="m9.5,15.59c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Zm0-3c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z"/></svg>
				</div>
				{labelBtn}
			</a>
		</section>
	);
}