import { Link } from 'react-router-dom';
import HelmetSeo from "../../components/HelmetSeo";
import Cta from '../../components/Cta';
import portale from '../../imgs/profile/portale.png';
import home1 from '../../imgs/profile/home1.png';
import home2 from '../../imgs/profile/home2.png';
import home3 from '../../imgs/profile/home3.png';
import calendar from '../../imgs/profile/calendar.png';
import sconti from '../../imgs/profile/sconti.png';
import website from '../../imgs/profile/website.png';


export default function SchedaWeb() {
	return (
		<>
		   <HelmetSeo
            pageTitle="Scheda Web Pagine Comunali - Milanesi Editore"
            description="La piattaforma integrata di PagineComunali ti permetterà di pubblicare le offerte, gli sconti, le news contemporaneamente sul tuo PcProfile Fast e sul tuo sito con un solo click"
            keys="scheda web, pagine comunali, elenchi telefonici, banca dati, azienda, promuovere azienda, raggiungere clienti"
         />
			
			<section className="bg-grey pb-0">
				<div className="container">
					<div className="grid gtc-1-2">
						<div className="txt-white">
							<h2 className="txt-white">Inserisci la tua attività su <b>PagineComunali</b></h2>
							<ul className="list mb-3">
								<li>Crea il tuo PcProfile</li>
								<li>Promuovi la tua Azienda</li>
								<li>Raggiungi più clienti</li>
								<li>Pubblica news, offerte o sconti</li>
								<li>Modifica in tempo reale</li>
							</ul>

							<Link to="/cosa-offriamo/profile/prezzi" className="btn btn-feature">
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32"><path d="m22.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h14c.28,0,.5.22.5.5s-.22.5-.5.5H1.5c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h21c.28,0,.5-.22.5-.5v-7.5c0-.28.22-.5.5-.5s.5.22.5.5v7.5c0,.83-.67,1.5-1.5,1.5Z"/><path d="m23.5,12.5c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m23.5,10h-7c-.84,0-1.5-.94-1.5-1.75V.5c0-.21.13-.39.32-.47.19-.07.41-.02.55.14l8,9c.13.15.16.36.08.54-.08.18-.26.3-.46.3ZM16,1.81v6.44c0,.33.28.75.5.75h5.89l-6.39-7.19Z"/><path d="m15.88,14H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.38c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.5,18H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h9c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m11.5,10h-6c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h6c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m15.75,22H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.25c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m18.5,26H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h13c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.5,24c-3.58,0-6.5-2.92-6.5-6.5s2.92-6.5,6.5-6.5,6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5Zm0-12c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5,5.5-2.47,5.5-5.5-2.47-5.5-5.5-5.5Z"/><path d="m32.5,31c-.13,0-.26-.05-.35-.15l-8-8c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/></svg>
								</div>
								Prova subito
							</Link>
						</div>

						<img src={portale} style={{ transform: 'translateY(4rem)' }} alt="Pagine Comunali portale" />
					</div>
				</div>
			</section>


			<section className="bg-light">
				<div className="container">
					<h2 className="txt-center mb-3">Scopri i vantaggi di essere su <b>PagineComunali</b></h2>

					<div className="grid gtc-3 g-2">
						<div>
							<img src={home1} className="mb-2" alt="Crea il tuo Pagine Comunali Profile" />
							<h3>Crea il tuo Pc<b>Profile</b></h3>
							<p>Hai un Azienda? Con pochi click sarai online su PagineComunali. Sarai visibile anche su Tablet e Smartphone. Essere censito correttamente è solo l’inizio per farti trovare da nuovi clienti.</p>
						</div>

						<div>
							<img src={home2} className="mb-2" alt="Personalizza il tuo Pagine Comunali Profile" />
							<h3>Personalizza il tuo Pc<b>Profile</b></h3>
							<p>Inserisci con attenzione tutti i tuoi dati, indirizzo, telefono, email, descrizione, orari di apertura e giorni di chiusura, keyword, foto. Inoltre potrai inserire le news, le offerte o i prodotti in saldo tutto autonomamente e in tempo reale.</p>
						</div>

						<div>
							<img src={home3} className="mb-2" alt="Il tuo Profile integrato di Pagine Comunali" />
							<h3>Il tuo <b>Profile</b> integrato</h3>
							<p>Associa il tuo nuovo Profilo di PagineComunali con il tuo sito internet scelto tra i nostri Template Pro, un'unica piattaforma per promuovere i tuoi prodotti su tre siti diversi, PcProfile, PcWebSite Pro e sul portale PagineComunali.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="bg-light">
				<div className="container">
					<div className="grid g-3 rg-10 place-center mb-5">
						<h2 className="mx-w-25">Perché PagineComunali è la scelta migliore per te?</h2>

						<div>
							<p>La risposta è semplice. Con PagineComunali hai la libertà di realizzare gratuitamente il tuo Pc<b>Profile</b>. Non importa quanta esperienza tu abbia nel creare pagine web. Vuoi avere un Profilo azienda creato per te? Prova Pc<b>Profile</b> Free. Hai bisogno di funzionalità avanzate, di inserire più informazioni? PagineComunali ti offre anche questo, Pc<b>Profile</b> Smart e Fast, ottieni il pacchetto completo: crea la tua scheda, ricevi l’hosting gratuito con protocollo “Https”, ottimizzato SEO per i maggiori motori di ricerca e 100% responsive. Non sei ancora convinto? Prova subito la nostra piattaforma gratuita e crea il tuo Pc<b>Profile</b> Free.</p>
						</div>

						<img src={calendar} className="mx-w-25" alt="Aggiungi le funzionalità di cui hai bisogno" />

						<div>
							<h2>SpecialCat<br />Lo creiamo su misura per te</h2>
							<p>Personalizza il tuo profilo esattamente come vuoi. Aggiungi le funzionalità di cui hai bisogno, come un modulo di contatto così i tuoi visitatori potranno contattarti direttamente dal tuo profilo, o i link dei tuoi social network: da Facebook, a Instagram, a Twitter.</p>
						</div>

						<div>
							<h2>PcProfile Fast<br />News - Eventi - Offerte</h2>
							<p>La piattaforma integrata di PagineComunali ti permetterà di pubblicare le offerte, gli sconti, le news contemporaneamente sul tuo PcProfile Fast e sul tuo sito con un solo click. Potrai pianificare in anticipo le tue campagne, indicando la data di pubblicazione e la data di oscuramento.</p>
						</div>

						<img src={sconti} className="mx-w-25" alt="News - Eventi - Offerte - Sconti" />
					</div>
				</div>
			</section>

			
			<section className="bg-light p-0">
				<Cta
					title="La tua Attività online in pochi click!"
					text="Inserisci la tua Attività su PagineComunali e raggiungi più clienti."
					urlBtn="prezzi"
					classSection="pbk-sm"
					labelBtn="Prova Subito"
				/>
			</section>


			<section className="container grid g-3 place-center">
				<div>
					<h2>Pc<b>WebSite</b></h2>
					<p>PagineComunali ti offre l’opportunità di scegliere il tuo nuovo sito web prendendo spunto dalla proprie librerie. Lasciati ispirare da più di 200 template, la tua scelta sarà personalizzata seguendo le necessità della tua azienda. Il client manager a te dedicato è sempre al tuo fianco in tutto il processo di creazione del sito web, dall’intervista iniziale per capire le esigenze della tua azienda, alla scelta delle immagini e alla creazione dei testi, sino al controllo finale prima della pubblicazione online.</p>

					<Link to="/" className="btn btn-feature">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32"><path d="m22.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h14c.28,0,.5.22.5.5s-.22.5-.5.5H1.5c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h21c.28,0,.5-.22.5-.5v-7.5c0-.28.22-.5.5-.5s.5.22.5.5v7.5c0,.83-.67,1.5-1.5,1.5Z"/><path d="m23.5,12.5c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m23.5,10h-7c-.84,0-1.5-.94-1.5-1.75V.5c0-.21.13-.39.32-.47.19-.07.41-.02.55.14l8,9c.13.15.16.36.08.54-.08.18-.26.3-.46.3ZM16,1.81v6.44c0,.33.28.75.5.75h5.89l-6.39-7.19Z"/><path d="m15.88,14H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.38c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.5,18H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h9c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m11.5,10h-6c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h6c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m15.75,22H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.25c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m18.5,26H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h13c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.5,24c-3.58,0-6.5-2.92-6.5-6.5s2.92-6.5,6.5-6.5,6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5Zm0-12c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5,5.5-2.47,5.5-5.5-2.47-5.5-5.5-5.5Z"/><path d="m32.5,31c-.13,0-.26-.05-.35-.15l-8-8c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/></svg>
						</div>
						Scopri
					</Link>
				</div>

				<img src={website} className="mx-w-25" alt="Librerie siti web template" />
			</section>
		</>
	);
}