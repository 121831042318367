import { EMAIL_ME, PHONE_ME, LIB_URL } from "../data/consts";
import { Link } from "react-router-dom";
import HelmetSeo from "../components/HelmetSeo";
import Cta from "../components/Cta";
import BtnScegliTemplate from "../components/BtnScegliTemplate";
import bg from "../imgs/bg/muticolor-wave.jpg";
import mondo from "../imgs/mondo.webp";
import template from "../imgs/template.webp";
import pc from "../imgs/pc.png";
import pc2 from "../imgs/pc2.png";

export default function Home() {
	return (
		<>
			<HelmetSeo
				pageTitle="Milanesi Editore"
				description="Un'unica agenzia per tutte le tue necessità di comunicazione digitale: I nostri professionisti trasformano la tua visione in un sito web ben progettato e ottimizzato per i motori di ricerca"
				keys="web agency, siti web, template, sito web aggiornato, grafica e design, comunicazione digitale, digital marketing, social media, landing page"
			/>

			<section className="hero section-bg" style={{"--bg-url": `url(${bg})`}}>
				<div className="container grid place-center g-2">
					<div>
						<h1 className="sm-none">PcWeb Agency</h1>
						<h2 className="fw-300">Non promettiamo la luna...<br />Noi ti diamo il Mondo!</h2>
					</div>

					<img src={mondo} className="mx-w-35" alt="Connettiti al mondo con i nostri siti web" />
				</div>
			</section>


			<section className="bg-light pbk-sm">
				<div className="container">
					<Cta
						title="Lasciati ispirare da più di 200 template!"
						text={<>Il tuo <b>sito web</b> sempre aggiornato</>}
						urlBtn={LIB_URL}
						classSection="p-0"
						labelBtn="Template"
					/>
				</div>
			</section>


			<section className="container grid place-center">
				<img src={pc} className="mx-w-30" alt="Agenzia di comunicazione digitale" />

				<div>
					<h2 className="txt-center mb-2">Un'unica agenzia per tutte le tue necessità di comunicazione digitale</h2>
					<ul className="list-check bold mb-2 pl-4">
						<li><Link to="/cosa-offriamo/advertising/strategia-consulenza">Strategia e consulenza</Link></li>
						<li><Link to="/cosa-offriamo/prodotti/grafica-e-design">Grafica e design</Link></li>
						<li><Link to="/cosa-offriamo/sitiweb/ecommerce">Sviluppo web</Link></li>
						<li><Link to="/cosa-offriamo/advertising/digital-marketing">Digital marketing</Link></li>
						<li><Link to="/cosa-offriamo/advertising/digital-marketing/social-ads">Social media</Link></li>
					</ul>
					<p className="txt-center">Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci.</p>
					<Link to="/contatti" className="btn btn-feature m-auto mb-2">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32"><path d="m22.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h14c.28,0,.5.22.5.5s-.22.5-.5.5H1.5c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h21c.28,0,.5-.22.5-.5v-7.5c0-.28.22-.5.5-.5s.5.22.5.5v7.5c0,.83-.67,1.5-1.5,1.5Z"/><path d="m23.5,12.5c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m23.5,10h-7c-.84,0-1.5-.94-1.5-1.75V.5c0-.21.13-.39.32-.47.19-.07.41-.02.55.14l8,9c.13.15.16.36.08.54-.08.18-.26.3-.46.3ZM16,1.81v6.44c0,.33.28.75.5.75h5.89l-6.39-7.19Z"/><path d="m15.88,14H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.38c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m14.5,18H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h9c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m11.5,10h-6c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h6c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m15.75,22H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h10.25c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m18.5,26H5.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h13c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.5,24c-3.58,0-6.5-2.92-6.5-6.5s2.92-6.5,6.5-6.5,6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5Zm0-12c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5,5.5-2.47,5.5-5.5-2.47-5.5-5.5-5.5Z"/><path d="m32.5,31c-.13,0-.26-.05-.35-.15l-8-8c-.2-.2-.2-.51,0-.71s.51-.2.71,0l8,8c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/></svg>
						</div>
						Contattaci
					</Link>

					<p className="txt-center">o chiamaci al <a href={`tel:${PHONE_ME}`}><b>{PHONE_ME}</b></a></p>
				</div>
			</section>


			<section className="container relative">
				<div className="bg-box">
					<h2><span className="fw-300">Domande e</span> <em>Risposte</em></h2>
					<p>Quanto costa un <b><Link to="/cosa-offriamo/sitiweb/prezzi">sito web</Link></b>?<br />Che cos’è il <b><Link to="/cosa-offriamo/advertising/digital-marketing">digital marketing</Link></b>?</p>
				</div>

				<img src={pc2} className="absolute-right mx-w-30 sm-none" alt="Siamo qui per trasformare le tue idee in realtà" />

				<ul className="list-check mb-3 pl-4">
   				<li>Compila il form e inviaci le tue richieste</li>
   				<li>Inviaci una email</li>
   				<li>Contattaci telefonicamente</li>
				</ul>

				<div className="grid">
					<div className="flex g-1" style={{ flexDirection: "row" }}>
						<a href={`tel:${PHONE_ME}`} className="btn-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.59 19.6"><path className="cls-1" d="m19.59,15.8c0-.32-.24-.73-.72-1.25-.39-.42-.92-.89-1.52-1.36-1-.78-2.41-1.69-3.06-1.69s-1.12.8-1.64,1.64c-.22.36-.44.72-.66.99-.22.28-.37.37-.46.4l-.07.02-.07-.03c-2.4-.96-5.36-3.92-6.33-6.33l-.03-.06.02-.07c.03-.09.12-.24.4-.46.27-.22.65-.45.98-.65.84-.52,1.64-1.01,1.64-1.65s-.91-2.06-1.69-3.06c-.47-.6-.94-1.13-1.36-1.52-.52-.48-.93-.72-1.25-.72-.65,0-1.64.64-2.32,1.27-.44.41-1.46,1.46-1.46,2.53,0,1.74.49,3.59,1.47,5.51.9,1.78,2.2,3.53,3.74,5.08,1.54,1.54,3.3,2.84,5.07,3.74,1.92.98,3.77,1.47,5.51,1.47h.06c1.05-.04,2.08-1.04,2.48-1.47.63-.69,1.27-1.67,1.27-2.33Zm-1.71,1.92c-.73.8-1.52,1.28-2.09,1.28C8.97,19,.6,10.62.6,3.8c0-.58.48-1.36,1.27-2.09.63-.58,1.24-.94,1.65-1.06l-.04-.11.33.06c.32.06,1.13.73,2.16,2.07.93,1.21,1.5,2.28,1.51,2.61v.03s0,.03,0,.03c-.02.08-.11.23-.4.46-.26.2-.6.42-.94.62-.46.28-.9.55-1.21.83-.43.39-.57.78-.42,1.15,1.03,2.58,4.09,5.64,6.67,6.67.09.04.19.06.29.06.66,0,1.16-.81,1.68-1.67.22-.35.43-.69.63-.95.23-.29.38-.38.46-.41h.03s.03,0,.03,0c.38,0,1.51.66,2.62,1.51,1.35,1.04,2.02,1.85,2.07,2.16,0,.39-.39,1.17-1.11,1.95Z"/></svg>
						</a>
						
						<a href={`mailto:${EMAIL_ME}`} className="btn-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.98 31.99"><path d="m20.05.4c-.58-.53-1.54-.53-2.12,0L.24,11.07c-.15.09-.24.25-.24.43v18.92c0,.86.71,1.57,1.57,1.57h34.83c.87,0,1.57-.7,1.57-1.57V11.5c0-.18-.09-.34-.24-.43L20.05.4Zm-1.56.83s.07-.05.1-.07c.11-.11.25-.17.4-.17s.29.06.4.17c.03.03.06.05.1.07l17.23,10.39-12.48,7.44c-.24.14-.31.45-.17.69.09.16.26.24.43.24.09,0,.18-.02.26-.07l12.23-7.29v17.76l-16.92-11.27c-.54-.43-1.59-.43-2.1-.02L1,30.38V12.63l12.23,7.29c.08.05.17.07.26.07.17,0,.34-.09.43-.24.14-.24.06-.54-.17-.69L1.27,11.62,18.49,1.23Zm17.59,29.76H1.89l16.66-11.09c.17-.14.71-.14.92.02l16.61,11.06Z"/></svg>
						</a>
						
						<Link to="/contatti" className="btn-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.6 18.6"><path d="m15.6,13.3c0-.17-.13-.3-.3-.3s-.3.13-.3.3v4c0,.39-.31.7-.7.7H1.3c-.39,0-.7-.31-.7-.7V1.3c0-.39.31-.7.7-.7h13c.39,0,.7.31.7.7v2c0,.17.13.3.3.3s.3-.13.3-.3V1.3c0-.72-.58-1.3-1.3-1.3H1.3C.58,0,0,.58,0,1.3v16c0,.72.58,1.3,1.3,1.3h13c.72,0,1.3-.58,1.3-1.3v-4Z"/><path d="m10.3,3H3.3c-.17,0-.3.13-.3.3s.13.3.3.3h7c.16,0,.3-.13.3-.3s-.13-.3-.3-.3Z"/><path d="m12.3,5H3.3c-.17,0-.3.13-.3.3s.13.3.3.3h9c.16,0,.3-.13.3-.3s-.13-.3-.3-.3Z"/><path d="m11.3,7H3.3c-.17,0-.3.13-.3.3s.13.3.3.3h8c.16,0,.3-.13.3-.3s-.13-.3-.3-.3Z"/><path d="m8.3,9H3.3c-.17,0-.3.13-.3.3s.13.3.3.3h5c.16,0,.3-.13.3-.3s-.13-.3-.3-.3Z"/><path d="m19.51,6.59l-2.5-2.5c-.06-.06-.13-.09-.21-.09s-.15.03-.21.09l-7.5,7.5s-.06.08-.08.13l-1,3.5c-.03.1,0,.21.07.29.07.08.2.11.29.09l3.5-.86c.05-.01.1-.04.14-.08l7.5-7.64c.11-.12.11-.31,0-.42Zm-7.87,7.58l-2.92.71.87-2.96,7.2-7.2,2.08,2.08-7.23,7.37Z"/><path d="m6.3,15h-3c-.17,0-.3.13-.3.3s.13.3.3.3h3c.16,0,.3-.13.3-.3s-.13-.3-.3-.3Z"/></svg>
						</Link>
					</div>
				</div>
			</section>


			<section className="bg-light">
				<div className="container grid place-center g-3">
					<img src={template} className="mx-w-30" alt="Sito web ben progettato e ottimizzato per i motori di ricerca" />

					<div>
						<h2>Siti Web</h2>
						<p>I nostri professionisti trasformano la tua visione in un sito web ben progettato e ottimizzato per i motori di ricerca. Offriamo <b>soluzioni web su misura</b>, che includono <b>landing page, siti vetrina, eCommerce, progressive web app, portali, marketplace e applicazioni di back-end</b> aziendali integrate con i sistemi gestionali.</p>

						<ul className="mb-3">
							<li className="grid g-1 mb-1" style={{ gridTemplateColumns: '1.2rem 1fr' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ width: '1.2rem', transform: 'translateY(.15rem)' }}><path d="m30.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h29c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h29c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"/><g><path d="m4,28.48c-.13,0-.26-.05-.36-.15-.13-.13-.17-.33-.12-.5l3.16-9.71c.02-.07.07-.14.12-.2l10.2-10.2c.19-.19.52-.19.71,0l6.73,6.73c.19.19.2.51,0,.71l-10.09,10.21c-.06.06-.13.1-.21.13l-10,2.98s-.09.02-.14.02Zm3.6-9.95l-2.83,8.7,8.96-2.67,9.65-9.76-6.03-6.03-9.76,9.76Z"/><path d="m24.06,15.29c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l3.74-3.74c.22-.22.22-.57,0-.79l-5.24-5.23c-.22-.22-.57-.22-.79,0l-3.74,3.74c-.2.2-.51.2-.71,0s-.2-.51,0-.71l3.74-3.74c.61-.61,1.59-.61,2.2,0l5.24,5.23c.61.61.61,1.6,0,2.2l-3.74,3.74c-.1.1-.23.15-.35.15Z"/><path d="m13.38,25.13l-.86-2.99c-.05-.18,0-.37.13-.5l9.24-9.03.7.71-9.02,8.82.78,2.71-.96.28Z"/><path d="m10.04,19.42s-.07,0-.11-.01l-2.9-.66.22-.98,2.63.6,8.86-8.9.71.71-9.06,9.1c-.09.1-.22.15-.35.15Z"/><path d="m13,22.5h-3c-.28,0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v2.5h2.5c.28,0,.5.22.5.5s-.22.5-.5.5Z"/></g></svg>
								<b>Onepage</b>
							</li>
							<li className="grid g-1 mb-1" style={{ gridTemplateColumns: '1.2rem 1fr' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.62 32" style={{ width: '1.2rem', transform: 'translateY(.15rem)' }}><path d="m16.31,24c-.08,0-.15-.02-.22-.05-.34-.16-8.28-4.12-8.28-11.45V2.5c0-.28.22-.5.5-.5h17c.28,0,.5.22.5.5v10c0,7.34-8.92,11.29-9.3,11.46-.06.03-.13.04-.2.04ZM8.81,3v9.5c0,6.08,6.29,9.78,7.51,10.44,1.33-.64,8.49-4.35,8.49-10.44V3H8.81Z"/><path d="m25.31,1H8.31c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h17c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m16.31,27c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m6.31,16c-.11,0-.21-.03-.31-.1-1.82-1.41-4.79-4.59-5.67-7.24-.57-1.71-.41-3.31.46-4.51.71-.98,2.24-2.14,5.52-2.14h2c.28,0,.5.22.5.5s-.22.5-.5.5h-2c-2.26,0-3.89.6-4.71,1.73-.67.93-.79,2.22-.32,3.61.81,2.42,3.66,5.47,5.33,6.76.22.17.26.48.09.7-.1.13-.25.19-.4.19Z"/><path d="m27.31,16c-.15,0-.3-.07-.4-.19-.17-.22-.13-.53.09-.7,1.56-1.21,4.7-4.87,5.33-6.76.47-1.4.35-2.68-.32-3.61-.82-1.13-2.44-1.73-4.71-1.73h-2c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2c3.28,0,4.81,1.17,5.52,2.14.86,1.2,1.03,2.8.46,4.51-.71,2.12-3.94,5.9-5.67,7.24-.09.07-.2.1-.31.1Z"/><path d="m23.31,32h-14c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h11c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-13.5-1h13v-.92c0-.61-.43-1.08-1-1.08h-11c-.59,0-1,.41-1,1v1Z"/></svg>
								<b>Starter</b>
							</li>
							<li className="grid g-1 mb-1" style={{ gridTemplateColumns: '1.2rem 1fr' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.42 32" style={{ width: '.9rem', transform: 'translateY(.15rem)' }}><path d="m18.92,30H4.92c-.12,0-.24-.05-.34-.13-1.22-1.11-1.87-2.64-1.88-4.42-.01-2.71,1.43-5.69,3.87-7.95,2.89-2.68,3.35-3.68,3.35-5.5,0-.28.22-.5.5-.5s.5.22.5.5c0,2.25-.75,3.53-3.67,6.24-2.2,2.04-3.56,4.81-3.55,7.21,0,1.42.5,2.65,1.42,3.55h13.8c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.92,32H2.92c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h18c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m17.92,24c-.06,0-.11,0-.17-.03-.26-.09-.39-.38-.3-.64,1.96-5.37,1.97-10.28,1.97-10.33,0-9-6.05-9.97-8.66-9.97-1.87,0-3.67-.88-4.72-1.51.19.97.54,2.02.54,2.03.04.13.03.28-.04.4-.07.13-1.8,3.22-5.25,6.2-.15.11-.49.43-.16,1.08.41.81,1.26,1.8,2.03,2.03.3.09.56.06.81-.09,1.37-.82,3.13-2.06,3.15-2.07.17-.12.4-.12.57,0,1.02.7,2.93.22,3.71-.92.64-.93.84-1.84.55-2.45-.12-.25-.02-.55.23-.67.25-.12.55-.01.67.23.65,1.34-.22,2.86-.63,3.45-.51.74-1.36,1.31-2.34,1.56-.89.23-1.77.16-2.46-.2-.55.38-1.85,1.27-2.95,1.93-.49.29-1.04.36-1.61.2-1.11-.32-2.15-1.54-2.65-2.54-.6-1.2.03-2.05.45-2.33,2.81-2.43,4.45-5.01,4.87-5.71-.18-.58-.65-2.17-.65-3.15,0-.19.11-.36.28-.45.17-.08.37-.06.53.05.02.02,2.53,1.92,5.04,1.92,2.27,0,9.66.79,9.66,10.97,0,.21,0,5.12-2.03,10.67-.07.2-.27.33-.47.33Z"/><path d="m20.92,21s-.08,0-.12-.01c-.27-.07-.43-.34-.36-.61.99-3.94.99-5.93.99-7.38,0-5.96-2.76-9.07-5.07-10.63-1.42-.96-3.05-1.37-5.43-1.37-.28,0-.5-.22-.5-.5s.22-.5.5-.5c2.59,0,4.38.46,5.99,1.54,2.52,1.7,5.51,5.06,5.51,11.46,0,1.5,0,3.56-1.01,7.62-.06.23-.26.38-.49.38Z"/></svg>
								<b>Pro</b>
							</li>
							<li className="grid g-1" style={{ width: '1.2rem', gridTemplateColumns: '1.2rem 1fr' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32" style={{ transform: 'translateY(.15rem)' }}><path d="m32.51,7.5c-.28,0-.5-.22-.5-.5v-1.67c0-.35-.29-.64-.64-.64h-2.84c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.84c.9,0,1.64.73,1.64,1.64v1.67c0,.28-.22.5-.5.5Z"/><path d="m13.04,6.97c-.21,0-.41-.14-.48-.35-.08-.26.07-.54.33-.62L27.45,1.57c.26-.08.53.06.62.32l1.5,4.42c.09.26-.05.55-.31.63-.26.09-.54-.05-.63-.31l-1.34-3.97-14.09,4.28s-.1.02-.15.02Z"/><path d="m5.21,6.97c-.21,0-.41-.13-.48-.34-.09-.26.06-.54.32-.63L23.17.03c.26-.09.55.06.63.32.09.26-.06.54-.32.63L5.36,6.95c-.05.02-.1.02-.16.02Z"/><path d="m36.5,24h-8c-.91,0-1.5-.59-1.5-1.5v-5c0-.91.59-1.5,1.5-1.5h5.5v-5.5c0-.35-.15-.5-.5-.5H3c-1.79,0-3-1.21-3-3s1.21-3,3-3h8c.28,0,.5.22.5.5s-.22.5-.5.5H3c-1.23,0-2,.77-2,2s.77,2,2,2h30.5c.91,0,1.5.59,1.5,1.5v6c0,.28-.22.5-.5.5h-6c-.35,0-.5.15-.5.5v5c0,.35.15.5.5.5h8c.35,0,.5-.15.5-.5v-6c0-.35-.15-.5-.5-.5-.28,0-.5-.22-.5-.5s.22-.5.5-.5c.91,0,1.5.59,1.5,1.5v6c0,.91-.59,1.5-1.5,1.5Z"/><circle cx="32" cy="20" r="1"/><path d="m33.5,32H3c-1.79,0-3-1.21-3-3V7c0-.28.22-.5.5-.5s.5.22.5.5v22c0,1.23.77,2,2,2h30.5c.35,0,.5-.15.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0,.91-.59,1.5-1.5,1.5Z"/></svg>
								<b>eCommerce</b>
							</li>
						</ul>

						<BtnScegliTemplate />
					</div>
				</div>
			</section>

			<section className="container pbk-sm">
				<p>Milanesi Editore è da più di 30 anni al servizio delle imprese, specializzata in editoria <Link to="/cosa-offriamo/profile/scheda-web">elenchi telefoni</Link>
				, <Link to="/cosa-offriamo/sitiweb/ecommerce">UX Design</Link>
				, <Link to="/cosa-offriamo/advertising/digital-marketing/seo">SEO</Link>
				, <Link to="/cosa-offriamo/advertising/digital-marketing">Digital Advertising</Link>
				, <Link to="/cosa-offriamo/advertising/digital-marketing">Marketing Automation</Link>
				, <Link to="/cosa-offriamo/prodotti/grafica-e-design">Brand Identity</Link>
				, <Link to="/cosa-offriamo/servizi/data-analytics">Data Analysis</Link>
				, <Link to="/cosa-offriamo/advertising/strategia-consulenza">Strategia Marketing</Link>
				, <Link to="/cosa-offriamo/advertising/digital-marketing">Content Marketing</Link>
				, <Link to="/cosa-offriamo/sitiweb/ecommerce">Sviluppo eCommerce in PrestaShop</Link>
				, <Link to="/cosa-offriamo/sitiweb/ecommerce">Sviluppo eCommerce in WordPress</Link>
				, <Link to="/cosa-offriamo/sitiweb/ecommerce">Sviluppo siti in Magento</Link>
				, <Link to="/cosa-offriamo/sitiweb/pro">Sviluppo siti custom</Link> e <Link to="/cosa-offriamo/sitiweb/starter">Sviluppo siti web</Link>.</p>
			</section>
		</>
	);
}