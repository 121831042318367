import useIsOpen from '../hooks/useIsOpen';

export default function Faq({ question, answer }) {
	const [isOpen, handleOpen] = useIsOpen();
	return (
		<article className="faq">
			<h4 onClick={handleOpen}>
				{question}?
				<button>
					{isOpen ?
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 256c0 17.7-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
						:
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z"/></svg>
					}
				</button>
			</h4>
			<p className={isOpen ? 'active' : ''}>{answer}</p>
		</article>
	)
}