import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/grey-wave.webp';
import report from '../../imgs/servizi/report.webp';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export default function ReportReview() {
	const isMobile = useWindowWidth() < 810;
   return (
      <>
         <HelmetSeo
            pageTitle="Report e Review - Milanesi Editore"
            description="La creazione di report e review serve per monitorare le performance del sito e apportare eventuali miglioramenti. Proponiamo sia offerte Free che Professionali con analisi periodica dei dati"
            keys="report, analitics, analisi dati, strategia marketing, funnel, A/B testing"
         />

         <PageTitle
            title='Report e Review'
            icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m33,32H9c-4.79,0-9-4.44-9-9.5,0-3.88,3.21-9.5,9-9.5h1.5c.28,0,.5.22.5.5s-.22.5-.5.5h-1.5c-5.33,0-8,5.45-8,8.5,0,4.53,3.74,8.5,8,8.5h24c4.31,0,8-4.67,8-8.5,0-5.21-4.44-8.54-7.5-8.54-.28,0-.5-.22-.5-.5s.22-.5.5-.5c3.93,0,8.5,4.17,8.5,9.54,0,4.28-4.15,9.5-9,9.5Z"/><path d="m33.5,16c-.28,0-.5-.22-.5-.5v-1.5c0-6.26-3.91-13-12.5-13S7.63,7.55,7.5,13.72c0,.28-.23.51-.51.49-.28,0-.5-.23-.49-.51C6.66,5.89,12.68,0,20.5,0c8.86,0,13.5,7.04,13.5,14v1.5c0,.28-.22.5-.5.5Z"/></svg>}
            text='Analisi dettagliata per ottimizzare il tuo sito'
				imgBottom={titleBg}
         />

         <section className="container pt-sm-4 grid g-3 place-center pb-0">
				<div className="mb-4">
					<h2>Report e Review</h2>
					<p><b>Proponiamo sia offerte Free che Professionali con analisi periodica dei dati</b> e personalizzate in tempo reale per soddisfare qualsiasi esigenza.</p>
               <p>La creazione di report e review serve per monitorare le performance del sito e apportare eventuali miglioramenti. La nostra esperienza nel settore ci permette di offrire un servizio completo di analisi e valutazione del sito web, utilizzando strumenti avanzati come <b>Google Analytics e SEMrush</b>. Utilizziamo tecniche di <b>analisi dei dati avanzate come funnel analysis e A/B testing</b> per ottenere informazioni dettagliate sull'andamento delle visite e delle conversioni.</p>
				</div>
				
            <Bar
               options={
                  {
                     responsive: true,
                     plugins: {
                        legend: { position: 'top' }
                     }
                  }
               }
               data={
                  {
                     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                     datasets: [
                        {
                           label: '2022',
                           data: [65, 45, 87, 78, 56, 35, 42]
                        }
                     ]
                  }
               }
            />
			</section>

         <section className="container grid g-3 place-center">
				{!isMobile &&
               <img src={report} className="mx-w-25" alt="Individuare aree di miglioramento e proporre soluzioni" />
            }

				<div className="mb-4">
					<h2>Review</h2>
               <p>Forniamo una review approfondita del sito web, per <b>individuare aree di miglioramento e proporre soluzioni</b> personalizzate per ottimizzare e migliorare la user experience. Attenzione costante alle performance per avere sempre un sito all'avanguardia e in linea con gli obiettivi del tuo business. I nostri report e review personalizzati ti forniranno informazioni utili per <b>prendere decisioni informate sulla strategia di marketing</b> digitale e migliorare il rendimento del tuo sito.</p>
				</div>
            {isMobile &&
               <img src={report} className="mx-w-25" alt="Individuare aree di miglioramento e proporre soluzioni" />
            }
			</section>
         
			<hr />

         <Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
            classBtn="bg-grey"
         />
      </>
   );
}