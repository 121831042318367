import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import BtnScegliTemplate from '../../components/BtnScegliTemplate';
import TabsLeft from '../../components/TabsLeft';
import Cta from '../../components/Cta';

import titleBg from '../../imgs/bg/green-wave.webp';
import ecommerce from '../../imgs/sitiweb/ecommerce.webp';
import ecommercePiattaforme from '../../imgs/sitiweb/piattaforme-ecommerce.webp';
import scrivanie from '../../imgs/sitiweb/scrivanie.webp';
import ecommerceMarketing from '../../imgs/sitiweb/ecommerce-marketing.webp';
import ecommerceStrategia from '../../imgs/sitiweb/ecommerce-strategia.webp';


const tabsDesign = [
	{
		id: 1,
		title: 'Progettazione Interfaccia Utente (UI):',
		content: 'I nostri designer esperti lavorano a stretto contatto con te per creare un\'interfaccia utente accattivante e intuitiva per il tuo negozio online. Questo include la disposizione degli elementi, la navigazione user-friendly e la presentazione dei prodotti in modo attraente.'
	},
	{
		id: 2,
		title: 'Design Responsivo:',
		content: 'Utilizziamo le migliori pratiche nel design responsivo per garantire che il tuo sito sia perfettamente fruibile su dispositivi desktop, tablet e mobile. I visitatori potranno navigare senza problemi ovunque si trovino.'
	},
	{
		id: 3,
		title: 'Identità Visiva del Brand:',
		content: 'Creiamo un design che rifletta perfettamente l\'identità visiva del tuo brand. Questo include l\'uso di colori, tipografie e elementi grafici che rafforzano la coerenza del brand in tutto il sito.'
	},
	{
		id: 4,
		title: 'Elementi Visivi Coinvolgenti:',
		content: 'Incorporiamo elementi visivi coinvolgenti, come immagini di alta qualità, grafiche accattivanti e animazioni leggere, per catturare l\'attenzione dei visitatori e creare un\'esperienza coinvolgente.'
	},
	{
		id: 5,
		title: 'Ottimizzazione delle Conversioni:',
		content: 'Il nostro obiettivo è guidare i visitatori verso l\'azione desiderata, che sia l\'acquisto di un prodotto o la registrazione. Utilizziamo il design per ottimizzare i tassi di conversione e massimizzare le vendite.'
	},
	{
		id: 6,
		title: 'Test Utente e Ottimizzazione:',
		content: 'Conduciamo test utente per valutare l\'efficacia del design e apportiamo miglioramenti basati sui feedback e sull\'analisi dei dati per garantire prestazioni ottimali.'
	},
	{
		id: 7,
		title: 'Integrazione di Funzionalità Speciali:',
		content: 'Se necessario, integriamo funzionalità speciali, come la visualizzazione 3D dei prodotti o la personalizzazione avanzata, per rendere il tuo sito unico e funzionale.'
	},
	{
		id: 8,
		title: 'Mobile App Design:',
		content: 'Se desideri un\'app mobile per il tuo eCommerce, i nostri designer possono creare un\'interfaccia utente appositamente progettata per dispositivi mobili.'
	}
]

export default function Ecommerce() {
	const isMobile = useWindowWidth() < 820;

	return (
		<>
			<HelmetSeo
				pageTitle="Siti web eCommerce - Milanesi Editore"
				description="Trasforma la tua visione in un negozio online di successo: progettazione, sviluppo e gestione di soluzioni eCommerce"
				keys="ecommerce, negozio online, prestashop, woocommerce, magento, shopify, development, strategie di marketing, buyer personas, branding, marketing"
			/>
			
			<PageTitle
				title='eCommerce'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32"><path d="m32.51,7.5c-.28,0-.5-.22-.5-.5v-1.67c0-.35-.29-.64-.64-.64h-2.84c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.84c.9,0,1.64.73,1.64,1.64v1.67c0,.28-.22.5-.5.5Z"></path><path d="m13.04,6.97c-.21,0-.41-.14-.48-.35-.08-.26.07-.54.33-.62L27.45,1.57c.26-.08.53.06.62.32l1.5,4.42c.09.26-.05.55-.31.63-.26.09-.54-.05-.63-.31l-1.34-3.97-14.09,4.28s-.1.02-.15.02Z"></path><path d="m5.21,6.97c-.21,0-.41-.13-.48-.34-.09-.26.06-.54.32-.63L23.17.03c.26-.09.55.06.63.32.09.26-.06.54-.32.63L5.36,6.95c-.05.02-.1.02-.16.02Z"></path><path d="m36.5,24h-8c-.91,0-1.5-.59-1.5-1.5v-5c0-.91.59-1.5,1.5-1.5h5.5v-5.5c0-.35-.15-.5-.5-.5H3c-1.79,0-3-1.21-3-3s1.21-3,3-3h8c.28,0,.5.22.5.5s-.22.5-.5.5H3c-1.23,0-2,.77-2,2s.77,2,2,2h30.5c.91,0,1.5.59,1.5,1.5v6c0,.28-.22.5-.5.5h-6c-.35,0-.5.15-.5.5v5c0,.35.15.5.5.5h8c.35,0,.5-.15.5-.5v-6c0-.35-.15-.5-.5-.5-.28,0-.5-.22-.5-.5s.22-.5.5-.5c.91,0,1.5.59,1.5,1.5v6c0,.91-.59,1.5-1.5,1.5Z"></path><circle cx="32" cy="20" r="1"></circle><path d="m33.5,32H3c-1.79,0-3-1.21-3-3V7c0-.28.22-.5.5-.5s.5.22.5.5v22c0,1.23.77,2,2,2h30.5c.35,0,.5-.15.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0,.91-.59,1.5-1.5,1.5Z"></path></svg>}
				text='Trasforma la tua visione in un negozio online di successo'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4">
				<div className="grid g-3 rg-10 place-center mb-5">
					<div>
						<h2>Portiamo la tua azienda nel mondo digitale</h2>
						<p>Con la nostra esperienza nella <b>progettazione, sviluppo e gestione di soluzioni eCommerce</b>, realizziamo per te una vetrina online all'avanguardia. Carica il tuo assortimento, siano essi prodotti fisici o digitali, e amplia la <b>tua presenza oltre i confini local</b>.</p>
						<p>Il nostro approccio all'eCommerce si concentra sulla creazione di un'<b>esperienza utente coinvolgente</b> su tutti i canali, sull'integrazione fluida tra il tuo negozio fisico e quello online e sulla gestione dei contenuti per massimizzare le conversioni. Unendo competenze tecniche e <b>strategie di marketing</b>, trasformiamo i visitatori in clienti fedeli, rendendo il tuo sito eCommerce un potente strumento di vendita.</p>
						<p className="mb-4">Siamo flessibili nel soddisfare le tue esigenze, offrendo soluzioni eCommerce basate su rinomati CMS come <b>WooCommerce o PrestaShop</b>, o creando progetti completamente personalizzati, da pagine monoprodotto a sofisticati marketplace globali. Scopri come possiamo guidarti verso il successo nell'universo dell'eCommerce.</p>

						{isMobile &&
							<div className="mb-4">
								<img src={ecommercePiattaforme} className="mx-w-30 mb-4" alt="Piattaforme eCommerce" />
								<ul className="list-check bold">
									<li>Prestashop Development</li>
									<li>WooCommerce Development</li>
									<li>Magento Development</li>
									<li>Shopify Development</li>
									<li>Custom eCommerce Development</li>
								</ul>
							</div>
						}

						<BtnScegliTemplate />
					</div>

					{!isMobile &&
						<div>
							<img src={ecommercePiattaforme} className="mx-w-30 mb-4" alt="Piattaforme eCommerce" />
							<ul className="list-check bold">
								<li>Prestashop Development</li>
								<li>WooCommerce Development</li>
								<li>Magento Development</li>
								<li>Shopify Development</li>
								<li>Custom eCommerce Development</li>
							</ul>
						</div>
					}

					{!isMobile &&
						<img src={ecommerceStrategia} className="mx-w-30" alt="Strategia eCommerce" />
					}

					<div>
						<h2>Strategia eCommerce</h2>
						<p className="mb-2">Nel cuore di ogni <b>soluzione di successo per l'eCommerce c'è una strategia ben definita</b>. La nostra web agency lavora a stretto contatto con te per identificare gli obiettivi chiave e pianificare una strategia efficace. Questo processo include la definizione delle buyer personas, l'analisi competitiva e di mercato, nonché la creazione di un piano strategico dettagliato. La nostra missione è garantire che il tuo negozio online sia pronto per raggiungere e <b>superare le tue aspettative</b>.</p>
					</div>

					{isMobile &&
						<img src={ecommerceStrategia} className="mx-w-30" alt="Strategia eCommerce" />
					}
				</div>

				<div className="box-line-grid">
					<div>
                  <h3>Definizione degli Obiettivi eCommerce:</h3>
                  <p>Lavoriamo a stretto contatto con te per stabilire gli obiettivi chiave del tuo eCommerce, come le vendite, la crescita del traffico e il coinvolgimento del cliente.</p>
               </div>
					
					<div>
                  <h3>Analisi di Mercato e Concorrenza:</h3>
                  <p>Effettuiamo un'analisi approfondita del mercato e della concorrenza per identificare opportunità, tendenze e strategie efficaci.</p>
               </div>

					<div>
                  <h3>Creazione delle Buyer Personas:</h3>
                  <p>Definiamo chiaramente il tuo pubblico target, comprese le caratteristiche demografiche, i comportamenti di acquisto e le preferenze.</p>
               </div>

					<div>
                  <h3>Pianificazione Strategica:</h3>
                  <p>Sviluppiamo un piano strategico dettagliato, che comprende l'identificazione dei canali di marketing più efficaci, le tattiche da adottare e i tempi di esecuzione.</p>
               </div>

					<div>
                  <h3>Audit del Sito Web:</h3>
                  <p>Effettuiamo un audit approfondito del tuo sito web esistente (se presente), identificando le aree che richiedono miglioramenti per ottimizzare le prestazioni.</p>
               </div>

					<div>
                  <h3>Strategia di Branding:</h3>
                  <p>Creiamo una strategia di branding completa che definisce l'identità visiva, il posizionamento del brand e la comunicazione coerente con la tua azienda.</p>
               </div>
				</div>
			</section>


			<section className="bbk">
				<div className="container">
					<div className="grid g-3 rg-10 place-center mb-5">
						<div>
							<h2>eCommerce Design</h2>
							<p>Il design è cruciale per il successo del tuo negozio online. I nostri esperti di design collaboreranno con te per creare una vetrina digitale accattivante e <b>user-friendly</b>. Incorporiamo elementi visivi che catturano l'attenzione dei visitatori e li guidano verso l'azione desiderata, garantendo un design che rappresenta perfettamente il tuo brand e che trasforma i <b>visitatori in clienti</b>.</p>
						</div>

						<img src={ecommerce} className="mx-w-30" alt="eCommerce Design" />
					</div>

					<TabsLeft tabs={tabsDesign} />

					<p>Questi servizi sono progettati per garantire che il design del tuo negozio online sia attraente, efficace e in linea con il tuo brand, con l'obiettivo di trasformare i visitatori in clienti soddisfatti.</p>
				</div>
			</section>


			<section className="bbk">
				<div  className="container">
					<h2>eCommerce Development:</h2>
					<p className="mb-4">La parte tecnica della realizzazione di un eCommerce è altrettanto importante. <b>La nostra web agency dispone di sviluppatori esperti</b> che tradurranno il tuo design in un sito web funzionale e performante. <b>Utilizziamo le ultime tecnologie e piattaforme</b>, garantendo che il tuo negozio online sia affidabile e scalabile. Dalla gestione dei prodotti all'integrazione con sistemi di pagamento sicuri, ci occupiamo di ogni dettaglio per offrire una soluzione eCommerce completa.</p>

					<h3>Sviluppo su Misura:</h3>
					<p><b>Creiamo siti eCommerce su misura</b>, adattati alle tue esigenze specifiche. Questo include la personalizzazione delle funzionalità e dell'architettura tecnica per garantire che il tuo negozio online soddisfi appieno i requisiti.</p>
					
					<h3>Integrazione con CMS:</h3>
					<p>Siamo in grado di sviluppare siti eCommerce basati su CMS popolari come <b>WooCommerce e PrestaShop</b>. Questi sistemi offrono una gestione intuitiva dei prodotti e delle vendite.</p>
					
					<h3>Gestione dei Prodotti:</h3>
					<p>Gestiamo l'inserimento e la gestione dei tuoi prodotti, compresi dettagli, immagini e prezzi, per garantire una <b>vetrina online sempre aggiornata</b> e attraente.</p>
					
					<h3>Integrazione di Sistemi di Pagamento Sicuri:</h3>
					<p>Integriamo sistemi di pagamento sicuri e affidabili per consentire ai tuoi clienti di effettuare transazioni online in modo protetto. Ci occupiamo dell'implementazione e della <b>configurazione dei gateway di pagamento</b>.</p>
					
					<h3>Ottimizzazione delle Prestazioni:</h3>
					<p>Ottimizziamo il sito per garantire velocità di caricamento veloci e una navigazione fluida. Questo contribuisce a migliorare l'esperienza dell'utente e a <b>ridurre i tassi di abbandono del carrello</b>.</p>
					
					<h3>Sicurezza dei Dati:</h3>
					<p>Implementiamo rigorose misure di sicurezza per proteggere i dati dei clienti e le transazioni online. <b>Ci occupiamo della gestione delle autorizzazioni</b> e delle misure di sicurezza per prevenire possibili minacce.</p>
					
					<h3>Testing e Controllo di Qualità:</h3>
					<p>Effettuiamo test approfonditi per verificare la funzionalità e l'integrità del sito eCommerce prima del suo lancio. Questo garantisce che il sito sia <b>privo di errori e pronto per l'uso</b>.</p>
					
					<h3>Supporto Tecnico Continuo:</h3>
					<p>Offriamo <b>supporto tecnico continuo</b> per affrontare qualsiasi problema tecnico o richiesta di assistenza dopo il lancio del sito.</p>

					<p className="mb-4">Questi servizi consentono di creare un eCommerce performante, sicuro e affidabile, offrendo un'esperienza di acquisto online senza problemi ai tuoi clienti.</p>

					<img src={scrivanie} alt="eCommerce Development" />
				</div>
			</section>

			
			<section className="container">
				<div className="grid g-3 rg-10 place-center mb-5">
					<div>
						<h2>eCommerce Marketing</h2>
						<p>Una volta che il tuo negozio online, <b>è essenziale promuoverlo efficacemente</b>. Offriamo servizi di <b>marketing eCommerce</b> per aumentare la visibilità del tuo sito e generare traffico di qualità. Questi servizi includono strategie di marketing digitale, <b>ottimizzazione SEO, pubblicità online, gestione dei social media e analisi dei dati</b>. Il nostro obiettivo è aiutarti a raggiungere il tuo pubblico target e a convertire visitatori in clienti soddisfatti.</p>
					</div>

					<img src={ecommerceMarketing} className="mx-w-30" alt="eCommerce Marketing e gestione social media" />
				</div>

				<div className="box-line-grid mb-5">
					<div>
                  <h3>Strategie di Marketing Digitale:</h3>
                  <p>Sviluppiamo strategie di marketing digitali su misura per il tuo eCommerce, includendo la definizione di obiettivi, la pianificazione delle campagne e l'ottimizzazione continua per massimizzare i risultati.</p>
               </div>
					
					<div>
                  <h3>Ottimizzazione SEO:</h3>
                  <p>Implementiamo tecniche di ottimizzazione dei motori di ricerca (SEO) per migliorare la visibilità del tuo sito web nei risultati di ricerca organica. Questo aumenta il traffico organico e attira visitatori interessati ai tuoi prodotti.</p>
               </div>
					
					<div>
                  <h3>Pubblicità Online:</h3>
                  <p>Gestiamo campagne pubblicitarie online, comprese pubblicità a pagamento su motori di ricerca (SEA) e annunci social media. Questo contribuisce a generare traffico mirato e a promuovere i tuoi prodotti.</p>
               </div>
					
					<div>
                  <h3>Gestione dei Social Media:</h3>
                  <p>Creiamo e gestiamo profili aziendali sui principali social media. Realizziamo strategie di contenuto, promuoviamo i tuoi prodotti e coinvolgiamo il pubblico per aumentare la visibilità e la fedeltà del cliente.</p>
               </div>
					
					<div>
                  <h3>Analisi dei Dati:</h3>
                  <p>Monitoriamo e analizziamo costantemente i dati per misurare le prestazioni del tuo eCommerce. Questo ci consente di apportare miglioramenti basati su dati concreti e ottimizzare le strategie di marketing.</p>
               </div>
					
					<div>
                  <h3>Email Marketing: </h3>
                  <p>Implementiamo strategie di email marketing per coinvolgere i clienti esistenti e potenziali. Questo include campagne di newsletter, offerte speciali e comunicazioni personalizzate.</p>
               </div>
					
					<div>
                  <h3>Marketing Automation:</h3>
                  <p>Utilizziamo strumenti di marketing automation per semplificare e automatizzare le attività di marketing, migliorando l'efficienza e la personalizzazione delle comunicazioni.</p>
               </div>
					
					<div>
                  <h3>Affiliate Marketing:</h3>
                  <p>Creiamo programmi di affiliazione per consentire ad altri siti web di promuovere i tuoi prodotti in cambio di una commissione sulle vendite.</p>
               </div>
				</div>

				<p>Questi servizi di marketing eCommerce sono essenziali per promuovere efficacemente il tuo negozio online, attirare il tuo pubblico target e convertire i visitatori in clienti soddisfatti.</p>
			</section>

			<hr />
			
			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="btn-feature-border-green"
			/>
		</>
	);
}