import { LIB_URL } from '../data/consts';
import { Link } from 'react-router-dom';
import useWindowWidth from '../hooks/useWindowWidth';
import useIsOpen from '../hooks/useIsOpen';
import SubmenuDesktop from './Navbar/SubmenuDesktop';
import SubmenuMobile from './Navbar/SubmenuMobile';
import logo from '../imgs/logo.svg';

export default function Header() {
	const isMobile = useWindowWidth() < 1200;
	const [isNavSmOpen, handleIsNavSmOpen] = useIsOpen();

	return (
		<header>
			<Link to="/" className="logo">
				<img src={logo} alt="logo" />
			</Link>

			<button className="nav-toggle" aria-controls="navbar" aria-expanded={isNavSmOpen} onClick={handleIsNavSmOpen}>
				<span className="sr-only">Menu</span>
				<span className="bar1" />
				<span className="bar2" />
				<span className="bar3" />
			</button>

			<nav id="navbar" data-visible={isNavSmOpen}>
				<ul>
					<li><Link to="/" onClick={handleIsNavSmOpen}>Home</Link></li>

					{isMobile ?
						<SubmenuMobile
							handleIsNavSmOpen={handleIsNavSmOpen}
						/>
						:
						<SubmenuDesktop
							isMobile={isMobile}
							handleIsNavSmOpen={handleIsNavSmOpen}
						/>
					}

					<li><a href={LIB_URL} onClick={handleIsNavSmOpen} target="_blank" rel="noreferrer">Librerie template</a></li>
					<li className="flex g-1">
						<Link to="contatti" className="btn btn-border" onClick={handleIsNavSmOpen}>Contatti</Link>
						<Link to="area-clienti" className="btn btn-border" onClick={handleIsNavSmOpen}>Area Clienti</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}