import { useState } from 'react'
import useWindowWidth from '../hooks/useWindowWidth'


export default function TabsLeft({ tabs }) {
	const isMobile = useWindowWidth() < 1080;

	const [currentTab, setCurrentTab] = useState(1),
			handleClick = e => setCurrentTab(e.target.id);

	const tabContent = tabs.find(tab => tab.id == currentTab);

	return (
		<div className="grid g-lg-6 place-center mb-3">
			<ul>
				{tabs.map(tab => (
					<li key={tab.id} className={`tab ${tab.id == currentTab ? 'active' : ''}`}>
						<h3 id={tab.id} onClick={handleClick}>{tab.id}. {tab.title}</h3>
						
						<svg viewBox="0 0 68 56" xmlns="http://www.w3.org/2000/svg"><path d="M39.0046 2.50283V13.3566C26.1713 12.3566 0.604639 19.4566 1.00464 55.8566C5.80464 34.2566 28.338 31.19 39.0046 32.3566V43.6682C39.0046 44.9376 40.4831 45.6331 41.461 44.8238L66.5935 24.0245C67.3233 23.4205 67.3175 22.2998 66.5816 21.7035L41.449 1.33743C40.4684 0.542809 39.0046 1.24069 39.0046 2.50283Z" /></svg>

						{isMobile &&
							<p>{tab.content}</p>
						}
					</li>
				))}
			</ul>
			
			{!isMobile && tabContent &&
				<p className="mx-w-25">{tabContent.content}</p>
			}
		</div>
	);
}