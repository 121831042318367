import { Link } from 'react-router-dom';
import LiSubmenu from './LiSubmenu';

export default function SitiwebNav({ handleIsNavSmOpen }) {
	return (
		<LiSubmenu label='Siti Web'>
			<li>
				<Link to="cosa-offriamo/sitiweb/onepage" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m30.5,32H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h29c.83,0,1.5.67,1.5,1.5v29c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v29c0,.28.22.5.5.5h29c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"/><g><path d="m4,28.48c-.13,0-.26-.05-.36-.15-.13-.13-.17-.33-.12-.5l3.16-9.71c.02-.07.07-.14.12-.2l10.2-10.2c.19-.19.52-.19.71,0l6.73,6.73c.19.19.2.51,0,.71l-10.09,10.21c-.06.06-.13.1-.21.13l-10,2.98s-.09.02-.14.02Zm3.6-9.95l-2.83,8.7,8.96-2.67,9.65-9.76-6.03-6.03-9.76,9.76Z"/><path d="m24.06,15.29c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l3.74-3.74c.22-.22.22-.57,0-.79l-5.24-5.23c-.22-.22-.57-.22-.79,0l-3.74,3.74c-.2.2-.51.2-.71,0s-.2-.51,0-.71l3.74-3.74c.61-.61,1.59-.61,2.2,0l5.24,5.23c.61.61.61,1.6,0,2.2l-3.74,3.74c-.1.1-.23.15-.35.15Z"/><path d="m13.38,25.13l-.86-2.99c-.05-.18,0-.37.13-.5l9.24-9.03.7.71-9.02,8.82.78,2.71-.96.28Z"/><path d="m10.04,19.42s-.07,0-.11-.01l-2.9-.66.22-.98,2.63.6,8.86-8.9.71.71-9.06,9.1c-.09.1-.22.15-.35.15Z"/><path d="m13,22.5h-3c-.28,0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5s.5.22.5.5v2.5h2.5c.28,0,.5.22.5.5s-.22.5-.5.5Z"/></g></svg>
					Onepage
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/sitiweb/starter" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.62 32"><path d="m16.31,24c-.08,0-.15-.02-.22-.05-.34-.16-8.28-4.12-8.28-11.45V2.5c0-.28.22-.5.5-.5h17c.28,0,.5.22.5.5v10c0,7.34-8.92,11.29-9.3,11.46-.06.03-.13.04-.2.04ZM8.81,3v9.5c0,6.08,6.29,9.78,7.51,10.44,1.33-.64,8.49-4.35,8.49-10.44V3H8.81Z"/><path d="m25.31,1H8.31c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h17c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m16.31,27c-.28,0-.5-.22-.5-.5v-2.5c0-.28.22-.5.5-.5s.5.22.5.5v2.5c0,.28-.22.5-.5.5Z"/><path d="m6.31,16c-.11,0-.21-.03-.31-.1-1.82-1.41-4.79-4.59-5.67-7.24-.57-1.71-.41-3.31.46-4.51.71-.98,2.24-2.14,5.52-2.14h2c.28,0,.5.22.5.5s-.22.5-.5.5h-2c-2.26,0-3.89.6-4.71,1.73-.67.93-.79,2.22-.32,3.61.81,2.42,3.66,5.47,5.33,6.76.22.17.26.48.09.7-.1.13-.25.19-.4.19Z"/><path d="m27.31,16c-.15,0-.3-.07-.4-.19-.17-.22-.13-.53.09-.7,1.56-1.21,4.7-4.87,5.33-6.76.47-1.4.35-2.68-.32-3.61-.82-1.13-2.44-1.73-4.71-1.73h-2c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2c3.28,0,4.81,1.17,5.52,2.14.86,1.2,1.03,2.8.46,4.51-.71,2.12-3.94,5.9-5.67,7.24-.09.07-.2.1-.31.1Z"/><path d="m23.31,32h-14c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h11c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-13.5-1h13v-.92c0-.61-.43-1.08-1-1.08h-11c-.59,0-1,.41-1,1v1Z"/></svg>
					Starter
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/sitiweb/pro" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.42 32"><path d="m18.92,30H4.92c-.12,0-.24-.05-.34-.13-1.22-1.11-1.87-2.64-1.88-4.42-.01-2.71,1.43-5.69,3.87-7.95,2.89-2.68,3.35-3.68,3.35-5.5,0-.28.22-.5.5-.5s.5.22.5.5c0,2.25-.75,3.53-3.67,6.24-2.2,2.04-3.56,4.81-3.55,7.21,0,1.42.5,2.65,1.42,3.55h13.8c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m20.92,32H2.92c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h18c.28,0,.5.22.5.5s-.22.5-.5.5Z"/><path d="m17.92,24c-.06,0-.11,0-.17-.03-.26-.09-.39-.38-.3-.64,1.96-5.37,1.97-10.28,1.97-10.33,0-9-6.05-9.97-8.66-9.97-1.87,0-3.67-.88-4.72-1.51.19.97.54,2.02.54,2.03.04.13.03.28-.04.4-.07.13-1.8,3.22-5.25,6.2-.15.11-.49.43-.16,1.08.41.81,1.26,1.8,2.03,2.03.3.09.56.06.81-.09,1.37-.82,3.13-2.06,3.15-2.07.17-.12.4-.12.57,0,1.02.7,2.93.22,3.71-.92.64-.93.84-1.84.55-2.45-.12-.25-.02-.55.23-.67.25-.12.55-.01.67.23.65,1.34-.22,2.86-.63,3.45-.51.74-1.36,1.31-2.34,1.56-.89.23-1.77.16-2.46-.2-.55.38-1.85,1.27-2.95,1.93-.49.29-1.04.36-1.61.2-1.11-.32-2.15-1.54-2.65-2.54-.6-1.2.03-2.05.45-2.33,2.81-2.43,4.45-5.01,4.87-5.71-.18-.58-.65-2.17-.65-3.15,0-.19.11-.36.28-.45.17-.08.37-.06.53.05.02.02,2.53,1.92,5.04,1.92,2.27,0,9.66.79,9.66,10.97,0,.21,0,5.12-2.03,10.67-.07.2-.27.33-.47.33Z"/><path d="m20.92,21s-.08,0-.12-.01c-.27-.07-.43-.34-.36-.61.99-3.94.99-5.93.99-7.38,0-5.96-2.76-9.07-5.07-10.63-1.42-.96-3.05-1.37-5.43-1.37-.28,0-.5-.22-.5-.5s.22-.5.5-.5c2.59,0,4.38.46,5.99,1.54,2.52,1.7,5.51,5.06,5.51,11.46,0,1.5,0,3.56-1.01,7.62-.06.23-.26.38-.49.38Z"/></svg>
					Pro
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/sitiweb/ecommerce" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32"><path d="m32.51,7.5c-.28,0-.5-.22-.5-.5v-1.67c0-.35-.29-.64-.64-.64h-2.84c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.84c.9,0,1.64.73,1.64,1.64v1.67c0,.28-.22.5-.5.5Z"/><path d="m13.04,6.97c-.21,0-.41-.14-.48-.35-.08-.26.07-.54.33-.62L27.45,1.57c.26-.08.53.06.62.32l1.5,4.42c.09.26-.05.55-.31.63-.26.09-.54-.05-.63-.31l-1.34-3.97-14.09,4.28s-.1.02-.15.02Z"/><path d="m5.21,6.97c-.21,0-.41-.13-.48-.34-.09-.26.06-.54.32-.63L23.17.03c.26-.09.55.06.63.32.09.26-.06.54-.32.63L5.36,6.95c-.05.02-.1.02-.16.02Z"/><path d="m36.5,24h-8c-.91,0-1.5-.59-1.5-1.5v-5c0-.91.59-1.5,1.5-1.5h5.5v-5.5c0-.35-.15-.5-.5-.5H3c-1.79,0-3-1.21-3-3s1.21-3,3-3h8c.28,0,.5.22.5.5s-.22.5-.5.5H3c-1.23,0-2,.77-2,2s.77,2,2,2h30.5c.91,0,1.5.59,1.5,1.5v6c0,.28-.22.5-.5.5h-6c-.35,0-.5.15-.5.5v5c0,.35.15.5.5.5h8c.35,0,.5-.15.5-.5v-6c0-.35-.15-.5-.5-.5-.28,0-.5-.22-.5-.5s.22-.5.5-.5c.91,0,1.5.59,1.5,1.5v6c0,.91-.59,1.5-1.5,1.5Z"/><circle cx="32" cy="20" r="1"/><path d="m33.5,32H3c-1.79,0-3-1.21-3-3V7c0-.28.22-.5.5-.5s.5.22.5.5v22c0,1.23.77,2,2,2h30.5c.35,0,.5-.15.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0,.91-.59,1.5-1.5,1.5Z"/></svg>
					eCommerce
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/sitiweb/funzionalita" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.58 32"><path d="m10.48,20.73c-.2,0-.38-.12-.46-.31l-2.66-6.42c-.1-.24,0-.51.22-.63l7.23-3.96c.13-.07.28-.08.41-.03.14.05.24.15.29.29l3.16,8.16c.05.13.04.28-.02.4-.06.13-.17.22-.31.26l-7.72,2.23s-.09.02-.14.02Zm-2.03-6.7l2.32,5.59,6.77-1.95-2.75-7.11-6.34,3.47Z"/><path d="m2,22.6c-.2,0-.38-.12-.46-.31L.04,18.68c-.1-.25,0-.53.25-.64l7.73-3.69c.12-.06.27-.06.39-.02.13.05.23.15.28.28l1.84,4.44c.05.13.05.27,0,.39-.06.13-.16.22-.29.27l-8.07,2.86c-.06.02-.11.03-.17.03Zm-.85-3.86l1.13,2.73,7.13-2.53-1.44-3.47-6.82,3.26Z"/><path d="m18.58,19.5c-.2,0-.39-.12-.47-.32l-3.91-10.22c-.09-.24,0-.5.23-.62l11.91-6.28c.12-.07.27-.08.41-.03.13.05.24.15.29.28l5,12.5c.05.13.05.27-.01.4-.06.13-.17.22-.3.26l-13,4s-.1.02-.15.02Zm-3.28-10.48l3.58,9.37,12.03-3.7-4.59-11.48-11.02,5.82Z"/><path d="m34.08,16c-.2,0-.39-.12-.46-.31L27.61.69c-.1-.26.02-.55.28-.65.25-.1.55.02.65.28l6,15c.1.26-.02.55-.28.65-.06.02-.12.04-.19.04Z"/><path d="m16.08,32c-.28,0-.5-.22-.5-.5v-7c0-.28.22-.5.5-.5s.5.22.5.5v7c0,.28-.22.5-.5.5Z"/><path d="m25.08,32c-.14,0-.28-.06-.38-.17l-6-7c-.18-.21-.16-.52.05-.7.21-.18.53-.16.7.05l6,7c.18.21.16.52-.05.7-.09.08-.21.12-.33.12Z"/><path d="m7.08,32c-.12,0-.23-.04-.33-.12-.21-.18-.23-.5-.05-.7l6-7c.18-.21.5-.23.7-.05.21.18.23.5.05.7l-6,7c-.1.12-.24.17-.38.17Z"/><path d="m19.08,25h-6c-.28,0-.5-.22-.5-.5v-1.5c0-1.14.86-2,2-2h3c1.12,0,2,.91,2,2.08v1.42c0,.28-.22.5-.5.5Zm-5.5-1h5v-.92c0-.61-.43-1.08-1-1.08h-3c-.59,0-1,.41-1,1v1Z"/></svg>
					Funzionalità
				</Link>
			</li>
			<li>
				<Link to="cosa-offriamo/sitiweb/prezzi" onClick={handleIsNavSmOpen}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32"><path d="m32.51,7.5c-.28,0-.5-.22-.5-.5v-1.67c0-.35-.29-.64-.64-.64h-2.84c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.84c.9,0,1.64.73,1.64,1.64v1.67c0,.28-.22.5-.5.5Z"/><path d="m13.04,6.97c-.21,0-.41-.14-.48-.35-.08-.26.07-.54.33-.62L27.45,1.57c.26-.08.53.06.62.32l1.5,4.42c.09.26-.05.55-.31.63-.26.09-.54-.05-.63-.31l-1.34-3.97-14.09,4.28s-.1.02-.15.02Z"/><path d="m5.21,6.97c-.21,0-.41-.13-.48-.34-.09-.26.06-.54.32-.63L23.17.03c.26-.09.55.06.63.32.09.26-.06.54-.32.63L5.36,6.95c-.05.02-.1.02-.16.02Z"/><path d="m36.5,24h-8c-.91,0-1.5-.59-1.5-1.5v-5c0-.91.59-1.5,1.5-1.5h5.5v-5.5c0-.35-.15-.5-.5-.5H3c-1.79,0-3-1.21-3-3s1.21-3,3-3h8c.28,0,.5.22.5.5s-.22.5-.5.5H3c-1.23,0-2,.77-2,2s.77,2,2,2h30.5c.91,0,1.5.59,1.5,1.5v6c0,.28-.22.5-.5.5h-6c-.35,0-.5.15-.5.5v5c0,.35.15.5.5.5h8c.35,0,.5-.15.5-.5v-6c0-.35-.15-.5-.5-.5-.28,0-.5-.22-.5-.5s.22-.5.5-.5c.91,0,1.5.59,1.5,1.5v6c0,.91-.59,1.5-1.5,1.5Z"/><circle cx="32" cy="20" r="1"/><path d="m33.5,32H3c-1.79,0-3-1.21-3-3V7c0-.28.22-.5.5-.5s.5.22.5.5v22c0,1.23.77,2,2,2h30.5c.35,0,.5-.15.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0,.91-.59,1.5-1.5,1.5Z"/></svg>
					Prezzi
				</Link>
			</li>
		</LiSubmenu>
	);
}