import { EMAIL_ME, PHONE_ME } from "../data/consts";
import { useRef } from "react";
import useWindowWidth from '../hooks/useWindowWidth';
import useSendEmail from '../hooks/useSendEmail';
import HelmetSeo from "../components/HelmetSeo";
import Form from "../components/Form/Form";
import InputField from "../components/Form/InputField";
import ufficio from "../imgs/contatti.webp"

export default function Contatti() {
	const isMobile = useWindowWidth() < 1190;
   const formRef = useRef();

	const initValue = {
      name:'',
      surname: '',
      agency: '',
      email: '',
      phone: '',
      website: '',
      message:''
   };
   const { formVals, handleChange, formErrs, handleSubmit, isSubmit, isSent } = useSendEmail(initValue, formRef);
   return (
      <>
         <HelmetSeo
				pageTitle="Contatti - Milanesi Editore"
				description="Desideri maggiori informazioni o parlarci del tuo progetto? Contattaci compilando il form"
				keys="contatti"
			/>

         <section className={`container relative ${!isMobile ? 'grid' : null} pb-sm-0`}>
            <div>
               <h2>Contatti</h2>
               <h1 className="mb-0">Desideri maggiori informazioni o parlarci del tuo progetto?</h1>
            </div>
				{!isMobile &&
               <img src={ufficio} className="absolute-head mx-w-50" alt="Ufficio Milanesi Editore" />
            }
         </section>


         <section className="container">
            <div className="grid gtc-2-1 g-3 place-start">
               <Form
                  noborder
                  formRef={formRef}
                  handleSubmit={handleSubmit}
                  errorsForm={formErrs}
                  isSent={isSent}
                  isSubmit={isSubmit}
               >
                  <p>Compila il form oppure scrivi a <a href={`mailto:${EMAIL_ME}`}>{EMAIL_ME}</a></p>

                  <div className="grid cg-2">
                     <InputField
                        label='Nome'
                        name='name'
                        type='text'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                        required={true}
                        value={formVals.name}
                        onChange={handleChange}
                        errorMsg={formErrs.name}
                     />

                     <InputField
                        label='Cognome'
                        name='surname'
                        type='text'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                        required={true}
                        value={formVals.surname}
                        onChange={handleChange}
                        errorMsg={formErrs.surname}
                     />
                  </div>

                  <InputField
                     label='Nome società'
                     name='agency'
                     type='text'
                     icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M486.4 563.2c-155.275 0-281.6-126.325-281.6-281.6s126.325-281.6 281.6-281.6 281.6 126.325 281.6 281.6-126.325 281.6-281.6 281.6zM486.4 51.2c-127.043 0-230.4 103.357-230.4 230.4s103.357 230.4 230.4 230.4c127.042 0 230.4-103.357 230.4-230.4s-103.358-230.4-230.4-230.4z"></path><path d="M896 1024h-819.2c-42.347 0-76.8-34.451-76.8-76.8 0-3.485 0.712-86.285 62.72-168.96 36.094-48.126 85.514-86.36 146.883-113.634 74.957-33.314 168.085-50.206 276.797-50.206 108.71 0 201.838 16.893 276.797 50.206 61.37 27.275 110.789 65.507 146.883 113.634 62.008 82.675 62.72 165.475 62.72 168.96 0 42.349-34.451 76.8-76.8 76.8zM486.4 665.6c-178.52 0-310.267 48.789-381 141.093-53.011 69.174-54.195 139.904-54.2 140.61 0 14.013 11.485 25.498 25.6 25.498h819.2c14.115 0 25.6-11.485 25.6-25.6-0.006-0.603-1.189-71.333-54.198-140.507-70.734-92.304-202.483-141.093-381.002-141.093z"></path></svg>}
                     required={true}
                     value={formVals.agency}
                     onChange={handleChange}
                     errorMsg={formErrs.agency}
                  />

                  <div className="grid cg-2">
                     <InputField
                        label='Email'
                        name='email'
                        type='email'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M896 307.2h-819.2c-42.347 0-76.8 34.453-76.8 76.8v460.8c0 42.349 34.453 76.8 76.8 76.8h819.2c42.349 0 76.8-34.451 76.8-76.8v-460.8c0-42.347-34.451-76.8-76.8-76.8zM896 358.4c1.514 0 2.99 0.158 4.434 0.411l-385.632 257.090c-14.862 9.907-41.938 9.907-56.802 0l-385.634-257.090c1.443-0.253 2.92-0.411 4.434-0.411h819.2zM896 870.4h-819.2c-14.115 0-25.6-11.485-25.6-25.6v-438.566l378.4 252.267c15.925 10.618 36.363 15.925 56.8 15.925s40.877-5.307 56.802-15.925l378.398-252.267v438.566c0 14.115-11.485 25.6-25.6 25.6z"></path></svg>}
                        required={true}
                        value={formVals.email}
                        onChange={handleChange}
                        errorMsg={formErrs.email}
                     />

                     <InputField
                        label='Telefono'
                        name='phone'
                        type='tel'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M819.2 1024c-90.691 0-187.154-25.699-286.706-76.386-91.794-46.736-182.48-113.654-262.258-193.522-79.763-79.853-146.595-170.624-193.272-262.498-50.608-99.61-76.269-196.102-76.269-286.795 0-58.774 54.765-115.55 78.31-137.232 33.85-31.17 87.104-67.568 125.794-67.568 19.245 0 41.803 12.589 70.994 39.616 21.782 20.17 46.27 47.51 70.814 79.067 14.794 19.021 88.592 116.267 88.592 162.917 0 38.27-43.25 64.853-89.037 92.998-17.694 10.875-35.992 22.122-49.226 32.73-14.114 11.315-16.645 17.288-17.061 18.629 48.602 121.128 197.141 269.651 318.203 318.184 1.085-0.341 7.067-2.699 18.592-17.075 10.608-13.234 21.854-31.531 32.73-49.227 28.144-45.789 54.726-89.038 92.998-89.038 46.648 0 143.896 73.798 162.917 88.592 31.557 24.546 58.898 49.032 79.067 70.816 27.029 29.189 39.616 51.747 39.616 70.992 0 38.701-36.378 92.115-67.528 126.099-21.693 23.662-78.491 78.701-137.272 78.701zM204.477 51.203c-13.731 0.262-50.634 17.054-90.789 54.029-38.115 35.099-61.792 73.25-61.792 99.568 0 344.523 423.093 768 767.304 768 26.28 0 64.418-23.795 99.528-62.099 37.003-40.366 53.806-77.413 54.069-91.178-1.662-9.728-28.57-47.563-102.232-104.283-63.322-48.762-114.699-74.886-127.901-75.237-0.925 0.274-6.656 2.467-18.277 17.222-10.104 12.832-20.912 30.418-31.366 47.424-28.683 46.666-55.774 90.744-95.122 90.744-6.336 0-12.597-1.219-18.608-3.624-134.376-53.75-293.31-212.685-347.061-347.061-6.456-16.138-7.485-41.414 24.272-70.184 16.882-15.293 40.25-29.656 62.848-43.546 17.006-10.453 34.59-21.261 47.422-31.366 14.755-11.619 16.95-17.352 17.222-18.277-0.352-13.203-26.475-64.579-75.237-127.902-56.72-73.659-94.554-100.568-104.282-102.23z"></path></svg>}
                        value={formVals.phone}
                        onChange={handleChange}
                        errorMsg={formErrs.phone}
                     />
                  </div>

                  <InputField
                     label='Sito web'
                     name='website'
                     type='text'
                     value={formVals.website}
                     onChange={handleChange}
                     errorMsg={formErrs.website}
                  />

                  <InputField
                     label='Messaggio'
                     name='message'
                     textarea={true}
                     value={formVals.message}
                     onChange={handleChange}
                  />

                  
                  <label htmlFor="agree" className="flex sm-flex-row mb-1">
                     <input
                        type="checkbox"
                        name="agree"
                        required
                     />
                     <span className="mb-0">Ho letto la <a href="https://www.iubenda.com/privacy-policy/68276502/legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a> e acconsento al trattamento dei miei dati personali *.</span>
                  </label>
               </Form>

               <div>
                  <div className="bg-box">
                     <h4>Contattaci per maggiori informazioni:</h4>
                     <a href={`mailto:${EMAIL_ME}`}>{EMAIL_ME}</a>
                  </div>

                  <div className="bg-box mb-4">
                     <h4>Chiamaci per maggiori informazioni:</h4>
                     <a href={`tel:${PHONE_ME}`}>{PHONE_ME}</a>
                  </div>

                  <h2>Milanesi Editore Srl</h2>
                  <h3 className="mb-1">Vicenza</h3>
                  <address className="mb-3">Via Capitano Alessio, 181/b, 36027 Rosà (VI)</address>
   
                  <h3 className="mb-1">Cagliari</h3>
                  <address>Via R,Piras 5, 09044 Quartucciu (CA)</address>
               </div>
            </div>
         </section>
      </>
   );
}