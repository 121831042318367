import useWindowWidth from '../../hooks/useWindowWidth';
import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/red-wave.webp';
import strategia from '../../imgs/advertising/strategia.webp';
import world from '../../imgs/advertising/world.webp';
import work from '../../imgs/advertising/work.webp';
import channel from '../../imgs/advertising/channel.webp';
import analisi from '../../imgs/advertising/analisi.webp';


export default function StrategiaConsulenza() {
	const isMobile = useWindowWidth() < 1080;
	return (
		<>
         <HelmetSeo
            pageTitle="Strategia e Consulenza - Milanesi Editore"
            description="La strategia digitale è un piano aziendale per sfruttare i vantaggi delle tecnologie digitali. Il nostro team di esperti ti aiuta a creare e implementare questa strategia"
            keys="consulenza, strategia digitale, trasformazione digitale"
         />
			
			<PageTitle
				title='Strategia e Consulenza'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m16,21h-.05c-2.76-.03-4.98-2.29-4.95-5.05.03-2.74,2.27-4.95,5-4.95h.05c2.76.03,4.98,2.29,4.95,5.05h0c-.03,2.74-2.27,4.95-5,4.95Zm0-9c-2.19,0-3.98,1.77-4,3.96-.02,2.21,1.75,4.02,3.96,4.04,2.24.03,4.02-1.75,4.04-3.96.02-2.21-1.75-4.02-3.96-4.04h-.04Zm4.5,4.05h0,0Z"/><circle cx="1" cy="16" r="1"/><circle cx="31" cy="16" r="1"/><circle cx="16" cy="1" r="1"/><circle cx="16" cy="31" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="27" cy="27" r="1"/><circle cx="27" cy="5" r="1"/><circle cx="5" cy="27" r="1"/><path d="m16,24h-.08c-2.14-.02-4.14-.87-5.63-2.4s-2.31-3.54-2.29-5.68c.04-4.41,3.64-7.94,8.08-7.92h7.42c.28,0,.5.22.5.5v7.58h0c-.04,4.39-3.63,7.92-8,7.92Zm0-15c-3.83,0-6.96,3.09-7,6.93-.02,1.87.69,3.63,2,4.97s3.06,2.08,4.93,2.1h.07c3.83,0,6.96-3.09,7-6.93v-7.07h-7Zm7.5,7.08h0,0Z"/></svg>}
            text='Guida strategica per il tuo successo digitale'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4">
				<div className="txt-center mb-5">
               <h2>Piano di trasformazione digitale</h2>
               <p>La strategia digitale è un piano aziendale per sfruttare i vantaggi delle tecnologie digitali. Il nostro team di esperti ti aiuta a creare e implementare questa strategia, focalizzandosi su:</p>
            </div>

				<div className="grid g-3 rg-10 place-center">
               <div>
                  <ul className="list">
                     <li>Stabilire gli scopi del tuo progetto digitale, garantendo che siano in linea con gli obiettivi aziendali di più ampio respiro</li>
                     <li>Riconoscere i clienti ideali della tua azienda (buyer personas)</li>
                     <li>Capire le debolezze e i punti di forza dei tuoi competitor, allo scopo di creare un'analisi della concorrenza nel campo digitale</li>
                     <li>Determinare le competenze necessarie e delegare i ruoli all'equipe impegnata nel progetto</li>
                     <li>Creare un piano editoriale e una strategia di content marketing</li>
                     <li>Selezionare i canali mediatici più adeguati per la tua attività e sviluppare in maniera coordinata gli strumenti e i canali digitali del tuo ecosistema</li>
                     <li>Progettare iniziative di lead generation e perfezionare le conversioni</li>
                     <li>Organizzare, sviluppare e gestire i tuoi canali social</li>
                     <li>Considerare la possibilità e le dimensioni di una strategia di digital advertising e social media marketing</li>
                     <li>Monitorare, esaminare i dati disponibili e apportare modifiche in base ai risultati ottenuti</li>
                  </ul>
               </div>

               <img src={strategia} className="mx-w-25" alt="Strategia digitale" />
               

               {!isMobile &&
                  <img src={work} className="mx-w-25" alt="Pianificazione Cross-Canale" />
               }

               <div>
                  <h2>Strategia e Pianificazione Cross-Canale</h2>
                  <p>Ci concentriamo su un obiettivo principale, stimolare la crescita del tuo business. <b>Desideriamo incrementare le vendite e moltiplicare i tuoi contatti attraverso una strategia cross-canale efficace e mirata.</b></p>
                  <p>Il nostro punto di forza risiede nell'offerta di una <b>direzione strategica alle tue campagne pubblicitarie</b>. Uniamo le tue iniziative sia online che offline, selezionando con cura il mix ideale di media e piattaforme. Il nostro obiettivo è veicolare il tuo messaggio nel modo più efficace possibile, raggiungendo il tuo pubblico target nel momento giusto.</p>
               </div>
               
               {isMobile &&
                  <img src={work} className="mx-w-25" alt="Pianificazione Cross-Canale" />
               }
               

               <div>
                  <p>Sfruttiamo la nostra <b>competenza in design, copywriting e posizionamento strategico</b> per creare campagne accattivanti. Queste non solo aumentano la consapevolezza del tuo marchio, ma generano anche <b>traffico di alta qualità verso il tuo sito web</b>.</p>
                  <p>Non ci accontentiamo di creare campagne accattivanti, miriamo anche a <b>ottimizzare le tue performance</b>. Attraverso l'affinamento della selezione delle parole chiave e l'ottimizzazione delle variabili demografiche e geografiche, puntiamo a massimizzare il ritorno sugli investimenti delle tue campagne e il raggiungimento degli obiettivi di conversione.</p>
               </div>

               <img src={world} className="mx-w-25" alt="Ottimizzare le performance" />
               

               {!isMobile &&
                  <img src={channel} className="mx-w-25" alt="Evoluzione digitale" />
               }

               <div>
                  <p>Sappiamo quanto sia fondamentale selezionare il mix di canali giusto per la tua comunicazione. Identifichiamo e assegniamo obiettivi e indicatori di performance per ciascun canale scelto, che si tratti del tuo sito web, delle applicazioni mobili, dei social media, delle email, della stampa, dei video, delle campagne display o degli sms.</p>
                  <p>Garantiamo l'adattabilità dei tuoi contenuti, facendo in modo che possano essere estesi su più piattaforme senza perdere la loro efficacia. Così facendo, assicuriamo un'esperienza di marca coerente e significativa, che può iniziare su un canale e proseguire su un altro.</p>
                  <p>Infine, aiutiamo la tua organizzazione a pianificare la migliore strategia di mix di canali. Enfatizziamo l'interazione tra i diversi media per creare esperienze di marca significative, sia online che offline.</p>
                  <p><b>La nostra missione è accompagnarti nel tuo viaggio attraverso l'evoluzione digitale</b>, assicurandoci che ogni tuo investimento contribuisca a raggiungere i tuoi obiettivi di business.</p>
               </div>

               {isMobile &&
                  <img src={channel} className="mx-w-25" alt="Evoluzione digitale" />
               }
               

               <div>
                  <h2>Esplorazione del pubblico obiettivo e analisi competitiva</h2>
                  <p>Munendoci di strumenti di ricerca e SEO all'avanguardia, sveliamo il profilo dettagliato del tuo pubblico di riferimento. Scopriamo archetipi di persona, interessi e comportamenti per creare una mappatura efficace e orientata ai tuoi obiettivi.</p>
                  <p><b>L'analisi della concorrenza è fondamentale per plasmare una strategia vincente di marketing digitale.</b> Tracciamo l'attività online dei tuoi competitor, analizziamo il loro <b>posizionamento SEO</b> e studiamo le strategie che stanno utilizzando per darti un vantaggio competitivo. Il nostro obiettivo è fornirti gli strumenti per superare la concorrenza e raggiungere la vetta.</p>
               </div>

               <img src={analisi} alt="Analisi competitiva" />
            </div>
         </section>

         <hr />

         <Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
            classBtn="btn-feature-border-red"
         />
		</>
	);
}