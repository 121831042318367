export const pricePlans = [
   {
      name: 'Onepage',
      price: 40,
      features: [
         [
            'Consulente dedicato',
            'Un consulente dedicato a tua disposizione'
         ],
         [
            'Dominio',
            ''
         ],
         [
            'Hosting',
            'Compreso con il sito, un hosting affidabile e sempre aggiornato mantiene il tuo sito web veloce e sicuro'
         ],
         [
            '1 casella email',
            'Casella di posta personalizzata, un aspetto professionale è indispensabile a far crescere la credibilità del tuo brand, di conseguenza il tuo business'
         ],
         [
            'Design responsivo',
            'Adatto a tutti i dispositivi mobili, Il tuo sito web avrà un aspetto fantastico su ogni dispositivo, computer, tablet o smartphone'
         ],
         [
            'Custom CSS',
            'Modifichiamo lo stile grafico del sito che hai scelto a tuo piacimento'
         ],
         [
            'Servizio di copywriting',
            ''
         ],
         [
            'Foto in stock',
            'Scegli le tue foto, vettoriali o infografici da oltre 40 gallerie. Servizio a pagamento'
         ],
         [
            'Foto editing',
            ''
         ],
         [
            'Video',
            'Trasforma il tuo sito con uno sfondo video unico. Utilizza i nostri video gratuiti o carica i tuoi video personalizzati'
         ],
         [
            'Widget',
            'Form contatti standard'
         ],
         [
            'Collegamento ai social',
            ''
         ],
         [
            'WhatsApp button',
            ''
         ],
         [
            'Sicurezza Https / SSL',
            'Con il protocollo HTTPS e il certificato di protezione SSL, il tuo sito sarà sempre sicuro per i tuoi visitatori e premiato dai motori di ricerca'
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'Google Analytics 4',
            ''
         ],
         [
            'Cookie Banner monolingue',
            'Sino a 50 pagine, singolo dominio e monolingue compreso'
         ],
         [
            'Policy base',
            ''
         ],
         [
            'Condivisione grafica per approvazione finale',
            ''
         ],
         [
            'Manutenzione ordinaria illimitata',
            ''
         ]
      ],
      services: [
         [
            'Domini aggiuntivi',
            'Richiedi i domini di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Email aggiuntive',
            'Richiedi le email personalizzate di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Servizio foto/video',
            ''
         ],
         [
            'Traduzione multilingue',
            'Servizio aggiuntivo a pagamento per ogni lingua straniera desiderata'
         ],
         [
            'Cookie Banner multilingue',
            ''
         ],
         [
            'GoogleMyBusiness',
            ''
         ]
      ]
   },
   {
      name: 'Starter',
      price: 89.9,
      features: [
         [
            'Sino a 5 pagine',
            ''
         ],
         [
            'Consulente dedicato',
            'Un consulente dedicato a tua disposizione'
         ],
         [
            'Dominio',
            ''
         ],
         [
            'Hosting',
            'Compreso con il sito, un hosting affidabile e sempre aggiornato mantiene il tuo sito web veloce e sicuro'
         ],
         [
            '1 casella email',
            'Casella di posta personalizzata, un aspetto professionale è indispensabile a far crescere la credibilità del tuo brand, di conseguenza il tuo business'
         ],
         [
            'Design responsivo',
            'Adatto a tutti i dispositivi mobili, Il tuo sito web avrà un aspetto fantastico su ogni dispositivo, computer, tablet o smartphone'
         ],
         [
            'Custom CSS',
            'Modifichiamo lo stile grafico del sito che hai scelto a tuo piacimento'
         ],
         [
            'Servizio di copywriting',
            ''
         ],
         [
            'Foto in stock',
            'Scegli le tue foto, vettoriali o infografici da oltre 40 gallerie. Servizio a pagamento'
         ],
         [
            'Foto editing',
            ''
         ],
         [
            'Video',
            'Trasforma il tuo sito con uno sfondo video unico. Utilizza i nostri video gratuiti o carica i tuoi video personalizzati'
         ],
         [
            'Temi grafici + animazioni',
            ''
         ],
         [
            'News e Offerte',
            ''
         ],
         [
            'Multi Widget',
            'Form contatti custom, multi sede, coupon, pop-up, listini evoluti'
         ],
         [
            'Collegamento ai social',
            ''
         ],
         [
            'WhatsApp button',
            ''
         ],
         [
            'Sicurezza Https / SSL',
            'Con il protocollo HTTPS e il certificato di protezione SSL, il tuo sito sarà sempre sicuro per i tuoi visitatori e premiato dai motori di ricerca'
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'GoogleMyBusiness',
            ''
         ],
         [
            'Google Analytics 4',
            ''
         ],
         [
            'Cookie Banner monolingue',
            'Sino a 50 pagine, singolo dominio e monolingue compreso'
         ],
         [
            'Policy base',
            ''
         ],
         [
            'Condivisione grafica per approvazione finale',
            ''
         ],
         [
            'Manutenzione ordinaria illimitata',
            ''
         ]
      ],
      services: [
         [
            'Domini aggiuntivi',
            'Richiedi i domini di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Email aggiuntive',
            'Richiedi le email personalizzate di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Pagine aggiuntive',
            'Puoi avere un numero illimitato di pagine.  Servizio aggiuntivo a pagamento'
         ],
         [
            'Servizio foto/video',
            ''
         ],
         [
            'Traduzione multilingue',
            'Servizio aggiuntivo a pagamento per ogni lingua straniera desiderata'
         ],
         [
            'Cookie Banner multilingue',
            ''
         ]
      ]
   },
   {
      name: 'Pro',
      price: 'Scopri l\'offerta a te dedicata',
      features: [
         [
            'oltre le 5 pagine',
            ''
         ],
         [
            'Consulente dedicato',
            'Un consulente dedicato a tua disposizione'
         ],
         [
            'Dominio',
            ''
         ],
         [
            'Hosting personalizzato',
            'Sceglieremo insieme l’hosting più adatto alle tue esigenze'
         ],
         [
            'Caselle email',
            'Illimitate'
         ],
         [
            'Design responsivo',
            'Adatto a tutti i dispositivi mobili, Il tuo sito web avrà un aspetto fantastico su ogni dispositivo, computer, tablet o smartphone'
         ],
         [
            'Grafiche custom',
            'Modifichiamo l  \'aspetto del tuo sito, aggiungiamo funzionalità non previste, tramite editing del codice HTML. Servizio aggiuntivo a pagamento'
         ],
         [
            'Blog',
            'Aggiungi al tuo sito un blog incredibile e completamente personalizzabile'
         ],
         [
            'Servizio di copywriting',
            ''
         ],
         [
            'Foto in stock',
            'Scegli le tue foto, vettoriali o infografici da oltre 40 gallerie. Servizio a pagamento'
         ],
         [
            'Foto editing',
            ''
         ],
         [
            'Video',
            'Trasforma il tuo sito con uno sfondo video unico. Utilizza i nostri video gratuiti o carica i tuoi video personalizzati'
         ],
         [
            'Temi grafici + animazioni',
            ''
         ],
         [
            'News e Offerte',
            ''
         ],
         [
            'Multi Widget',
            'Form contatti custom, multi sede, coupon, pop-up, listini evoluti'
         ],
         [
            'Collegamento ai social',
            ''
         ],
         [
            'WhatsApp button',
            ''
         ],
         [
            'Sicurezza Https / SSL',
            'Con il protocollo HTTPS e il certificato di protezione SSL, il tuo sito sarà sempre sicuro per i tuoi visitatori e premiato dai motori di ricerca'
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'GoogleMyBusiness',
            ''
         ],
         [
            'Google Analytics 4',
            ''
         ],
         [
            'Cookie Banner monolingue',
            ''
         ],
         [
            'Policy base',
            ''
         ],
         [
            '1 report/bimestrale + 2 review/anno',
            ''
         ],
         [
            'Condivisione grafica per approvazione finale',
            ''
         ],
         [
            '8 ore assistenza dedicata',
            ''
         ],
         [
            'Manutenzione ordinaria illimitata',
            ''
         ]
      ],
      services: [
         [
            'Domini aggiuntivi',
            'Richiedi i domini di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Email aggiuntive',
            'Richiedi le email personalizzate di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Servizio foto/video',
            ''
         ],
         [
            'Traduzione multilingue',
            'Servizio aggiuntivo a pagamento per ogni lingua straniera desiderata'
         ],
         [
            'Cookie Banner multilingue',
            ''
         ],
         [
            'Integrazione widget',
            'Variazione dei widget presenti, integrazione dei necessari scelti dalle librerie o forniti dai clienti. Servizio aggiuntivo a pagamento'
         ]
      ]
   },
   {
      name: 'Ecommerce',
      price: 'Scopri l\'offerta a te dedicata',
      features: [
         [
            'Consulente dedicato',
            'Un consulente dedicato a tua disposizione'
         ],
         [
            'Dominio',
            ''
         ],
         [
            'Hosting personalizzato',
            'Sceglieremo insieme l’hosting più adatto alle tue esigenze'
         ],
         [
            'Caselle email',
            'Illimitate'
         ],
         [
            'Design responsivo',
            'Adatto a tutti i dispositivi mobili, Il tuo sito web avrà un aspetto fantastico su ogni dispositivo, computer, tablet o smartphone'
         ],
         [
            'Custom HTML',
            'L\'aspetto del tuo sito è personalizzato in base alle tue esigenze tramite editing del codice HTML'
         ],
         [
            'Custom CSS',
            'Stili grafici del tuo sito personalizzati tramite editing delle classi CSS'
         ],
         [
            'Blog',
            'Aggiungi al tuo sito un blog incredibile e completamente personalizzabile'
         ],
         [
            'Editabilità',
            'Crea i tuoi contenuti e pubblicali autonomamente sul tuo sito in un modo semplice e veloce'
         ],
         [
            'Servizio di copywriting',
            ''
         ],
         [
            'Foto in stock',
            'Scegli le tue foto, vettoriali o infografici da oltre 40 gallerie. Servizio a pagamento'
         ],
         [
            'Foto editing',
            ''
         ],
         [
            'Video',
            'Trasforma il tuo sito con uno sfondo video unico. Utilizza i nostri video gratuiti o carica i tuoi video personalizzati'
         ],
         [
            'Temi grafici + animazioni',
            ''
         ],
         [
            'News e Offerte',
            ''
         ],
         [
            'Multi Widget',
            'Form contatti custom, multi sede, coupon, pop-up, listini evoluti'
         ],
         [
            'Collegamento ai social',
            ''
         ],
         [
            'WhatsApp button',
            ''
         ],
         [
            'Sicurezza Https / SSL',
            ''
         ],
         [
            'Ottimizzazione SEO',
            ''
         ],
         [
            'GoogleMyBusiness',
            ''
         ],
         [
            'Google Analytics 4',
            ''
         ],
         [
            '1 report mensile + 3 review/anno',
            ''
         ],
         [
            'Condivisione grafica per approvazione finale',
            ''
         ],
         [
            '12 ore assistenza dedicata',
            ''
         ],
         [
            'Manutenzione ordinaria illimitata',
            ''
         ],
         [
            'Cookie Banner personalizzato',
            ''
         ],
         [
            'Spedizioni ed imposte',
            'Configura regole su tasse e spedizioni personalizzate in base alla destinazione'
         ]
      ],
      services: [
         [
            'Domini aggiuntivi',
            'Richiedi i domini di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Email aggiuntive',
            'Richiedi le email personalizzate di cui hai bisogno. Servizio aggiuntivo a pagamento'
         ],
         [
            'Servizio foto/video',
            ''
         ],
         [
            'Traduzione multilingue',
            'Servizio aggiuntivo a pagamento per ogni lingua straniera desiderata'
         ],
         [
            'Policy personalizzate',
            ''
         ],
         [
            'Data Analytics',
            ''
         ],
         [
            'Google Shop',
            ''
         ]
      ]
   },
]