import { useState, useEffect } from 'react';
import validate from './validationForm';
import emailjs from '@emailjs/browser';


const useSendEmail = (initValue, formRef) => {
   const [formVals, setFormVals] = useState(initValue),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);

   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };

   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals, initValue));
      setIsSubmit(true);
   };
   
   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_xh5kdb9', 'template_urz8fo3', formRef.current, 'DpgLOWlv5ER2nLZa3')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);

   useEffect(() => {
		if(isSent) {
			const msgSent = setTimeout(()=>setIsSent(false), 5000);
			return () => clearTimeout(msgSent);
		}
	}, [isSent]);

   return { formVals, handleChange, formErrs, handleSubmit, isSubmit, isSent };
}

export default useSendEmail;