import PageTitle from '../../components/PageTitle';
import HelmetSeo from "../../components/HelmetSeo";
import PriceTableProfile from '../../components/PriceTableProfile';
import titleBg from '../../imgs/bg/grey-wave.webp';
import calendar from '../../imgs/profile/calendar.png';

export default function Prezzi() {
	return (
		<>
		   <HelmetSeo
            pageTitle="Prezzi PcProfile - Milanesi Editore"
            description="Scopri tutti i prezzi delle PcProfile di Paginecomunali. Inoltre sono state create le schede web “PcProfile” speciali, adibite a delle determinate attività che hanno nel loro DNA delle esigenze uniche e speciali."
            keys="prezzi, piani"
         />
			
			<PageTitle
				title='Prezzi'
				text="Scegli il PcProfile per la tua azienda"
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32"><path d="m32.51,7.5c-.28,0-.5-.22-.5-.5v-1.67c0-.35-.29-.64-.64-.64h-2.84c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h2.84c.9,0,1.64.73,1.64,1.64v1.67c0,.28-.22.5-.5.5Z"/><path d="m13.04,6.97c-.21,0-.41-.14-.48-.35-.08-.26.07-.54.33-.62L27.45,1.57c.26-.08.53.06.62.32l1.5,4.42c.09.26-.05.55-.31.63-.26.09-.54-.05-.63-.31l-1.34-3.97-14.09,4.28s-.1.02-.15.02Z"/><path d="m5.21,6.97c-.21,0-.41-.13-.48-.34-.09-.26.06-.54.32-.63L23.17.03c.26-.09.55.06.63.32.09.26-.06.54-.32.63L5.36,6.95c-.05.02-.1.02-.16.02Z"/><path d="m36.5,24h-8c-.91,0-1.5-.59-1.5-1.5v-5c0-.91.59-1.5,1.5-1.5h5.5v-5.5c0-.35-.15-.5-.5-.5H3c-1.79,0-3-1.21-3-3s1.21-3,3-3h8c.28,0,.5.22.5.5s-.22.5-.5.5H3c-1.23,0-2,.77-2,2s.77,2,2,2h30.5c.91,0,1.5.59,1.5,1.5v6c0,.28-.22.5-.5.5h-6c-.35,0-.5.15-.5.5v5c0,.35.15.5.5.5h8c.35,0,.5-.15.5-.5v-6c0-.35-.15-.5-.5-.5-.28,0-.5-.22-.5-.5s.22-.5.5-.5c.91,0,1.5.59,1.5,1.5v6c0,.91-.59,1.5-1.5,1.5Z"/><circle cx="32" cy="20" r="1"/><path d="m33.5,32H3c-1.79,0-3-1.21-3-3V7c0-.28.22-.5.5-.5s.5.22.5.5v22c0,1.23.77,2,2,2h30.5c.35,0,.5-.15.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0,.91-.59,1.5-1.5,1.5Z"/></svg>}
				imgBottom={titleBg}
			/>
			
			<PriceTableProfile />

			<section className="bt">
				<div className="container grid g-3 place-center">
					<div>
						<h2>Specialcat</h2>
						<p>Paginecomunali ha deciso di creare delle schede web “PcProfile” speciali, adibite a delle determinate attività che hanno nel loro DNA delle esigenze uniche e speciali. Ad esempio i ristoranti, per i quali è fondamentale poter pubblicare ed aggiornare autonomamente il proprio menù, poter ricevere richieste di prenotazione tavoli etc. Per questo motivo sono stati creati dei PcProfile Specialcat. Le vostre particolarità, il vostro essere speciali è la chiave del vostro successo.</p>
					</div>

					<img src={calendar} className="mx-w-25" alt="PcProfile Specialcat" />
				</div>
			</section>
		</>
	);
}