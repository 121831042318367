import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import titleBg from '../../imgs/bg/brown-wave.webp';
import hosting from '../../imgs/prodotti/hosting.webp';

export default function Hosting() {
   return (
      <>
		   <HelmetSeo
            pageTitle="Hosting Professionale  - Milanesi Editore"
            description="Il nostro impegno è garantire che i siti web dei nostri clienti siano sempre supportati dalle migliori soluzioni di hosting disponibili sul mercato"
            keys="hosting, web agency, cloud, hosting wordpress, hosting condiviso, hosting economico"
         />
			
         <PageTitle
            title='Hosting'
            icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32"><path d="m33,32H9c-4.79,0-9-4.44-9-9.5,0-3.88,3.21-9.5,9-9.5h1.5c.28,0,.5.22.5.5s-.22.5-.5.5h-1.5c-5.33,0-8,5.45-8,8.5,0,4.53,3.74,8.5,8,8.5h24c4.31,0,8-4.67,8-8.5,0-5.21-4.44-8.54-7.5-8.54-.28,0-.5-.22-.5-.5s.22-.5.5-.5c3.93,0,8.5,4.17,8.5,9.54,0,4.28-4.15,9.5-9,9.5Z"/><path d="m33.5,16c-.28,0-.5-.22-.5-.5v-1.5c0-6.26-3.91-13-12.5-13S7.63,7.55,7.5,13.72c0,.28-.23.51-.51.49-.28,0-.5-.23-.49-.51C6.66,5.89,12.68,0,20.5,0c8.86,0,13.5,7.04,13.5,14v1.5c0,.28-.22.5-.5.5Z"/></svg>}
				text='Hosting Professionale e Su Misura'
				imgBottom={titleBg}
         />

         <section className="pt-sm-4">
				<div className="container">
					<div className="txt-center mb-5">
						<h2>Scopri la Nostra Web Agency</h2>
						<p>Sappiamo quanto sia essenziale fornire ai nostri clienti soluzioni di hosting su misura per le loro esigenze uniche. Abbiamo stretto partnership con i migliori fornitori di hosting sul mercato per garantire che ciascun cliente abbia l'ambiente di hosting più adatto al proprio sito web.</p>
					</div>

					<div className="grid g-3 place-center mb-5">
						<div>
							<h2>Le nostre opzioni di hosting includono:</h2>
							<ul className="list">
                        <li><b>Hosting in Cloud</b>: per i siti web che necessitano di flessibilità e scalabilità, consigliamo l'hosting in cloud. Questa soluzione offre risorse virtuali facilmente adattabili alle fluttuazioni del traffico, garantendo prestazioni ottimali in qualsiasi momento.</li>
                        <li><b>Cloud Dedicato</b>: per siti web con esigenze di prestazioni e sicurezza elevate, suggeriamo il cloud dedicato. Con server virtuali esclusivi, il cliente ha il controllo completo sulle risorse e può personalizzare l'ambiente secondo le proprie necessità.</li>
                        <li><b>Server Fisici</b>: per progetti web particolarmente impegnativi e con carichi di lavoro intensi, raccomandiamo l'uso di server fisici. Questa opzione offre il massimo delle risorse hardware per garantire prestazioni ottimali e affidabilità senza compromessi.</li>
                        <li><b>Hosting Condiviso</b>: per siti web di piccole e medie dimensioni con budget limitato, potremmo suggerire l'hosting condiviso. Questa opzione permette di condividere le risorse di un server con altri siti, risultando un'opzione economica per progetti con traffico moderato.</li>
                        <li><b>Hosting WordPress</b>: per i siti web basati su WordPress, proponiamo soluzioni di hosting ottimizzate per garantire tempi di caricamento rapidi e un'esperienza utente senza intoppi.</li>
                     </ul>
						</div>

						<img src={hosting} className="mx-w-25" alt="Hosting web agency" />
					</div>
					
					<p>Quando un nuovo cliente si unisce alla nostra web agency, ci prendiamo il tempo di ascoltare attentamente le sue esigenze e gli obiettivi del sito web. Analizziamo le caratteristiche del progetto e suggeriamo il tipo di hosting più appropriato per massimizzare le prestazioni, la sicurezza e la scalabilità. La nostra esperienza nel campo ci consente di scegliere i fornitori di hosting più affidabili e performanti, adattando ciascuna soluzione alle specifiche necessità del cliente.</p>

					<p>Il nostro impegno è garantire che i siti web dei nostri clienti siano sempre supportati dalle migliori soluzioni di hosting disponibili sul mercato. La nostra scelta dei fornitori di hosting è basata sulla qualità delle prestazioni, la sicurezza, l'affidabilità e l'assistenza tecnica, in modo che i nostri clienti possano concentrarsi sul loro business senza preoccuparsi della gestione tecnica del server.</p>
				</div>
			</section>

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="bg-red"
			/>
      </>
   );
}