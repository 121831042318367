import useWindowWidth from '../../hooks/useWindowWidth';

import HelmetSeo from "../../components/HelmetSeo";
import PageTitle from '../../components/PageTitle';
import SectionRefPage from '../../components/SectionRefPage';
import PriceTable from '../../components/PriceTable';
import BtnScegliTemplate from '../../components/BtnScegliTemplate';
import Cta from '../../components/Cta';

import titleBg from '../../imgs/bg/green-wave.webp';
import versatilita from '../../imgs/sitiweb/versatilita.webp';
import funzionalitapc from '../../imgs/sitiweb/funzionalita-pc.webp';
import sitoOnline from '../../imgs/sitiweb/sito-online.webp';
import tabletOnline from '../../imgs/sitiweb/tablet-online.webp';
import httpsSecure from '../../imgs/sitiweb/https-secure.webp';
import funzionalita from '../../imgs/sitiweb/funzionalita.webp';


export default function Pro() {
	const isMobile = useWindowWidth() < 1080;

	return (
		<>
			<HelmetSeo
				pageTitle="Siti web Pro - Milanesi Editore"
				description="Sviluppiamo soluzioni web personalizzate, realizzate totalmente a mano, per rispondere in modo mirato agli obiettivi del progetto e per portare l'azienda verso un livello superiore."
				keys="sito web custom, personalizzare sito web, sito web, obiettivi aziendali, framework, https, widget sito web"
			/>

			<PageTitle
				title='Pro'
				icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.42 32"><path d="m18.92,30H4.92c-.12,0-.24-.05-.34-.13-1.22-1.11-1.87-2.64-1.88-4.42-.01-2.71,1.43-5.69,3.87-7.95,2.89-2.68,3.35-3.68,3.35-5.5,0-.28.22-.5.5-.5s.5.22.5.5c0,2.25-.75,3.53-3.67,6.24-2.2,2.04-3.56,4.81-3.55,7.21,0,1.42.5,2.65,1.42,3.55h13.8c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m20.92,32H2.92c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h18c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m17.92,24c-.06,0-.11,0-.17-.03-.26-.09-.39-.38-.3-.64,1.96-5.37,1.97-10.28,1.97-10.33,0-9-6.05-9.97-8.66-9.97-1.87,0-3.67-.88-4.72-1.51.19.97.54,2.02.54,2.03.04.13.03.28-.04.4-.07.13-1.8,3.22-5.25,6.2-.15.11-.49.43-.16,1.08.41.81,1.26,1.8,2.03,2.03.3.09.56.06.81-.09,1.37-.82,3.13-2.06,3.15-2.07.17-.12.4-.12.57,0,1.02.7,2.93.22,3.71-.92.64-.93.84-1.84.55-2.45-.12-.25-.02-.55.23-.67.25-.12.55-.01.67.23.65,1.34-.22,2.86-.63,3.45-.51.74-1.36,1.31-2.34,1.56-.89.23-1.77.16-2.46-.2-.55.38-1.85,1.27-2.95,1.93-.49.29-1.04.36-1.61.2-1.11-.32-2.15-1.54-2.65-2.54-.6-1.2.03-2.05.45-2.33,2.81-2.43,4.45-5.01,4.87-5.71-.18-.58-.65-2.17-.65-3.15,0-.19.11-.36.28-.45.17-.08.37-.06.53.05.02.02,2.53,1.92,5.04,1.92,2.27,0,9.66.79,9.66,10.97,0,.21,0,5.12-2.03,10.67-.07.2-.27.33-.47.33Z"></path><path d="m20.92,21s-.08,0-.12-.01c-.27-.07-.43-.34-.36-.61.99-3.94.99-5.93.99-7.38,0-5.96-2.76-9.07-5.07-10.63-1.42-.96-3.05-1.37-5.43-1.37-.28,0-.5-.22-.5-.5s.22-.5.5-.5c2.59,0,4.38.46,5.99,1.54,2.52,1.7,5.51,5.06,5.51,11.46,0,1.5,0,3.56-1.01,7.62-.06.23-.26.38-.49.38Z"></path></svg>}
				text='News, offerte o saldi, il tuo sito web può gestire tutto questo!'
				imgBottom={titleBg}
			/>

			<section className="container pt-sm-4 grid g-3 rg-10 place-center">
				<div>
					<h2>Siti web Pro, quando il custom diventa necessità</h2>
					<p>Affrontiamo le sfide delle esigenze di business più specifiche attraverso <b>soluzioni tecnologiche su misura</b>. Quando le piattaforme tradizionali non riescono a soddisfare appieno le esigenze particolari o a tenere il passo con la crescita aziendale, <b>sviluppiamo soluzioni personalizzate</b>, realizzate totalmente a mano, per rispondere in modo mirato agli obiettivi del progetto e per portare l'azienda verso un livello superiore.</p>
				</div>

				<img src={funzionalitapc} className="mx-w-25" alt="Siti web Pro la nostra soluzione custom" />

				{!isMobile &&
					<img src={versatilita} className="mx-w-25" alt="Analisi attenta degli obiettivi aziendali" />
				}

				<div>
					<h2>Personalizzazione digitale avanzata<br />Analisi, Sviluppo e Implementazione</h2>
					<p>I siti web Pro rappresentano l'apice della <b>personalizzazione digitale</b>. Sviluppati attraverso tecnologie all'avanguardia e affidabili, la creazione di queste <b>soluzioni su misura</b> inizia con un'<b>analisi attenta degli obiettivi aziendali</b> e delle esigenze specifiche di manutenzione e gestione dei contenuti.</p>
					<p>Il nostro approccio coinvolge tre ambienti distinti (<b>sviluppo, staging e produzione</b>) per garantire un'implementazione coordinata e scalabile dell'intero progetto. Seguendo una solida pipeline di rilascio, il risultato è un sito web custom che va oltre gli standard, adattandosi perfettamente alle tue esigenze uniche.</p>
				</div>
			
				{isMobile &&
					<img src={versatilita} alt="Analisi attenta degli obiettivi aziendali" />
				}

				<div>
					<h2>Il Nostro Approccio</h2>
					<ul className="list-numb">
						<li><b>Identificazione dei Requisiti</b>: Collaboriamo con il Cliente per raccogliere i requisiti essenziali del progetto custom.</li>
						<li><b>Scelta del Framework ottimale</b>: Guidati dalla nostra competenza, individuiamo il framework ideale per lo sviluppo della piattaforma e pianifichiamo i rilasci in ambiente di staging.</li>
						<li><b>Progressi Condivisi e Rilasci Graduali</b>: Presentiamo progressivamente i risultati al Cliente in ambiente di staging per garantire il corretto funzionamento delle nuove implementazioni.</li>
						<li><b>Evoluzione Continua</b>: Oltre al lancio iniziale, ci impegniamo in un miglioramento continuo attraverso il nostro servizio di assistenza, per affinare costantemente il progetto del Cliente.</li>
					</ul>
				</div>
				
				<img src={tabletOnline} alt="Individuiamo il framework ideale per lo sviluppo della piattaforma" />
				
				{!isMobile &&
					<img src={sitoOnline} className="mx-w-25" alt="Creazione del tuo sito web personalizzato" />
				}

				<div>
					<h2>Portiamo il tuo sito web Pro online</h2>
					<p className="mb-4">Segui attentamente la creazione del tuo sito web personalizzato attraverso uno <b>stage di sviluppo online</b>. Puoi intervenire in qualsiasi momento per <b>chiarimenti, modifiche o aggiunte</b>. Il sito sarà ufficialmente lanciato solo dopo la tua approvazione. È fondamentale tenere presente che il tuo sito web è un potente <b>strumento in costante evoluzione</b>. Avrai la flessibilità di apportare miglioramenti o aggiunte anche successivamente.</p>

					{isMobile &&
						<img src={sitoOnline} className="mb-4" alt="Creazione del tuo sito web personalizzato" />
					}
					
					<BtnScegliTemplate />
				</div>
			</section>
			
			<hr />
			
			<SectionRefPage
				title="Protocollo Https"
				text="Il protocollo standard di trasferimento ipertestuale sicuro, la dicitura “https://” viene visualizzata nel URL del sito web protetto con un certificato SSL (Secure Sockets Layer). Se si clicca sul simbolo del lucchetto nella barra del browser, è possibile visualizzare i dettagli del certificato, il nome aziendale del proprietario del sito web oltre che i dati relativi all'autorità di emissione."
				urlBtn="/cosa-offriamo/sitiweb/https"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="m25.5,32H2.5c-1.38,0-2.5-1.12-2.5-2.5v-14c0-1.38,1.12-2.5,2.5-2.5h23c1.38,0,2.5,1.12,2.5,2.5v14c0,1.38-1.12,2.5-2.5,2.5ZM2.5,14c-.83,0-1.5.67-1.5,1.5v14c0,.83.67,1.5,1.5,1.5h23c.83,0,1.5-.67,1.5-1.5v-14c0-.83-.67-1.5-1.5-1.5H2.5Z"></path><path d="m4.5,14c-.28,0-.5-.22-.5-.5v-3.5C4,4.49,8.49,0,14,0s10,4.49,10,10v3.42c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.42c0-4.96-4.04-9-9-9S5,5.04,5,10v3.5c0,.28-.22.5-.5.5Z"></path><path d="m8.5,13.99c-.28,0-.5-.22-.5-.5v-3.49c0-3.31,2.69-6,6-6s6,2.69,6,6v3.45c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-3.45c0-2.76-2.24-5-5-5s-5,2.24-5,5v3.49c0,.28-.22.5-.5.5Z"></path><path d="m27.12,27H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m27.12,21H.94c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h26.19c.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m1.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m7.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m13.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path><path d="m19.5,27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l6-6c.2-.2.51-.2.71,0s.2.51,0,.71l-6,6c-.1.1-.23.15-.35.15Z"></path></svg>}
				img={httpsSecure}
			/>

			<SectionRefPage
				title="Funzionalità"
				text="Scoprite le molteplici funzionalità che offriamo per il vostro sito web, tra cui design responsivo, servizio foto/video, temi grafici animati, personalizzazione HTML/CSS, widget, Google MyBusiness, report/recensioni dettagliati, integrazione con Google Analytics 4 e soluzioni specifiche per il vostro eCommerce."
				urlBtn="/cosa-offriamo/sitiweb/funzionalita"
				iconBtn={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32"><path d="m39.5,32c-.28,0-.5-.22-.5-.5V.5c0-.28.22-.5.5-.5s.5.22.5.5v31c0,.28-.22.5-.5.5Z"></path><path d="m37.5,30.85c-.11,0-.22-.04-.31-.11l-.52-.41c-3-2.37-8.02-6.33-18.24-6.33H4.63c-1.45,0-2.63-1.17-2.63-2.61v-10.71c0-1.47,1.18-2.67,2.63-2.67h13.87c10.17,0,15.15-3.94,18.12-6.3l.56-.45c.15-.12.36-.14.53-.05.17.08.28.26.28.45v28.69c0,.19-.11.37-.28.45-.07.03-.14.05-.22.05ZM4.63,9c-.9,0-1.63.75-1.63,1.67v10.71c0,.9.72,1.61,1.63,1.61h13.8c10.24,0,15.43,3.85,18.57,6.32V2.68c-3.11,2.45-8.26,6.32-18.5,6.32H4.63Z"></path><path d="m.5,21.86c-.28,0-.5-.22-.5-.5v-10.71c0-.28.22-.5.5-.5s.5.22.5.5v10.71c0,.28-.22.5-.5.5Z"></path><path d="m16.5,32c-2.52,0-4.55-.75-6.02-2.24-2.48-2.5-2.48-6.14-2.48-6.3v-14.46c0-.28.22-.5.5-.5s.5.22.5.5v14.47s0,3.4,2.19,5.59c1.28,1.29,3.06,1.94,5.31,1.94.28,0,.5.22.5.5s-.22.5-.5.5Z"></path><path d="m16.5,28c-1.33,0-2.41-.4-3.2-1.2-1.32-1.33-1.32-3.26-1.32-3.34,0-.27.23-.5.5-.5h0c.28,0,.5.23.5.5,0,.02,0,1.61,1.03,2.63.59.59,1.43.9,2.48.9.28,0,.5.22.5.5s-.22.5-.5.5Z"></path></svg>}
				img={funzionalita}
				order
				bg
			/>

			{!isMobile &&
				<PriceTable active="Pro" />
			}

			<hr />

			<Cta
				title="Siamo qui per trasformare le tue idee in realtà! Se hai una domanda, un progetto da condividere o una curiosità da soddisfare, contattaci."
				urlBtn="/contatti"
				labelBtn="Contattaci"
				classSection="pbk-xs"
				classBtn="btn-feature-border-green"
			/>
		</>
	);
}