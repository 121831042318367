import { pricePlansProfile } from '../data/pricePlansProfile'
import Popover from './Popover';

export default function PriceTableProfile() {
	return (
		<section className="container">
			<div className="grid gtc-4 g-_5">
				{pricePlansProfile.map(plan =>
					<article className="price-table" key={plan.name}>
						<h1 className='price-title'>{plan.name}</h1>

						{typeof plan.price === 'string' ?
							<h3 className="price txt-center">{plan.price}</h3>
							:
							<h2 className="price txt-center">
								<small>da</small>
								€ {plan.price.toString().split('.')[0]}
								<sup> .{plan.price.toFixed(2).toString().split('.')[1]}</sup>
								<small>/ mese</small>
							</h2>
						}

						<ul className="price-features list">
							{plan.features.map((feature, idx) =>
								<li key={idx}>
									{feature[0]}
									{feature[1] &&
										<Popover text={feature[1]} />
									}
								</li>
							)}
						</ul>
					</article>
				)}
			</div>
		</section>
	);
}